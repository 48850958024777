<template>
  <div class="horus-select-applicate-organization-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>請求詳細</h1>
      </div>
      <div class="modal-body" v-if="currentOrganizationBilling">
        <div class="controll">
          <button class="close-btn" @click="closeModal">閉じる</button>
          <button class="suspend-btn" @click="suspend" v-if="currentOrganizationBilling.billing_state === 1">請求保留</button>
          <button class="resume-btn" @click="resume" v-if="currentOrganizationBilling.billing_state === 5">請求保留解除</button>
          <button class="finish-btn" @click="finish" v-if="currentOrganizationBilling.billing_state === 4">処理完了</button>
        </div>
        <h2>施設情報</h2>
        <div class="organization-info line-block">
          <div class="line-item"><span class="title">施設名</span>{{ currentOrganizationBilling.organization.name }}</div>
          <div class="line-item"><span class="title">施設番号</span>{{ organizationNumberForHuman }}</div>
        </div>
        <h2>請求情報</h2>
        <div class="billing-info line-block">
          <div class="line-item"><span class="title">状態</span>{{ currentOrganizationBilling.billing_state_jp }}</div>
          <div class="line-item"><span class="title">締め日</span>{{ currentOrganizationBilling.period_end }}</div>
          <div class="line-item"><span class="title">内税/外税</span>{{ textIncludedText }}</div>
          <div class="line-item"><span class="title">税率</span>{{ taxRatioPercent }}</div>
          <div class="line-item"><span class="title">小計</span>{{ currentOrganizationBilling.total_price }}円</div>
          <div class="line-item"><span class="title">値引き</span>{{ currentOrganizationBilling.discount_price }}円</div>
          <div class="line-item"><span class="title">請求金額</span>{{ currentOrganizationBilling.billing_price }}円</div>
          <div class="line-item"><span class="title">調整金額</span>{{ currentOrganizationBilling.adjust_price }}円</div>
          <div class="line-item"><span class="title">調整金額名目</span>{{ currentOrganizationBilling.adjust_price_name }}</div>
          <div class="line-item"><span class="title">備考</span>{{ currentOrganizationBilling.memo }}</div>
          <div class="controll">
            <button class="adjust-btn" v-if="!currentOrganizationBilling.adjust_price_name" @click="adjust">金額調整</button>
            <button class="memo-btn" @click="editMemo">備考欄編集</button>
          </div>
        </div>
        <h2>請求明細</h2>
        <div class="billing-units">
          <div class="billing-unit line-block"
            v-for="unit in currentOrganizationBilling.organization_billing_units"
            :key="'obu' + unit.id">
            <div class="line-item"><span class="title">名目</span>{{ unit.name }}</div>
            <div class="line-item"><span class="title">単価</span>{{ unit.unit_price }}円</div>
            <div class="line-item"><span class="title">数量</span>{{ unit.amount }}</div>
            <div class="line-item"><span class="title">小計</span>{{ unit.subtotal }}円</div>
          </div>
        </div>
        <h2>Stripe情報</h2>
        <div class="stripe-info line-block">
          <div class="line-item"><span class="title">Stripeインボイス状態</span>{{ currentOrganizationBilling.stripe_invoice_state }}</div>
          <div class="line-item"><span class="title">StripeインボイスID</span>{{ currentOrganizationBilling.stripe_invoice_id }}</div>
          <div class="line-item"><span class="title">StripeクーポンID</span>{{ currentOrganizationBilling.stripe_coupon_id }}</div>
        </div>
      </div>
    </horus-modal-frame>
    <horus-organization-billing-adjust-modal
      :organizationBilling="currentOrganizationBilling"
      :modalDisplay="adjustModalDisplay"
      v-on:cecelEvent="cancelAdjust"
      v-on:submitEvent="submitAdjust"
      />
    <horus-organization-billing-memo-edit-modal
      :organizationBilling="currentOrganizationBilling"
      :modalDisplay="memoEditModalDisplay"
      v-on:cecelEvent="cancelEditMemo"
      v-on:submitEvent="updateMemo"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { OrganizationBilling } from '@/api'

import HorusOrganizationBillingAdjustModal from '@/components/manageSystem/organizationBilling/HorusOrganizationBillingAdjustModal.vue'
import HorusOrganizationBillingMemoEditModal from '@/components/manageSystem/organizationBilling/HorusOrganizationBillingMemoEditModal.vue'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusOrganizationBillingDetailModal',

  components: {
    HorusOrganizationBillingAdjustModal,
    HorusOrganizationBillingMemoEditModal,
    HorusModalFrame,
    HorusConfirmDialog,
  },

  props: {
    organizationBilling: Object,
    modalDisplay: Boolean,
  },

  emits: [
    'closeEvent',
  ],

  data () {
    return {
      currentOrganizationBilling: null,
      adjustModalDisplay: false,
      memoEditModalDisplay: false,
    }
  },

  watch: {
    organizationBilling: { handler: 'loadOrganizationBilling', immediate: true }
  },

  computed: {
    organizationNumberForHuman () {
      if (this.currentOrganizationBilling.organization) {
        return this.currentOrganizationBilling.organization.organizationNumber.substr(0, 4) 
          + '-' + this.currentOrganizationBilling.organization.organizationNumber.substr(4, 4) 
          + '-' + this.currentOrganizationBilling.organization.organizationNumber.substr(8, 4) 
          + '-' + this.currentOrganizationBilling.organization.organizationNumber.substr(12, 4)
      }
      return '-----'
    },

    textIncludedText () {
      if (this.currentOrganizationBilling) {
        if (this.currentOrganizationBilling.tax_included) {
          return '内税'
        } else {
          return '外税'
        }
      }
      return '-----'
    },

    taxRatioPercent () {
      if (this.currentOrganizationBilling) {
        return this.currentOrganizationBilling.tax_ratio * 100 + '%'
      } else {
        return '---'
      }
    },
  },

  methods: {
    loadOrganizationBilling () {
      if (this.organizationBilling) {
        OrganizationBilling.fetch({ targetID: this.organizationBilling.id }).then(res => {
          console.log(res)
          this.currentOrganizationBilling = res.organization_billing
        })
      }
    },

    closeModal () {
      this.$emit('closeEvent')
    },

    suspend () {
      this.showConfirmDialog(
        '請求保留',
        'この請求を保留します。\n保留解除して請求実施するまでこの請求は実施されません。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeSuspend() }
      )
    },

    executeSuspend () {
      OrganizationBilling.suspend({ targetID: this.currentOrganizationBilling.id }).then(res => {
        console.log(res)
        this.loadOrganizationBilling()
        this.showNotifyDialog('保留完了', 'この請求を保留しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('保留失敗', 'この請求の保留に失敗しました。')
      })
    },

    resume () {
      this.showConfirmDialog(
        '請求保留解除',
        'この保留中の請求を保留解除します。\n保留解除後、請求実施するとこの請求は実施されます。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeResume() }
      )
    },

    executeResume () {
      OrganizationBilling.resume({ targetID: this.currentOrganizationBilling.id }).then(res => {
        console.log(res)
        this.loadOrganizationBilling()
        this.showNotifyDialog('保留解除完了', 'この請求の保留を解除しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('保留解除失敗', 'この請求の保留解除に失敗しました。')
      })
    },

    finish () {
      this.showConfirmDialog(
        '処理完了',
        'この請求を処理完了します。\nStripeには回収不能請求としてマークします。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeFinish() }
      )
    },

    executeFinish () {
      OrganizationBilling.suspend({ targetID: this.currentOrganizationBilling.id }).then(res => {
        console.log(res)
        this.loadOrganizationBilling()
        this.showNotifyDialog('処理完了', 'この請求を処理完了状態にしました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('処理完了失敗', 'この請求の処理完了に失敗しました。')
      })
    },

    adjust () {
      this.adjustModalDisplay = true
    },

    cancelAdjust () {
      this.adjustModalDisplay = false
    },

    submitAdjust (formData) {
      OrganizationBilling.adjust({ formData: formData }).then(res => {
        console.log(res)
        this.adjustModalDisplay = false
        this.loadOrganizationBilling()
        this.showNotifyDialog('調整の設定', 'この請求に調整金額を設定しました。Stripeクーポンとして割引が適用されます。')
      }).catch(error => {
        console.error(error)
        this.adjustModalDisplay = false
        this.showNotifyDialog('調整の設定失敗', 'この請求の調整金額設定に失敗しました。')
      })
    },

    editMemo () {
      this.memoEditModalDisplay = true
    },

    cancelEditMemo () {
      this.memoEditModalDisplay = false
    },

    updateMemo (formData) {
      OrganizationBilling.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadOrganizationBilling()
        this.memoEditModalDisplay = false
        this.showNotifyDialog('備考の更新', 'この請求の備考を更新しました。')
      }).catch(error => {
        console.error(error)
        this.memoEditModalDisplay = false
        this.showNotifyDialog('備考の更新失敗', 'この請求の備考の更新に失敗しました。')
      })
    },
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-select-applicate-organization-modal {
  .modal-body {
    padding: 10px;
    .controll {
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
      }
    }
    .line-block {
      .line-item {
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        .title {
          display: inline-block;
          width: 200px;
          margin-right: 10px;
          text-align: right;
          font-size: 0.8rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>