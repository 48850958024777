import axios from './AxiosInstance'

export default {
  fetchList: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/list',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetch: ({ targetID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set',
        params: { id: targetID },
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  simulate: ({ params }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/simulate',
        params: params,
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  testBillingCreate: ({formData}) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/test_create_billing',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  // createのみ。updateはない。
  create: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_billing_calcuration_set',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({ targetID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_billing_calcuration_set',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          if (res.data.success === true) {
            resolve(res.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  lock: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/lock',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  depuricate: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/depuricate',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  addUnit: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_billing_calcuration_set/add_unit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  updateUnit: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/update_unit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroyUnit: ({ setID, unitID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_billing_calcuration_set/destroy_unit',
            withCredentials: true,
            params: { id: setID, organization_billing_calcuration_unit_id: unitID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchApplicatedOrganizations: ({ targetID, targetStatus }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/applicated_organization_list',
        withCredentials: true,
        params: { id: targetID, condition: targetStatus }
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  applicateOrganizations: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/applicate_organizations',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  endApplicateOrganizations: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_calcuration_set/end_applicate_organizations',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  nameAvailable: ({ params }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/name_available',
        params: params,
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  unitNameAvailable: ({ params }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/unit_name_available',
        params: params,
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  unitApplicableConditions: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_calcuration_set/unit_applicable_conditions',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

}