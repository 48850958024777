<template>
  <div class="horus-calcuration-set-column">
    <div class="list-line">
      <div class="name"><span>名称:</span>{{ calcurationSet.name }} ({{ calcurationSet.id }})</div>
      <div class="status"><span>状態:</span>
        {{ calcurationSet.state_jp }}
        <span v-if="calcurationSet.state === 1">[適用施設数: {{ calcurationSet.applicated_organization_count }}]</span>
      </div>
    </div>
    <div class="controll">
      <button type="button" class="show-btn" @click="showDetail" v-if="jumpDetail">詳細</button>
      <button type="button" class="show-btn" @click="simulate" v-if="!jumpDetail">シミュレート</button>
      <button type="button" class="edit-btn" @click="editSet" v-if="editable && calcurationSet.state === 0">編集</button>
      <button type="button" class="lock-btn" @click="lockCalcurationSet" v-if="editable && calcurationSet.state === 0 && calcurationSet.organization_billing_calcuration_units.length > 0">確定</button>
      <button type="button" class="depuricate-btn" @click="depuricateCalcurationSet" v-if="editable && calcurationSet.state === 1">停止</button>
      <button type="button" class="destroy-btn" @click="destroyCalcurationSet" v-if="editable && calcurationSet.state === 0">削除</button>
    </div>
    <div class="list-line">
      <div class="tax-included"><span>内税/外税:</span>{{ taxIncludeText }}</div>
      <div class="tax-ratio"><span>税率:</span>{{ taxRatioPercent }}%</div>
      <div class="period-end"><span>締め日:</span>毎月{{ periodEndDate }}日</div>
    </div>
    <div class="list-line">
      <div class="tax-included"><span>備考:</span>{{ calcurationSet.memo }}</div>
    </div>
    <div class="calcuration-units">
      <div class="block-title" @click="toggleCalcurationUnits">
        <img src="/static/images/open_close_triangle.png" :class="{open: calcurationUnitListOpen}" />
        明細設定<span class="caption">{{ unitCount }}個</span>
        <div class="controll" v-if="calcurationSet.state === 0 && editable">
          <button class="add-unit-btn" @click="addCalcurationUnit">明細追加</button>
        </div>
      </div>
      <div class="calcuration-unit-list" v-show="calcurationUnitListOpen" v-if="unitCount > 0">
        <horus-calcuration-unit-column
          v-for="calcurationUnit in calcurationSet.organization_billing_calcuration_units"
          :key="'calcunit' + calcurationUnit.id"
          :calcurationSet="calcurationSet"
          :calcurationUnit="calcurationUnit"
          :editable="editable"
          v-on:editUnitEvent="editUnit"
          v-on:destroyUnitEvent="destroyUnit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HorusCalcurationUnitColumn from '@/components/manageSystem/billingSetting/HorusCalcurationUnitColumn.vue'

export default {
  name: 'HorusCalcurationSetColumn',

  components: {
    HorusCalcurationUnitColumn,
  },

  props: {
    calcurationSet: Object,
    jumpDetail: Boolean,
    editable: Boolean,
    listOpen: Boolean,
  },

  data () {
    return {
      calcurationUnitListOpen: false,
    }
  },

  emits: [
    'showEvent',
    'simulateEvent',
    'editEvent',
    'lockEvent',
    'depuricateEvent',
    'destroyEvent',
    'addUnitEvent',
    'editUnitEvent',
    'destroyUnitEvent',
  ],

  computed: {
    taxIncludeText () {
      if (this.calcurationSet && this.calcurationSet.tax_included) {
        return '内税'
      } else {
        return '外税'
      }
    },

    taxRatioPercent () {
      if (this.calcurationSet) {
        return this.calcurationSet.tax_ratio * 100
      }
      return 0
    },

    unitCount () {
      if (this.calcurationSet.organization_billing_calcuration_units) {
        return this.calcurationSet.organization_billing_calcuration_units.length
      } else {
        return 0
      }
    },

    periodEndDate () {
      if (this.calcurationSet.period_end_date === 0) {
        return '末'
      } else {
        return this.calcurationSet.period_end_date
      }
    }
  },

  mounted () {
    this.calcurationUnitListOpen = this.listOpen
  },

  methods: {
    toggleCalcurationUnits () {
      this.calcurationUnitListOpen = !this.calcurationUnitListOpen
    },

    showDetail () {
      this.$emit('showEvent', this.calcurationSet)
    },

    simulate () {
      this.$emit('simulateEvent', this.calcurationSet)
    },

    editSet () {
      this.$emit('editEvent', this.calcurationSet)
    },

    lockCalcurationSet () {
      this.$emit('lockEvent', this.calcurationSet)
    },

    depuricateCalcurationSet () {
      this.$emit('depuricateEvent', this.calcurationSet)
    },

    destroyCalcurationSet () {
      this.$emit('destroyEvent', this.calcurationSet)
    },

    addCalcurationUnit () {
      this.$emit('addUnitEvent', this.calcurationSet)
    },

    editUnit (calcurationUnit) {
      this.$emit('editUnitEvent', calcurationUnit)
    },

    destroyUnit (calcurationUnit) {
      this.$emit('destroyUnitEvent', calcurationUnit)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-calcuration-set-column {
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #aaa;
  .list-line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    div {
      padding: 3px;
      span { 
        margin-right: 10px;
        font-size: 0.9em;
        font-weight: bold;
      }
      &.name {
        font-weight: bold;
          font-size: 1.1rem;
      }
    }
  }
  .calcuration-units {
    .block-title {
      cursor: pointer;
      margin: 10px 0;
      font-weight: bold;
      img {
        width: 13px;
        transition: transform 300ms ease;
        &.open {
          transform: rotate(90deg);
        }
      }
      .caption {
        font-size: 12px;
        font-weight: normal;
        &.notice {
          color: #c43d53;
        }
      }
    }
  }
  .controll {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
    button {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      background-color: #666;
      color: #fff;
    }
  }
}
</style>