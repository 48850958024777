<template>
  <div class="horus-stripe-notification-view">
    <h1 class="centering-title">Stripe警告一覧</h1>
    <h2>請求設定一覧</h2>
    <div class="display-setting">
      <div class="display-selection" @click="changeDisplayState(10)">
        <horus-custom-check-box
          :active="displayState == 10"
          :enabled="true"
          :type="'color'"
          />
          すべて
      </div>
      <div class="display-selection" @click="changeDisplayState(0)">
        <horus-custom-check-box
          :active="displayState == 0"
          :enabled="true"
          :type="'color'"
          />
          発生のみ
      </div>
      <div class="display-selection" @click="changeDisplayState(1)">
        <horus-custom-check-box
          :active="displayState == 1"
          :enabled="true"
          :type="'color'"
          />
          解決のみ
      </div>
      <div class="display-selection" @click="changeDisplayState(2)">
        <horus-custom-check-box
          :active="displayState == 2"
          :enabled="true"
          :type="'color'"
          />
          無視のみ
      </div>
    </div>
    <div class="stripe-notificaton-list">
      <div class="stripe-notification"
        v-for="notification in stripeNotifications"
        :key="'sn' + notification.id"
        >
          <div class="line-item">
            <span class="title">対象</span>{{ notification.target_object_type }}
          </div>
          <div class="line-item">
            <span class="title">発生イベント</span>{{ notification.event_name }}
          </div>
          <div class="line-item">
            <span class="title">対象ID</span>{{ notification.target_object_id }}
          </div>
          <div class="line-item">
            <span class="title">状態</span>{{ notification.state_jp }}
          </div>
          <div class="line-item">
            <span class="title">発生日時</span>{{ createdAtText(notification) }}
          </div>
          <div class="line-item">
            <span class="title">備考</span>{{ notification.memo }}
          </div>
          <div class="controll">
            <button class="editMemo-btn" @click="editMemo(notification)" v-if="notification.state == 0">備考の編集</button>
            <button class="resolve-btn" @click="resolveNotificaton(notification)" v-if="notification.state == 0">解決済みにする</button>
            <button class="ignored-btn" @click="ignoreNotificaton(notification)" v-if="notification.state == 0">無視する</button>
          </div>
        </div>
    </div><!-- notification-list -->
    <horus-stripe-notification-memo-edit-modal
      :stripeNotification="targetNotification"
      :modalDisplay="memoEditModalDisplay"
      v-on:cancelEvent="cancelEditMemo"
      v-on:submitEvent="updateMemo"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { StripeNotificaton } from '@/api'

import HorusStripeNotificationMemoEditModal from '@/components/manageSystem/StripeNotification/HorusStripeNotificatonMemoEditModal.vue'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusStripeNotificationView',

  components: {
    HorusStripeNotificationMemoEditModal,
    HorusConfirmDialog,
    HorusCustomCheckBox,
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      stripeNotifications: [],
      displayState: 0,
      targetNotification: null,
      memoEditModalDisplay: false,
    }
  },

  mounted () {
    this.loadNotification()
  },

  methods: {
    loadNotification () {
      StripeNotificaton.fetchList({ targetState: this.displayState }).then(res => {
        this.stripeNotifications = res.stripe_notifications
      }).catch(error => {
        console.error(error)
      })
    },

    changeDisplayState (stateCode) {
      this.displayState = stateCode
      this.loadNotification()
    },

    createdAtText (notification) {
      let date = new Date(notification.created_at)
      return moment(date).format('YYYY/MM/DD HH:mm:ss')
    },

    resolveNotificaton (notification) {
      this.showConfirmDialog(
        'Stipe警告の解決',
        'Stripe警告を解決済みにします。この操作は元にもどす事ができません。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeResolveNotification(notification) }
      )
    },

    executeResolveNotification (notification) {
      let formData = new FormData()
      formData.append('id', notification.id)
      formData.append('state', 1)
      StripeNotificaton.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadNotification()
        this.showNotifyDialog('Stripe警告の解決', 'Stirpe警告を解決済みにしました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('Stripe警告の解決失敗', 'Stirpe警告を解決済みにすることに失敗しました。')
      })
    },

    ignoreNotificaton (notification) {
      this.showConfirmDialog(
        'Stipe警告の無視',
        'Stripe警告を無視にします。この操作は元にもどす事ができません。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeIgnoreNotification(notification) }
      )
    },

    executeIgnoreNotification (notification) {
      let formData = new FormData()
      formData.append('id', notification.id)
      formData.append('state', 2)
      StripeNotificaton.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadNotification()
        this.showNotifyDialog('Stripe警告の無視', 'Stirpe警告を無視にしました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('Stripe警告の無視失敗', 'Stirpe警告を無視にすることに失敗しました。')
      })
    },

    editMemo (notification) {
      this.targetNotification = notification
      this.memoEditModalDisplay = true
    },

    cancelEditMemo () {
      this.memoEditModalDisplay = false
      this.targetNotification = null
    },

    updateMemo (formData) {
      StripeNotificaton.update({ formData: formData }).then(res => {
        console.log(res)
        this.memoEditModalDisplay = false
        this.targetNotification = null
        this.loadNotification()
      }).catch(error => {
        console.error(error)
        this.memoEditModalDisplay = false
        this.targetNotification = null
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-stripe-notification-view {
  margin: 0;
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .display-setting {
    display: flex;
    .display-selection {
      display: flex;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .stripe-notificaton-list {
    .stripe-notification {
      border-top: 1px solid #ddd;
      background-color: #fff;
      .line-item {
        display: flex;
        margin: 5px 10px;
        .title {
          margin-right: 10px;
          font-weight: bold;
          font-size: 0.8rem;
        }
      }
      .controll {
        margin: 5px 0 0;
        padding-bottom: 10px;
        text-align: right;
        button {
          margin: 0 10px;
          border: 1px solid #999;
          border-radius: 3px;
          font-size: 14px;
          font-weight: bold;
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
}
</style>