<template>
  <div class="horus-organization-billing-adjust-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>請求調整</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'調整金額'"
          :necessary="false"
          :validationState="validateAdjustPrice.state"
          :validationMessage="validateAdjustPrice.message"
          :attributeNotify="''">
          <input type="number" size="8" v-model="adjustPrice" />円引き
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'調整名目'"
          :necessary="false"
          :validationState="validateAdjustPriceName.state"
          :validationMessage="validateAdjustPriceName.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="adjustPriceName" />
        </horus-attribute-in-form>
        <div class="controll">
          <button class="cancel-btn" @click="cancel">キャンセル</button>
          <button class="sumit-btn" :class="{ enable: allValid }" @click="submit">適用</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusOrganizationBillingAdjustModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    organizationBilling: Object,
    modalDisplay: Boolean,
  },

  emits: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      adjustPrice: 0,
      adjustPriceName: '',
    }
  },

  watch: {
    modalDisplay: { handler: 'initData', immediate: true },
  },

  computed: {
    validateAdjustPrice () {
      if (this.adjustPrice < 0 || this.adjustPrice > this.organizationBilling.billing_price) {
        return { valid: false, state: 'invalid', message: '調整金額は0以上で請求金額以下である必要があります。' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateAdjustPriceName () {
      if (this.adjustPriceName.trim().length <= 0) {
        return { valid: false, state: 'invalid', message: '調整名目は入力必須です。' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    allValid () {
      if (this.validateAdjustPrice.valid && this.validateAdjustPriceName.valid) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    initData () {
      this.adjustPrice = 0
      this.adjustPriceName = ''
    },

    cancel () {
      this.$emit('cancelEvent')
    },

    submit () {
      let formData = new FormData()
      formData.append('id', this.organizationBilling.id)
      formData.append('adjust_price', this.adjustPrice)
      formData.append('adjust_price_name', this.adjustPriceName)
      this.$emit('submitEvent', formData)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-organization-billing-adjust-modal {
  margin: 0;
  .modal-body {
    padding: 10px 20px;
    .controll {
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>