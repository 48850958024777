<template>
  <div class="horus-calcuration-unit-form-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ titleText }}</h1>
      </div>
      <div class="modal-body">
        <div class="calcuration-unit-form">
          <horus-attribute-in-form
            :labelText="'名称'"
            :necessary="true"
            :validationState="validateName.state"
            :validationMessage="validateName.message"
            :attributeNotify="'同一請求設定内で重複を許しません'">
            <input type="text" size="40" v-model="name" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'単位根拠'"
            :necessary="true"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="'単位数の計算根拠になる対象です'">
            <select v-model="basisTarget">
              <option v-for="target in basisTargets"
                :key="'bt' + target.value"
                :value="target.value">{{ target.name }}</option>
            </select>
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'単位数'"
            :necessary="true"
            :validationState="validateBasisUnitCount.state"
            :validationMessage="validateBasisUnitCount.message"
            :attributeNotify="'計算根拠の数をこの数で割った値が数量となります.1以上の整数'">
            <input class="number-type-input" type="number" size="4" v-model="basisUnitCount" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'桁丸め'"
            :necessary="true"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="''">
            <select v-model="roundTo">
              <option v-for="roundMethod in roundMethods"
                :key="'rt' + roundMethod.value"
                :value="roundMethod.value">{{ roundMethod.name }}</option>
            </select>
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'足切り'"
            :necessary="true"
            :validationState="validateCutoffCount.state"
            :validationMessage="validateCutoffCount.message"
            :attributeNotify="'単位数で割る前に引かれる数量です。0以上の整数'">
            <input class="number-type-input" type="number" size="4" v-model="cutoffCount" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'単価'"
            :necessary="true"
            :validationState="validateUnitPrice.state"
            :validationMessage="validateUnitPrice.message"
            :attributeNotify="'1以上の整数。'">
            <input type="number" size="8" v-model="unitPrice" />円
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'対象根拠'"
            :necessary="true"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="'課金対象の条件です。単位根拠で「静的」を選んだ際に対象となるかどうかの判定に利用します。'">
            <select v-model="applicableCondition">
              <option v-for="condition in applicableConditions"
                :key="'ac' + condition.id"
                :value="condition.id">{{ condition.name }}</option>
            </select>
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'備考'"
            :necessary="true"
            :validationState="validateMemo.state"
            :validationMessage="validateMemo.message"
            :attributeNotify="''">
            <textarea class="memo-input" v-model="memo" />
          </horus-attribute-in-form>
        </div>
        <div class="controll-column">
          <button class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button class="submit-btn" :class="{ enable: allValid }" @click="submitForm">{{ actionText }}</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { BillingSetting } from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusCalcurationUnitFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    modalDisplay: Boolean,
    calcurationSet: Object,
    calcurationUnit: Object,
  },

  emits: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      name: '',
      basisTarget: 0,
      basisUnitCount: 1,
      roundTo: 0,
      cutoffCount: 1,
      unitPrice: 0,
      applicableCondition: 100,
      memo: '',
      basisTargets: [
        { value: 0, name: '静的' },
        { value: 1, name: '職員数' },
        { value: 2, name: 'かかりつけ数' }
      ],
      roundMethods: [
        { value: 0, name: '切り上げ' },
        { value: 1, name: '四捨五入' },
        { value: 2, name: '切り捨て' }
      ],
      applicableConditions: [],
      nameState: '',
    }
  },

  mounted () {
    BillingSetting.unitApplicableConditions().then(res => {
      this.applicableConditions = res.applicable_conditions
    })
  },

  watch: {
    modalDisplay: { handler: 'initData', immediate: true },
    name: { handler: 'updateNameState' }
  },

  computed: {
    titleText () {
      if (this.calcurationUnit) {
        return '明細設定編集'
      }
      return '新規明細設定'
    },

    actionText () {
      if (this.calcurationUnit) {
        return '更新'
      }
      return '新規作成'
    },

    validateName () {
      if (this.name.trim().length <= 0 || this.name.trim().length > 128) {
        return { valid: false, state: 'invalid', message: '名前は入力必須で文字数は128文字以下です' }
      } else if (this.nameState === 'exist') {
        return { valid: false, state: 'invalid', message: '同一の名前が使用されています' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateBasisUnitCount () {
      if (this.basisUnitCount <= 0) {
        return { valid: false, state: 'invalid', message: '1以上の整数を入れてください。' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateCutoffCount () {
      if (this.cutoffCount < 0) {
        return { valid: false, state: 'invalid', message: '0以上の整数を入れてください。' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateUnitPrice () {
      if (this.unitPrice <= 0) {
        return { valid: false, state: 'invalid', message: '1以上の整数を入れてください。' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateMemo () {
      if (this.memo.trim().length <= 0) {
        return { valid: false, state: 'invalid', message: '備考は入力必須です' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    allValid () {
      if (this.validateName.valid && this.validateBasisUnitCount.valid && this.validateCutoffCount.valid && this.validateUnitPrice.valid) {
        return true
      } else {
        return false
      }
    }

  },

  methods: {
    initData () {
      if (this.calcurationUnit) {
        this.name = this.calcurationUnit.name
        this.basisTarget = this.calcurationUnit.basis_target
        this.basisUnitCount = this.calcurationUnit.basis_unit_count
        this.roundTo = this.calcurationUnit.round_to
        this.cutoffCount = this.calcurationUnit.cutoff_count
        this.unitPrice = this.calcurationUnit.unit_price
        this.applicableCondition = this.calcurationUnit.applicable_condition
        this.memo = this.calcurationUnit.memo
      } else {
        this.name = ''
        this.basisTarget = 0
        this.basisUnitCount = 1
        this.roundTo = 0
        this.cutoffCount = 0
        this.unitPrice = 1
        this.applicableCondition = 100
        this.memo = ''
      }
    },

    updateNameState () {
      let params = { id: this.calcurationSet.id, name: this.name }
      if (this.calcurationUnit) {
        params = { id: this.calcurationSet.id, name: this.name, unit_id: this.calcurationUnit.id }
      }
      BillingSetting.unitNameAvailable({ params: params }).then(res => {
        console.log(res)
        if (res.exist) {
          this.nameState = 'exist'
        } else {
          this.nameState = 'available'
        }
      })
    },

    cancelSubmit () {
      this.$emit('cancelEvent')
    },

    submitForm () {
      let formData = new FormData()
      formData.append('id', this.calcurationSet.id)
      if (this.calcurationUnit) {
        formData.append('organization_billing_calcuration_unit_id', this.calcurationUnit.id)
      }
      formData.append('organization_billing_calcuration_unit[name]', this.name)
      formData.append('organization_billing_calcuration_unit[basis_target]', this.basisTarget)
      formData.append('organization_billing_calcuration_unit[basis_unit_count]', this.basisUnitCount)
      formData.append('organization_billing_calcuration_unit[round_to]', this.roundTo)
      formData.append('organization_billing_calcuration_unit[cutoff_count]', this.cutoffCount)
      formData.append('organization_billing_calcuration_unit[unit_price]', this.unitPrice)
      formData.append('organization_billing_calcuration_unit[applicable_condition]', this.applicableCondition)
      formData.append('organization_billing_calcuration_unit[memo]', this.memo)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-calcuration-unit-form-modal {
  .modal-body {
    padding: 10px 20px;
    .number-type-input {
      margin-right: 10px;
      width: 4rem;
    }
    .memo-input {
      width: 80%;
    }
    .controll-column {
      text-align: right;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #aaa;
          color: #fff;
          &.enable {
            background-color: #666;
          }
        }
      }
    }
  }
}
</style>