<template>
  <div class="horus-discount-unit-form-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ titleText }}</h1>
      </div>
      <div class="modal-body">
        <div class="discount-unit-form">
          <horus-attribute-in-form
            :labelText="'名称'"
            :necessary="false"
            :validationState="validateName.state"
            :validationMessage="validateName.message"
            :attributeNotify="''">
            <input type="text" size="40" v-model="name" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'割引価格'"
            :necessary="usePrice"
            :validationState="validateDiscountPrice.state"
            :validationMessage="validateDiscountPrice.message"
            :attributeNotify="''">
            <horus-custom-check-box
              :active="usePrice"
              :enabled="true"
              :type="'color'"
              @click="toggleUsePrice"
              />
            <input class="discount-price" type="number" size="8" v-model="discountPrice" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'割引率'"
            :necessary="!usePrice"
            :validationState="validateDiscountRatio.state"
            :validationMessage="validateDiscountRatio.message"
            :attributeNotify="''">
            <horus-custom-check-box
              :active="!usePrice"
              :enabled="true"
              :type="'color'"
              @click="toggleUsePrice"
              />
            <input class="discount-ratio" type="number" size="2" v-model="discountRatio" />%
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'備考'"
            :necessary="false"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea class="memo-input" v-model="memo" />
          </horus-attribute-in-form>
        </div>
        <div class="controll-column">
          <button class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button class="submit-btn" :class="{ enable: allValid }" @click="submitForm">{{ actionText }}</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { BillingDiscount } from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusDiscountUnitFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox,
  },

  props: {
    modalDisplay: Boolean,
    discountUnit: Object,
  },

  emits: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      name: '',
      discountPrice: 0,
      discountRatio: 0,
      memo: '',
      nameState: '',
      usePrice: true,
    }
  },

  watch: {
    discountUnit: { handler: 'initData', immediate: true },
    name: { handler: 'updateNameState' }
  },

  computed: {
    titleText () {
      if (this.calcurationSet) {
        return '割引設定編集'
      }
      return '新規割引設定'
    },

    actionText () {
      if (this.calcurationSet) {
        return '更新'
      }
      return '新規作成'
    },

    validateName () {
      if (this.name.trim().length <= 0 || this.name.trim().length > 128) {
        return { valid: false, state: 'invalid', message: '名前は入力必須で文字数は128文字以下です' }
      } else if (this.nameState === 'exist') {
        return { valid: false, state: 'invalid', message: '同一の名前が使用されています' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateDiscountPrice () {
      if (this.usePrice) {
        if (this.discountPrice < 0) {
          return { valid: false, state: 'invalid', message: '0以上の整数で入力してください' }
        } else {
          return { valid: true, state: 'valid', message: '' }
        }
      } else {
        return { valid: true, state: 'none', message: '' }
      }
    },

    validateDiscountRatio () {
      if (this.usePrice) {
        return { valid: true, state: 'none', message: '' }
      } else if (this.discountRatio < 0 || this.discountRatio > 100) {
        return { valid: false, state: 'invalid', message: '0〜100の整数を入力してください。'}
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    allValid () {
      if (this.validateName.valid && this.validateDiscountPrice.valid && this.validateDiscountRatio.valid) {
        return true
      } else {
        return false
      }
    }

  },

  methods: {
    initData () {
      if (this.discountUnit) {
        this.name = this.discountUnit.name
        if (this.discountUnit.discount_price) {
          this.usePrice = true
          this.discountPrice = this.discountUnit.discount_price
        } else {
          this.discountPrice = 0
        }
        if (this.discountUnit.discount_ratio) {
          this.usePrice = false
          this.discountRatio = this.discountUnit.discount_ratio * 100
        } else {
          this.discountRatio = 0
        }
        this.memo = this.discountUnit.memo
      } else {
        this.name = ''
        this.discountPrice = 0
        this.discountRatio = 0
        this.memo = ''
      }
    },

    updateNameState () {
      let params = { name: this.name }
      if (this.discountUnit) {
        params = { name: this.name, id: this.discountUnit.id }
      }
      BillingDiscount.nameAvailable({ params: params }).then(res => {
        console.log(res)
        if (res.exist) {
          this.nameState = 'exist'
        } else {
          this.nameState = 'available'
        }
      })
    },

    toggleUsePrice () {
      this.usePrice = !this.usePrice
    },

    cancelSubmit () {
      this.$emit('cancelEvent')
    },

    submitForm () {
      let formData = new FormData()
      if (this.calcurationSet) {
        formData.append('id', this.calcurationSet.id)
      }
      formData.append('organization_billing_discount_unit[name]', this.name)
      if (this.usePrice) {
        formData.append('organization_billing_discount_unit[discount_price]', this.discountPrice)
      } else {
        formData.append('organization_billing_discount_unit[discount_ratio]', (this.discountRatio / 100))
      }
      formData.append('organization_billing_discount_unit[memo]', this.memo)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-discount-unit-form-modal {
  .modal-body {
    padding: 10px 20px;
    .number-type-input {
      margin-right: 10px;
      width: 4rem;
    }
    .discount-price {
      margin: 0 10px;
      width: 8rem;
    }
    .discount-ratio {
      margin: 0 10px;
      width: 4rem;
    }
    .memo-input {
      width: 80%;
    }
    .controll-column {
      text-align: right;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #aaa;
          color: #fff;
          &.enable {
            background-color: #666;
          }
        }
      }
    }
  }
}
</style>