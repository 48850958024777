<template>
  <div class="horus-select-end-application-organization-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ title }}</h1>
      </div>
      <div class="modal-body">
        <div class="search-condition">
          <label>名称検索<input type="text" v-model="searchName" /></label>
          <label>名称カナ検索<input type="text" v-model="searchNameKana"/></label>
          <button class="search-btn" @click="searchOrganization">検索実行</button>
        </div>
        <div class="search-result">
          <h3>検索結果</h3>
          <horus-applicated-organization-column
            v-for="appOrg in searchResult"
            :key="'sorg' + appOrg.id"
            @click="toggleSelection(appOrg)"
            :applicatedOrganization="appOrg"
            :selected="organizationSelected(appOrg.organization)"
            />
        </div>
        <div class="applicated-orgnizations">
          <h3>適用中一覧</h3>
          <horus-applicated-organization-column
            v-for="appOrg in applicatedOrganizations"
            :key="'org' + appOrg.id"
            @click="toggleSelection(appOrg)"
            :applicatedOrganization="appOrg"
            :selected="organizationSelected(appOrg.organization)"
            />
        </div>
        <div class="controll-column">
          <button class="all-deselect-btn" @click="deselectAll">すべて選択解除</button>
          <button class="cancel-btn" @click="cancelSelection">キャンセル</button>
          <button class="submit-btn" @click="submitSelection">決定</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'

import HorusApplicatedOrganizationColumn from '@/components/manageSystem/billingSetting/HorusApplicatedOrganizationColumn.vue'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
// import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusSelectEndApplicationOrganizationModal',

  components: {
    HorusApplicatedOrganizationColumn,
    HorusModalFrame,
    // HorusCustomCheckBox,
  },

  props: {
    title: String,
    modalDisplay: Boolean,
    applicatedOrganizations: Array,
  },

  emit: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      selectedOrganizations: [],
      searchName: '',
      searchNameKana: '',
      searchResult: [],
    }
  },

  watch: {
    modalDisplay: { handler: 'resetSelection', immediate: true },
  },

  methods: {
    formatedDate (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    organizationNumberForHuman (org) {
      return org.organizationNumber.substr(0, 4) + '-' + org.organizationNumber.substr(4, 4) + '-' + org.organizationNumber.substr(8, 4) + '-' + org.organizationNumber.substr(12, 4)
    },

    resetSelection () {
      this.selectedOrganizations = []
      this.searchResult = []
      this.searchName = ''
      this.searchNameKana = ''
    },

    searchOrganization () {
      this.searchResult = []
      if (this.searchName.length > 0) {
        if (this.searchNameKana.length > 0) {
          for(let appOrg of this.applicatedOrganizations) {
            if (appOrg.organization.name.includes(this.searchName) || appOrg.organization.name_kana.includes(this.searchNameKana)) {
              this.searchResult.push(appOrg)
            }
          }
        } else {
          for(let appOrg of this.applicatedOrganizations) {
            if (appOrg.organization.name.includes(this.searchName)) {
              this.searchResult.push(appOrg)
            }
          }
        }
      } else if (this.searchNameKana.length > 0) {
        for(let appOrg of this.applicatedOrganizations) {
          if (appOrg.organization.nameKana.includes(this.searchNameKana)) {
            this.searchResult.push(appOrg)
          }
        }
      }
    },

    organizationSelected (org) {
      if (this.selectedOrganizations.some((o) => o === org.id)) {
        return true
      } else {
        return false
      }
    },

    toggleSelection (org) {
      let orgIndex = this.selectedOrganizations.indexOf(org.id)
      if (orgIndex < 0) {
        this.selectedOrganizations.push(org.id)
      } else {
        this.selectedOrganizations.splice(orgIndex, 1)
      }
    },

    deselectAll () {
      this.selectedOrganizations = []
    },

    cancelSelection () {
      this.$emit('cancelEvent')
    },

    submitSelection () {
      this.$emit('submitEvent', this.selectedOrganizations)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-select-end-application-organization-modal {
  .modal-body {
    padding: 10px 20px;
    .search-condition {
      label {
        margin-right: 10px;
      }
      button {
        min-width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
      }
    }
    .search-result {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #ddd;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
    .applicated-orgnizations {
      h3 {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
    .controll-column {
      button {
        min-width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>