<template>
  <div class="horus-area-block-term-of-use-display-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ termOfUse.title }}</h1>
      </div>
      <div class="modal-body">
        <div class="controll">
          <button class="close-btn" @click="closeModal">閉じる</button>
        </div>
        <div class="content narrativebook_rule" v-html="parsedContent"></div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import moment from 'moment'
import { Marked } from 'marked'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusAreaBlockTermOfUseDisplayModal',

  components: {
    HorusModalFrame,
  },

  props: {
    termOfUse: Object,
    modalDisplay: Boolean,
  },

  emits: [
    'closeEvent',
  ],

  computed: {
    openAtStr () {
      if (this.openAt) {
        return moment(this.termOfUse.open_at).format('YYYY/MM/DD HH:mm')
      } else {
        return '-----'
      }
    },

    parsedContent () {
      const marked = new Marked()
      return marked.parse(this.termOfUse.content)
    },
  },

  methods: {
    closeModal () {
      this.$emit('closeEvent')
    },
  }
}
</script>

<style lang="scss">
.horus-area-block-term-of-use-display-modal {
  .modal-body {
    padding: 10px;
    .content {
      height: calc(100vh - 200px);
      overflow: auto;
      border: 1px solid #ddd;
    }
  }
}
</style>