import axios from './AxiosInstance'

export default {
  fetchList:  () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_discount_unit/list',
        withCredentials: true,
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetch: ({ targetID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_discount_unit',
        withCredentials: true,
        params: { id: targetID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  create: ({ formData }) => {
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization_billing_discount_unit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_discount_unit',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: ({ targetID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization_billing_discount_unit',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  lock: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_discount_unit/lock',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  depuricate: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_discount_unit/depuricate',
            withCredentials: true,
            params: { id: targetID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetchApplicatedOrganizations: ({ targetID, targetStatus }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_discount_unit/applicated_organization_list',
        withCredentials: true,
        params: { id: targetID, condition: targetStatus }
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  applicateOrganizations: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_discount_unit/applicate_organizations',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  endApplicateOrganizations: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing_discount_unit/end_applicate_organizations',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  nameAvailable: ({ params }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing_discount_unit/name_available',
        params: params,
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  }
}