<template>
  <div class="horus-album-photo" :class="photoDirection" :style="{ width: photoWidthStr, height: photoHeightStr, top: photoTop, left: photoLeft }">
    <img :src="photoObject.photoThumbnailSrc" />
  </div>
</template>

<script>
export default {
  name: 'HorusAlbumPhoto',

  props: {
    photoObject: Object,
    photoMinSize: Number,
    sizeType: String,
    photoDirection: String
  },

  computed: {
    photoRatio () {
      if (this.photoObject.imageWidth > this.photoObject.imageHeight) {
        return this.photoMinSize / this.photoObject.imageHeight
      } else {
        return this.photoMinSize / this.photoObject.imageWidth
      }
    },

    photoWidth () {
      return (this.photoObject.imageWidth * this.photoRatio)
    },

    photoHeight () {
      return (this.photoObject.imageHeight * this.photoRatio)
    },

    photoWidthStr () {
      return this.photoWidth + 'px'
    },

    photoHeightStr () {
      return this.photoHeight + 'px'
    },

    photoTop () {
      if (this.photoObject.imageWidth > this.photoObject.imageHeight) {
        return 0
      } else {
        return '-' + ((this.photoHeight - this.photoMinSize) / 2) + 'px'
      }
    },

    photoLeft () {
      if (this.photoObject.imageWidth > this.photoObject.imageHeight) {
        return '-' + ((this.photoWidth - this.photoMinSize) / 2) + 'px'
      } else {
        return 0
      }
    }
    // },

    // photoDirection () {
    //   if (this.photoObject.imageWidth > this.photoObject.imageHeight) {
    //     return 'landscape'
    //   } else {
    //     return 'portrate'
    //   }
    // }
  }

  // methods: {
  //   showPhoto () {
  //     this.$emit('showPhotoEvent', this.photoObject)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.horus-album-photo {
  position: absolute;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
