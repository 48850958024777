<template>
  <div class="horus-calcuration-unit-column">
    <div class="list-line">
      <div class="name"><span>名称:</span>{{ calcurationUnit.name }}</div>
    </div>
    <div class="list-line">
      <div class="target"><span>単位根拠:</span>{{ calcurationUnit.basis_target_jp }}</div>
      <div class="round"><span>桁丸め:</span>{{ calcurationUnit.round_to_jp }}</div>
      <div class="cutoff"><span>足切り:</span>{{ calcurationUnit.cutoff_count }}</div>
      <div class="price"><span>単価:</span>{{ Number(calcurationUnit.unit_price).toLocaleString() }}円</div>
      <div class="condition"><span>対象根拠:</span>{{ calcurationUnit.applicable_condition_jp }}</div>
    </div>
    <div class="list-line">
      <div class="memo"><span>備考:</span>{{ calcurationUnit.memo }}</div>
    </div>
    <div class="list-line" v-if="calcurationSet.state === 1">
      <div class="stripe"><span>Stripe Product ID:</span>{{ calcurationUnit.stripe_product_id }}</div>
    </div>
    <div class="list-line" v-if="calcurationSet.state === 1">
      <div class="stripe"><span>Stripe Meter ID:</span>{{ calcurationUnit.stripe_meter_id }}</div>
    </div>
    <div class="list-line" v-if="calcurationSet.state === 1">
      <div class="stripe"><span>Stripe Price ID:</span>{{ calcurationUnit.stripe_price_id }}</div>
    </div>
    <div class="list-line" v-if="editable && calcurationSet.state === 0">
      <div class="controll">
        <button type="button" class="show-btn" @click="editCalcurationUnit(calcurationUnit)" v-if="editable">編集</button>
        <button type="button" class="destroy-btn" @click="destroyCalcurationUnit(calcurationUnit)" v-if="editable">削除</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusCalcurationUnitColumn',

  props: {
    calcurationSet: Object,
    calcurationUnit: Object,
    editable: Boolean,
  },

  emits: [
    'editUnitEvent',
    'destroyUnitEvent',
  ],

  methods: {
    editCalcurationUnit () {
      this.$emit('editUnitEvent', this.calcurationUnit)
    },

    destroyCalcurationUnit () {
      this.$emit('destroyUnitEvent', this.calcurationUnit)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-calcuration-unit-column {
  border-top: 1px solid #999;
  .list-line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    div {
      padding: 3px;
      span { 
        font-size: 0.9em;
        font-weight: bold;
      }
    }
    .calcuration-units {
      .block-title {
        cursor: pointer;
        margin: 10px 0;
        font-weight: bold;
        img {
          width: 13px;
          transition: transform 300ms ease;
          &.open {
            transform: rotate(90deg);
          }
        }
        .caption {
          font-size: 12px;
          font-weight: normal;
          &.notice {
            color: #c43d53;
          }
        }
      }
    }
  }
  .controll {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
    button {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      background-color: #666;
      color: #fff;
    }
  }

}
</style>