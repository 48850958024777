<template>
  <div class="horus-discount-unit-column">
    <div class="list-line">
      <div class="name"><span class="title">名称:</span>{{ discountUnit.name }}</div>
    </div>
    <div class="list-line">
      <div class="target"><span class="title">割引:</span>{{ discountValue }}</div>
      <div class="state"><span class="title">状態:</span>{{ discountUnit.state_jp }}</div>
    </div>
    <div class="list-line">
      <div class="memo"><span class="title">備考:</span>{{ discountUnit.memo }}</div>
    </div>
    <div class="list-line">
      <div class="controll">
        <button type="button" class="show-btn" @click="showDiscountUnit" v-if="!editable">詳細表示</button>
        <button type="button" class="show-btn" @click="editDiscountUnit" v-if="editable && discountUnit.state === 0">編集</button>
        <button type="button" class="show-btn" @click="lockDiscountUnit" v-if="editable && discountUnit.state === 0">確定</button>
        <button type="button" class="destroy-btn" @click="depuricateDiscountUnit" v-if="editable && discountUnit.state === 1">停止</button>
        <button type="button" class="destroy-btn" @click="destroyDiscountUnit" v-if="editable && discountUnit.state === 0">削除</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusDiscountUnitColumn',

  props: {
    discountUnit: Object,
    editable: Boolean,
  },

  emits: [
    'showEvent',
    'editEvent',
    'lockEvent',
    'depuricateEvent',
    'destroyEvent',
  ],

  computed: {
    discountValue () {
      if (this.discountUnit) {
        if (this.discountUnit.discount_price) {
          return this.discountUnit.discount_price + '円引き'
        } else if (this.discountUnit.discount_ratio) {
          return this.discountUnit.discount_ratio * 100 + '%引き'
        }
      }
      return '-----'
    }
  },

  methods: {
    showDiscountUnit () {
      this.$emit('showEvent', this.discountUnit)
    },

    editDiscountUnit () {
      this.$emit('editEvent', this.discountUnit)
    },

    lockDiscountUnit () {
      this.$emit('lockEvent', this.discountUnit)
    },

    depuricateDiscountUnit () {
      this.$emit('depuricateEvent', this.discountUnit)
    },

    destroyDiscountUnit () {
      this.$emit('destroyEvent', this.discountUnit)
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-discount-unit-column {
  border-top: 1px solid #aaa;
  .list-line {
    display: flex;
    border-bottom: 1px solid #ddd;
    div {
      margin: 5px 10px;
      .title {
        margin-right: 10px;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
  }
  .controll {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
    button {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      background-color: #666;
      color: #fff;
    }
  }
}
</style>