<template>
  <div class="horus-create-member-invitation-modal">
    <horus-modal-frame
      :showModalFlag="showCreateMemberInvitationModal">
      <div class="modal-header">
        <h1>施設職員の案内状作成</h1>
      </div>
      <div class="modal-body" v-if="organization">
        <horus-attribute-in-form
          :labelText="'職員名(宛名)'"
          :necessary="true"
          :validationState="targetNameState"
          :validationMessage="targetNameNotify"
          :attributeNotify="'送信されるメールに表示される宛名です。'">
          <input type="text" v-model="targetName" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="true"
          :validationState="targetMailState"
          :validationMessage="targetMailNotify"
          :attributeNotify="''">
          <input type="text" v-model="targetMail" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'メールアドレス(確認)'"
          :necessary="true"
          :validationState="targetMailConfirmationState"
          :validationMessage="targetMailConfirmationNotify"
          :attributeNotify="''">
          <input type="text" v-model="targetMailConfirmation" />
        </horus-attribute-in-form>
        <horus-attribute-in-form
          :labelText="'本人確認用コード(数字4桁:空白の場合自動生成)'"
          :necessary="false"
          :validationState="targetShortTokenState"
          :validationMessage="targetShortTokenNotify"
          :attributeNotify="'※空白の場合は自動生成 ※2段階認証のため、このコードは本人にのみ伝えてください'">
          <input type="text" v-model="targetShortToken" />
        </horus-attribute-in-form>
        <div class="optional-attributes">
          <div class="caption">施設利用者への表示内容になります。 職員本人が後で入力・編集できます。</div>
          <horus-attribute-in-form
            :labelText="'表示用職員名'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="text" v-model="reservedName" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'表示用メールアドレス'"
            :necessary="false"
            :validationState="reservedMailState"
            :validationMessage="reservedMailNotify"
            :attributeNotify="''">
            <input type="text" v-model="reservedMail" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'表示用電話番号'"
            :necessary="false"
            :validationState="reservedTelState"
            :validationMessage="reservedTelNotify"
            :attributeNotify="''">
            <input type="text" v-model="reservedTel" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'職種'"
            :necessary="false"
            :validationState="'none'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="text" v-model="reservedAdditionalName" />
          </horus-attribute-in-form>
        </div><!-- optional attributes end -->
        <div class="optional-attributes">
          <div class="caption">下記の管理権限は事前に付与することができます。<br/>※ 管理権限は後で変更できます。</div>
          <div class="manager-reserve" @click="toggleManageRelation">
            <horus-custom-check-box
              :type="'color'"
              :active="reserveRelationManager"
              :enabled="true"
              />かかりつけの管理者権限
          </div>
          <div class="manager-reserve" @click="toggleManageDeputize">
            <horus-custom-check-box
              :type="'color'"
              :active="reserveDeputizeManager"
              :enabled="true"
              />設定代行の管理者権限
          </div>
        </div>
        <div class="submit-controll">
          <button class="cancel-btn" @click="cancelSendInvitation">キャンセル</button>
          <button class="submit-btn" :class="{ disable: !invitationReady }" @click="submitSendInvitation">案内状の送信</button>
        </div>
      </div><!-- modal body end -->
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import * as USER_ADDRESS_DATA from '@/assets/constants/addressData.js'

export default {
  name: 'HorusCreateMemberInvitationModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  props: {
    organization: Object,
    showCreateMemberInvitationModal: Boolean
  },

  data () {
    return {
      targetName: '',
      targetMail: '',
      targetMailConfirmation: '',
      targetShortToken: '',
      reservedName: '',
      reservedMail: '',
      reservedTel: '',
      reservedAdditionalName: '',
      reserveRelationManager: false,
      reserveDeputizeManager: false
    }
  },

  computed: {
    targetNameState () {
      if (this.targetName.length > 0) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetNameNotify () {
      if (this.targetName.length <= 0) {
        return '職員名(宛名)は入力必須です'
      } else {
        return ''
      }
    },

    targetMailState () {
      if (this.targetMail.length > 0 && this.targetMail.match(/.+@.+\..+/)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetMailNotify () {
      if (this.targetMail.length <= 0) {
        return 'メールアドレスは入力必須です'
      } else if (this.targetMail.match(/.+@.+\..+/)) {
        return ''
      } else {
        return 'メールアドレスの書式が正しくありません。'
      }
    },

    targetMailConfirmationState () {
      if (this.targetMail.length > 0 && this.targetMail === this.targetMailConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    targetMailConfirmationNotify () {
      if (this.targetMail !== this.targetMailConfirmation) {
        return '入力されたメールアドレスと一致しません'
      } else {
        return ''
      }
    },

    targetShortTokenState () {
      if (this.targetShortToken.length >= 0 && !this.targetShortToken.trim().match(/^\d{4}$/g)) {
        return 'invalid'
      } else {
        return 'valid'
      }
    },

    targetShortTokenNotify () {
      if (this.targetShortToken.length > 0 && !this.targetShortToken.trim().match(/^\d{4}$/g)) {
        return '本人確認コードは半角数字4桁で入力ください'
      } else {
        return ''
      }
    },

    reservedMailState () {
      if (this.reservedMail.trim().length <= 0) {
        return 'none'
      } else if (this.reservedMail.trim().match(/.+@.+\..+/)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    reservedMailNotify () {
      if (this.reservedMail.trim().length > 0 && !this.reservedMail.trim().match(/.+@.+\..+/)) {
        return 'メールアドレスの書式が正しくありません。'
      } else {
        return ''
      }
    },

    reservedTelState () {
      if (this.reservedTel.length <= 0) {
        return 'none'
      } else if (this.reservedTel.trim().match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    reservedTelNotify () {
      if (this.reservedTel.length <= 0 || this.reservedTel.trim().match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
        return ''
      } else {
        return '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
      }
    },

    invitationReady () {
      if (
        this.targetNameState === 'valid' &&
        this.targetMailState === 'valid' &&
        this.targetMailConfirmationState === 'valid' &&
        this.targetShortTokenState === 'valid'
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    initParams () {
      this.targetName = ''
      this.targetMail = ''
      this.targetMailConfirmation = ''
      this.targetShortToken = '0000'
      this.reservedName = ''
      this.reservedMail = ''
      this.reservedTel = ''
      this.reservedAdditionalName = ''
      this.reserveRelationManager = false
      this.reserveDeputizeManager = false
    },

    toggleManageRelation () {
      this.reserveRelationManager = !this.reserveRelationManager
    },

    toggleManageDeputize () {
      this.reserveDeputizeManager = !this.reserveDeputizeManager
    },

    cancelSendInvitation () {
      this.$emit('cancelSentInvitationEvent', () => { this.initParams() })
    },

    submitSendInvitation () {
      if (this.invitationReady) {
        var formData = new FormData()
        formData.append('organization_id', this.organization.id)
        formData.append('organization_member_invitation_token[name]', this.targetName)
        formData.append('organization_member_invitation_token[mail]', this.targetMail)
        formData.append('organization_member_invitation_token[short_token]', this.targetShortToken)
        formData.append('organization_service_member_reserve[name]', this.reservedName)
        formData.append('organization_service_member_reserve[mail]', this.reservedMail)
        formData.append('organization_service_member_reserve[tel]', this.reservedTel)
        formData.append('organization_service_member_reserve[role]', this.reservedAdditionalName)
        if (this.reserveRelationManager) {
          formData.append('organization_manager_reserve[manage_relation]', this.reserveRelationManager)
        }
        if (this.reserveDeputizeManager) {
          formData.append('organization_manager_reserve[manage_deputize]', this.reserveDeputizeManager)
        }
        this.$emit('submitSentInvitationEvent', formData, () => { this.initParams() })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-member-invitation-modal {
  .modal-body {
    padding: 0 20px;
    background-color: #fefefe;
    input {
      font-size: 16px;
      background-color: #fff;
    }
    .optional-attributes {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #aaa;
      .manager-reserve {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-weight: bold;
      }
    }
    .submit-controll {
      padding-bottom: 20px;
      text-align: right;
      button {
        cursor: pointer;
        border: 1px solid #666;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #666;
          color: #fff;
          &.disable {
            background-color: #aaa;
          }
        }
      }
    }
  }
}
</style>
