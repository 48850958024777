import * as USER_ADDRESS_DATA from '@/assets/constants/addressData.js'

export default class UserBasic {
  constructor (obj = {}) {
    this.userID = obj.user_id || null
    this.zipCode = obj.zip_code || ''
    this.prefecture = obj.prefecture || '00'
    this.city = obj.city || ''
    this.address = obj.address || ''
    this.latitude = obj.latitude || 0
    this.longitude = obj.longitude || 0
    this.tel = obj.tel || ''
    this.cellphone = obj.cellphone || ''
    this.fax = obj.fax || ''
    this.mail = obj.mail || ''
    this.updatedAt = obj.updated_at || null
    this.authorID = obj.author_id || null
  }

  isValid () {
    if (
      this.validateZipCode().valid &&
      this.validatePrefecture().valid &&
      this.validateTel().valid &&
      this.validateCellphone().valid &&
      this.validateFax().valid
    ) {
      return true
    } else {
      return false
    }
  }

  validateZipCode () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (!this.zipCode) {
      message = '郵便番号は入力必須です。空白を含めず1文字以上20文字以下で入力ください。'
    } else {
      var trimed = this.zipCode.trim()
      if (trimed.match(USER_ADDRESS_DATA.ZIP_CODE_REGXP)) {
        valid = true
        state = 'valid'
      } else {
        message = '郵便番号はハイフン区切りの正しい形式で入力してください。(例:000-0000)'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validatePrefecture () {
    var valid = false
    var state = 'invalid'
    var message = ''
    if (USER_ADDRESS_DATA.PREFECTURE_CODE_JP.some(pref => pref[0] === this.prefecture) && this.prefecture !== USER_ADDRESS_DATA.PREFECTURE_CODE_JP[0][0]) {
      valid = true
      state = 'valid'
    } else {
      message = '都道府県が指定されたフォーマットと異なります。'
    }
    return { valid: valid, state: state, message: message }
  }

  validateCity () {
    var valid = false
    var state = 'invalid'
    var message = ''
    var trimed = this.city.trim()
    if (!trimed || trimed.length <= 0) {
      message = '市町村区は入力必須です。空白を含めず1文字以上80文字以下で入力ください。'
    } else {
      valid = true
      state = 'valid'
    }
    return { valid: valid, state: state, message: message }
  }

  validateTel () {
    var valid = false
    var state = 'disabled'
    var message = ''
    if (!this.tel) {
      valid = true
      state = 'disabled'
    } else {
      var trimed = this.tel.trim()
      if (trimed.match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
        valid = true
        state = 'valid'
      } else {
        state = 'invalid'
        message = '電話番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateCellphone () {
    var valid = false
    var state = 'disabled'
    var message = ''
    if (!this.cellphone) {
      valid = true
      state = 'disabled'
    } else {
      var trimed = this.cellphone.trim()
      if (trimed.match(USER_ADDRESS_DATA.CELLPHONE_NUMBER_REGXP)) {
        valid = true
        state = 'valid'
      } else {
        state = 'invalid'
        message = '携帯電話番号はハイフン区切りの正しい形式で入力してください。(例:000-0000-0000)'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  validateFax () {
    var valid = false
    var state = 'disabled'
    var message = ''
    if (!this.fax) {
      valid = true
      state = 'disabled'
    } else {
      var trimed = this.fax.trim()
      if (trimed.match(USER_ADDRESS_DATA.TEL_NUMBER_REGXP)) {
        valid = true
        state = 'valid'
      } else {
        state = 'invalid'
        message = 'FAX番号はハイフン区切りの正しい形式で入力してください。(例:000-000-0000)'
      }
    }
    return { valid: valid, state: state, message: message }
  }

  toFormData (targetUserID = null) {
    if (this.isValid()) {
      var formData = new FormData()
      formData.append('target_user_id', targetUserID.id)
      formData.append('user_address[zip_code]', this.zipCode)
      formData.append('user_address[prefecture]', this.prefecture)
      formData.append('user_address[city]', this.city)
      formData.append('user_address[address]', this.address)
      formData.append('user_address[tel]', this.tel)
      formData.append('user_address[cellphone]', this.cellphone)
      formData.append('user_address[fax]', this.fax)
      formData.append('user_address[mail]', this.mail)
      return formData
    } else {
      return null
    }
  }
}
