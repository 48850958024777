<template>
  <div class="horus-select-applicate-organization-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ title }}</h1>
      </div>
      <div class="modal-body">
        <div class="display-tags">
          <div class="label">監理団体絞り込み</div>
          <div class="organization-tag"
            v-for="area in areaBlocks"
            :key="area.id"
            @click="toggleDisplayAreaBlock(area)">
            <horus-custom-check-box
              :active="displayAreaBlocks.some((a) => a === area.id)"
              :enabled="true"
              :type="'color'"
              />
            {{ area.name }}
          </div>
        </div><!-- display tag controll -->
        <div class="display-tags">
          <div class="label">タグ絞り込み</div>
          <div class="organization-tag"
            v-for="tag in organizationTags"
            :key="tag.id"
            @click="toggleDisplayTag(tag)">
            <horus-custom-check-box
              :active="displayTags.some((t) => t === tag.id)"
              :enabled="true"
              :type="'color'"
              />
            {{ tag.name }}
          </div>
        </div><!-- display tag controll -->
        <div class="display-setting">
          <horus-custom-check-box
            :active="showOnlySelectable"
            :enabled="true"
            :type="'color'"
            />
          選択可能のみ表示
        </div>
        <div class="organizations">
          <div class="organization"
            v-for="organization in organizations"
            :key="'org' + organization.id"
            v-show="organizationVisible(organization)"
            @click="toggleSelectOrganization(organization)">
            <horus-custom-check-box
              :active="organizationSelected(organization)"
              :enabled="organizationSelectable(organization)"
              :type="'color'"
              />
            <div class="name">{{ organization.name }}</div>
            <div class="number">({{ organizationNumberForHuman (organization) }})</div>
            <div class="address">{{ organization.address }}</div>
            <div class="nubmer">{{ organization.stripe_customer_id }}</div>
          </div>
        </div>
        <div class="controll-column">
          <button class="all-select-btn" @click="selectAll">表示中をすべて選択</button>
          <button class="all-deselect-btn" @click="deselectAll">すべて選択解除</button>
          <button class="cancel-btn" @click="cancelSelection">キャンセル</button>
          <button class="submit-btn" @click="submitSelection">決定</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { Organization, AreaBlock } from '@/models'
import { OrganizationTag } from '@/api'

import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusSelectApplicateOrganizationModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox,
  },

  props: {
    title: String,
    modalDisplay: Boolean,
    applicatedOrganizations: Array,
  },

  emit: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      organizations: [],
      selectedOrganizations: [],
      displayOrganizations: [],
      organizationTags: [],
      displayTags: [],
      areaBlocks: [],
      displayAreaBlocks: [],
      showOnlySelectable: true
    }
  },

  mounted () {
    Organization.api().listForBilling().then(res => {
      this.organizations = res.organizations
    })
    OrganizationTag.fetchList().then(res => {
      console.log(res)
      this.organizationTags = res.organization_tags
    })
    AreaBlock.api().fetchList().then(res => {
      console.log(res)
      this.areaBlocks = res
    })
  },

  watch: {
    modalDisplay: { handler: 'resetSelection', immediate: true }
  },
  
  methods: {
    organizationNumberForHuman (org) {
      return org.organizationNumber.substr(0, 4) + '-' + org.organizationNumber.substr(4, 4) + '-' + org.organizationNumber.substr(8, 4) + '-' + org.organizationNumber.substr(12, 4)
    },

    resetSelection () {
      this.selectedOrganizations = []
    },

    toggleShowOnlySelectable () {
      this.showOnlySelectable = !this.showOnlySelectable
    },

    toggleDisplayTag (tag) {
      let tagIndex = this.displayTags.indexOf(tag.id)
      if (tagIndex < 0) {
        this.displayTags.push(tag.id)
      } else {
        this.displayTags.splice(tagIndex, 1)
      }
    },

    toggleDisplayAreaBlock (areaBlock) {
      let abIndex = this.displayAreaBlocks.indexOf(areaBlock.id)
      if (abIndex < 0) {
        this.displayAreaBlocks.push(areaBlock.id)
      } else {
        this.displayAreaBlocks.splice(abIndex, 1)
      }
    },

    organizationSelected (org) {
      if (this.selectedOrganizations.some((o) => o === org.id)) {
        return true
      } else {
        return false
      }
    },

    organizationSelectable (org) {
      if (this.applicatedOrganizations.some((ao) => ao.organization.id === org.id)) {
        return false
      } else {
        return true
      }
    },

    organizationVisible (org) {
      if (this.showOnlySelectable && !this.organizationSelectable(org)) {
        return false
      }
      if (this.displayTags.length <= 0 && this.displayAreaBlocks.length <= 0) {
        return true
      } else {
        let tagDisplay = false
        let areaBlockDisplay = false
        if (this.displayTags.length > 0) {
          for(let tag of this.displayTags) {
            for(let orgTag of org.tags) {
              if (tag === orgTag) {
                tagDisplay = true
              }
            }
          }
        } else {
          tagDisplay = true
        }
        if (this.displayAreaBlocks.length > 0) {
          for(let areaBlock of this.displayAreaBlocks) {
            if (org.area_block_id === areaBlock) {
              areaBlockDisplay = true
            }
          }
        } else {
          areaBlockDisplay = true
        }
        return tagDisplay && areaBlockDisplay
      }
    },

    toggleSelectOrganization (org) {
      if (this.organizationSelectable(org)) {
        let orgIndex = this.selectedOrganizations.indexOf(org.id)
        if (orgIndex < 0) {
          this.selectedOrganizations.push(org.id)
        } else {
          this.selectedOrganizations.splice(orgIndex, 1)
        }
      }
    },

    selectAll () {
      this.selectedOrganizations = []
      for(let org of this.organizations) {
        if (this.organizationVisible(org) && this.organizationSelectable(org)) {
          this.selectedOrganizations.push(org.id)
        }
      }
    },

    deselectAll () {
      this.selectedOrganizations = []
    },

    cancelSelection () {
      this.$emit('cancelEvent')
    },

    submitSelection () {
      this.$emit('submitEvent', this.selectedOrganizations)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-select-applicate-organization-modal {
  .modal-body {
    padding: 10px 20px;
    .display-tags {
      display: flex;
      .organization-tag {
        margin: 5px 10px;
        display: flex;
        align-items: center;
      }
    }
    .display-setting {
      display: flex;
      align-items: center;
      margin: 10px;
    }
    .organizations {
      border-top: 1px solid #aaa;
      .organization {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        .name {
          padding-right: 10px;
          font-weight: bold;
          width: 200px;
        }
        .number {
          width: 150px;
        }
      }
    }
    .controll-column {
      padding: 10px 0;
      button {
        min-width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
      }
    }
  }
}
</style>