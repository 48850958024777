<template>
  <div class="horus-manage-organization-basic">
    <horus-manage-organization-frame
      :currentDisplay="'basic'"
      :signinMember="signinMember"
      :organization="organization"
      >
      <h1 class="centering-title">基本情報</h1>
      <div class="red_titled_column" v-if="organization">
        <h4>{{ organization.name }}<img src="/static/images/pencil_icon.png" class="edit-organization-btn" @click="edit"/></h4>
        <div class="list-data">
          <div class="list-data-line">
            <div class="labels"><label>医療機関コード</label></div>
            <div class="content">{{ organization.medicalInstitutionCode }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>施設番号</label></div>
            <div class="content">{{ organization.numberForHuman }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>施設名称</label></div>
            <div class="content">{{ organization.name }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>ヨミガナ</label></div>
            <div class="content">{{ organization.nameKana }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>略称</label></div>
            <div class="content">{{ organization.shortName }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>郵便番号</label></div>
            <div class="content">{{ organization.zipCode }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>住所</label></div>
            <div class="content">{{ organization.address }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>電話番号</label></div>
            <div class="content">{{ organization.tel }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>メールアドレス</label></div>
            <div class="content">{{ organization.mail }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>設定代行権限</label></div>
            <div class="content">{{ deputizableStr }}</div>
          </div>
          <div class="list-data-line">
            <div class="labels"><label>追加機能</label></div>
            <div class="content">{{ additionalFunctions }}</div>
          </div>
        </div>
      </div>
      <div class="red_titled_column" v-if="organization">
        <h4>第三者サービス連携</h4>
        <div class="list-data">
          <div class="list-data-line"
            v-for="linkage in organization.thirdPartyLinkages"
            :key="'orgTPL' + linkage.id">
            <div class="labels"><label>{{ linkage.name }}</label></div>
            <div class="content">
              {{ linkage.description }}
              <button class="show-linkage-btn" @click="showThirdPartyLinkage(linkage)">詳細</button>
              <button class="remove-linkage-btn" @click="removeThirdPartyLinkage(linkage)">利用停止</button>
            </div>
          </div>
        </div>
      </div>
      <div class="red_titled_column" v-if="signinMember.member_manager">
        <h4>支払い/請求の管理</h4>
        <div class="list-data">
          <div class="list-data-line">
            <button class="stripe-portal-btn" @click="openStripe">Stripeポータルを開く</button>
          </div>
        </div>
      </div>
      <div class="red_titled_column" v-if="signinMember.member_manager">
        <h4>施設の管理</h4>
        <div class="list-data">
          <div class="list-data-line">
            <div class="labels"><label>施設の退会申請</label></div>
            <div class="content">
              <div class="submit-revoke" v-if="organization && (!organization.revoke_log || organization.revoke_log.restored)">
                <div class="notify-caption">
                  ここで施設のナラティブブック退会の申請が行なえます。<br/>
                  以下の注意事項をよく読んでから手続きを行ってください。<br/><br/>
                  施設のナラティブブック退会のためには、以下の条件を満たす必要があります。<br/>
                  条件を満たしていない場合は、それぞれの処理を完了させてください。<br/>
                  <ul>
                    <li>設定代行可能な施設の場合、自施設のみが設定代行者となっている利用者がいないこと</li>
                    <li>未処理の職員案内がないこと</li>
                    <li>未処理のかかりつけ案内がないこと</li>
                    <li>未処理の設定代行案内がないこと</li>
                  </ul>
                  条件を満たしている場合、下記フォームの「上記確認事項を確認しました」にチェックを入れ、施設管理者であるあなたのNBIDとログインパスワードを入力して「施設退会申請」をクリックしてください。<br/>
                  システム管理者に施設の退会申請が通知され、施設の削除処理が行われます。<br/>
                  施設の削除処理はシステム管理者の操作によるもので、実行されるまで時間がかかる場合があります。<br/>
                  削除申請送信後は、以下の操作は一切できなくなります。<br/>
                  <ul>
                    <li>かかりつけ案内の作成・送信</li>
                    <li>かかりつけ申込みの受理</li>
                    <li>設定代行に関する全ての処理(設定代行の管理画面にアクセスできなくなります)</li>
                    <li>職員案内の作成・送信</li>
                  </ul>
                  上記以外のタイムライン閲覧、書き込みなどの操作は行えます。<br/>
                  施設の削除処理が完了した場合、利用者とのかかりつけ関係は自動的に削除されますが、
                  これまでにかかりつけ利用者のタイムラインに書き込まれた情報は削除されることはありません。<br/>
                  また、施設の削除に伴い、施設職員全員の職員としてのアカウントは削除されますが、削除されたあとも、個人として、ナラティブブックはご利用いただけます。<br/>
                  施設削除後も、個人としてのログインに際して利用するNBIDとログインパスワードに変更はありません。<br/>
                  また、施設削除後もシステム管理者に申請することで、削除前の状態に施設を復帰させることも可能です。<br/>
                </div>
                <div class="revoke-form">
                  <div class="admit-agreement" @click="toggleAdmit">
                    <horus-custom-check-box
                      :active="admitAgreement"
                      :enabled="true"
                      :type="'color'"
                      />
                    上記確認事項を確認しました
                  </div>
                  <label>NBID: <input type="text" v-model="submitNBID" /></label>
                  <label>パスワード: <input type="password" v-model="submitPassword" /></label>
                  <button class="submit-revoke-btn" :class="{ready: prepareRevokeReady}" @click="submitPrepareRevoke">施設退会申請</button>
                </div>
              </div>
              <div class="cancel-revoke" v-if="organization && organization.revoke_log && !organization.revoke_log.restored">
                <div class="revoke-prepared-notify">
                  現在、退会申請を受付中です。<br/>
                  システム管理者による削除処理実行をお待ち下さい。<br/>
                  申請の確認のためにシステム管理者より連絡が来る場合があります。<br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </horus-manage-organization-frame>
    <horus-system-organization-edit-modal
      :sourceOrganization="organization"
      :showOrganizationEditModal="showOrganizationEditModal"
      :submitString="'更 新'"
      v-on:submitCancelEvent="cencelEditSubmit"
      v-on:submitEvent="submitEdit"
      />
    <horus-member-profile-third-party-linkage-modal
      :linkage="targetThirdPartyLinkage"
      :showThirdPartyLinkageModal="showThirdPartyLinkageModal"
      v-on:closeEvent="closeThirdPartyLinkageModal"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { Organization } from '@/models'
import { OrganizationThirdPartyLinkageRelation, StripeCustomer } from '@/api'


import HorusSystemOrganizationEditModal from '@/components/manageSystem/organization/HorusSystemOrganizationEditModal.vue'
import HorusMemberProfileThirdPartyLinkageModal from '@/components/memberProfile/HorusMemberProfileThirdPartyLinkageModal.vue'
import HorusManageOrganizationFrame from '@/components/manageOrganization/HorusManageOrganizationFrame.vue'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageOrganizationBasicView',

  components: {
    HorusSystemOrganizationEditModal,
    HorusMemberProfileThirdPartyLinkageModal,
    HorusManageOrganizationFrame,
    HorusCustomCheckBox,
    HorusConfirmDialog
  },

  props: {
    signinMember: Object,
    organization: Object,
  },

  data () {
    return {
      showOrganizationEditModal: false,
      showThirdPartyLinkageModal: false,
      targetThirdPartyLinkage: null,
      admitAgreement: false,
      submitNBID: '',
      submitPassword: ''
    }
  },

  mounted() {
    this.$emit('reloadOrganizationEvent')
  },

  computed: {
    additionalFunctions () {
      var functions = []
      if (this.organization.additionalFunctions.some(func => func === 0)) {
        functions.push('バイタル入力機能')
      }
      if (this.organization.additionalFunctions.some(func => func === 1)) {
        functions.push('お薬情報')
      }
      return functions.join(', ')
    },

    deputizableStr () {
      if (this.organization.deputizable) {
        return 'あり'
      } else {
        return 'なし'
      }
    },

    prepareRevokeReady () {
      if (this.submitNBID && this.submitNBID.length > 0 && this.submitPassword && this.submitPassword.length > 0 && this.admitAgreement) {
        return true
      }
      return false
    }

  },

  methods: {
    edit () {
      this.showOrganizationEditModal = true
    },

    cencelEditSubmit (callback) {
      this.showOrganizationEditModal = false
      callback()
    },

    submitEdit (formData, callback) {
      Organization.api().update({ formData: formData }).then(() => {
        this.$emit('reloadOrganizationEvent')
        this.showOrganizationEditModal = false
        // TODO: fire app event
        if (this.$native.status) {
          this.$native.event('userProfileUpdated', { updatedAt: new Date(), targetAuthorID: this.signinMember.author_id })
        }
        callback()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('施設更新失敗', '施設の情報更新に失敗しました。')
      })
    },

    showThirdPartyLinkage (linkage) {
      this.targetThirdPartyLinkage = linkage
      this.showThirdPartyLinkageModal = true
    },

    closeThirdPartyLinkageModal () {
      this.showThirdPartyLinkageModal = false
      this.targetThirdPartyLinkage = null
    },

    removeThirdPartyLinkage (linkage) {
      this.showConfirmDialog(
        '第三者サービス連携の利用停止',
        '第三者サービス連携を利用停止します。\n利用停止すると、職員が利用しているこの第三者サービス連携ログイン情報が利用出来なくなります。\n再度利用するためには、システム管理者による作業が必要となります。\n利用停止しますか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeRemoveThirdPartyLinkage(linkage) }
      )
    },

    executeRemoveThirdPartyLinkage (linkage) {
      console.log('remove linkage.')
      OrganizationThirdPartyLinkageRelation.destroy({ organizationID: this.organization.id, targetID: linkage.id }).then(() => {
        var index = this.targetThirdPartyLinkage.findIndex(link => link.id === linkage.id )
        if (index >= 0) {
          this.targetThirdPartyLinkage.splice(index, 1)
        }
        this.$emit('reloadOrganizationEvent')
        this.showNotifyDialog('利用停止', '第三者サービス連携を利用停止しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('停止失敗', '第三者サービス連携の停止に失敗しました。')
      })
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    submitPrepareRevoke () {
      if (this.submitNBID && this.submitNBID.length > 0 && this.submitPassword && this.submitPassword.length > 0 && this.admitAgreement) {
        this.showConfirmDialog(
          '施設退会申請の送信',
          '施設の退会申請を送信します。\n送信後、システム管理者によって施設は削除されます。\n削除申請を送信しますか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executePrepareRevoke() }
        )
      } else {
        this.showNotifyDialog('施設退会申請', 'フォームに、施設管理者であるあなたのNBIDとログインパスワードを入力し、確認事項のチェックを行ってください。')
      }
    },

    executePrepareRevoke () {
      var formData = new FormData()
      if (this.admitAgreement) {
        formData.append('admit_quit', true)
      }
      formData.append('id', this.organization.id)
      formData.append('login_id', this.submitNBID)
      formData.append('password', this.submitPassword)
      Organization.api().prepareRevoke({ formData: formData }).then(res => {
        console.log('prepare revoke send.')
        console.log(JSON.stringify(res))
        if (res.success) {
          this.$emit('reloadOrganizationEvent')
          this.showNotifyDialog('施設退会申請', '施設の退会申請を送信しました。')
        } else {
          this.showNotifyDialog('施設退会申請失敗', res.message)
        }
      }).catch(error => {
        console.error(error.response)
      })
    },

    cancelRevoke () {},

    executeCancelRevoke () {},

    openStripe () {
      StripeCustomer.customerPortalURL({targetID: this.organization.id}).then(res => {
        console.log(res)
        window.location.href = res.redirect_url
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/centeringTitle.scss';
@import '@/assets/css/redTitledColumn.scss';
@import '@/assets/css/listData.scss';

.horus-manage-organization-basic {
  margin: 0;
  .remove-linkage-btn {
    margin-left: 30px;
    width: 100px;
    margin-left: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #666;
    color: #fff;
  }
  .stripe-portal-btn {
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #666;
    color: #fff;
  }
  .submit-revoke {
    .notify-caption {
      padding: 5px;
      border: 1px solid #ddd;
      font-size: 0.9em;
      ul {
        margin: 5px 0;
        padding-inline-start: 20px;
      }
    }
    .revoke-form {
      margin: 5px 0;
      padding: 10px;
      border: 1px solid #aaa;
      label {
        margin-right: 10px;
      }
      .submit-revoke-btn {
        padding: 3px;
        border-radius: 3px;
        border: 1px solid #666;
        background-color: #ddd;
        color: #fff;
        font-weight: bold;
        &.ready {
          background-color: #666;
        }
      }
    }
  }
  .revoke-prepared-notify {
    padding: 5px;
    border: 1px solid #c43d53;
  }
}
</style>
