<template>
  <div class="background">
    <router-view/>
  </div>
</template>

<script>
import 'normalize.css'

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import 'assets/css/global.scss';
@import 'assets/css/mediaQuery.scss';

#app {
  height: 100%;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}

.container {
  height: 100%;
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  overflow: auto;
  @include mediaQuery('tablet') {
    width: 100%;
  }
  @include mediaQuery('phone') {
    width: 100%;
  }
}

.general-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.001;
  cursor: pointer;
}

.navbar {
  .main-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    height: 34px;
    padding: 8px;
    background-color: #692220;
    background-image: url("/static/images/nb_header_iconless.png");
    color: #fff;
    &.member {
      background-color: #2b4733;
      background-image: url("/static/images/cgc_header_iconless.png");
    }
    &.reporter {
      background-color: #4f455c;
      background-image: url("/static/images/reporter_header_iconless.png");
    }
    .navi-logo-icon {
      width: 36px;
      height: 36px;
    }
    .navbar-dropdown {
      ul {
        position: absolute;
        top: 40px;
        right: 10px;
        z-index: 1000;
        margin: 0;
        padding: 0;
        width: 250px;
        background-color: #fff;
        border: 1px solid #aaa;
        li {
          padding: 0;
          border-top: 1px solid #aaa;
          list-style-type: none;
          color: #666;
          text-align: left;
          a {
            display: inline-block;
            padding: 10px 20px;
            width: calc(100% - 40px);
            color: #666;
            text-decoration: none;
          }
        }
      }
    }
  }
}

// general style
button {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1em;
  color: #ffffff;
}

.btn-sm {
  @extend .btn;
  padding: 4px 8px;
}

.btn-success {
  @extend .btn;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-warning {
  @extend .btn;
  background-color: #f39800;
  border-color: #f39800;
}

iframe {
  width: 100%;
  height: 100%;
}

// hide google reCAPTCHA badge.
.grecaptcha-badge { visibility: hidden; }

.narrativebook_rule {
  padding: 10px;
  background-color: #fff;
  h1 {
    margin: 20px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  h2 {
    margin: 20px 0;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
    &.top {
      margin-top: 0;
    }
    &.centering {
      text-align: center;
    }
  }
  h3 {
    margin: 3px 0;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
  }
  h4 {
    margin: 3px 0;
    font-size: 0.9em;
    font-weight: normal;
    color: #000;
    border-bottom: 1px solid #ddd;
  }
  p {
    margin: 3px 0;
    font-size: 0.9em;
  }
  .rule-content.en {
    padding: 0 30px;
    word-break: normal;
    @include mediaQuery('phone') {
      padding: 0;
    }
    h1 {
      margin: 20px 0;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      text-align: center;
    }
    h2 {
      margin: 20px 0 10px;
      padding-bottom: 3px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #000;
      &.centering {
        border: none;
        @include mediaQuery('phone') {
          margin-top: 0;
        }
      }
      &.first {
        margin-top: 0;
      }
    }
    h3 {
      margin: 3px 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
      color: #000;
    }
    h4 {
      margin: 3px 0;
      font-size: 1em;
      font-weight: normal;
      color: #000;
    }
    p {
      margin: 0;
      margin-top: 10px;
      margin-left: 50px;
      font-size: 1em;
      @include mediaQuery('phone') {
        margin-left: 10px;
      }
    }
    table.difinition-table {
      width: 100%;
      border: none;
      border-collapse: collapse;
      td {
        padding-top: 10px;
        border: none;
        vertical-align: top;
      }
      .index {
        width: 44px;
        padding-right: 5px;
        vertical-align: top;
        text-align: right;
        @include mediaQuery('phone') {
          width: 24px;
        }
      }
      .title {
        padding-right: 5px;
        white-space: nowrap;
        @include mediaQuery('phone') {
          width: 50px;
        }
      }
      .content {
        margin: 0;
        @include mediaQuery('phone') {
          width: calc(100% - 44px - 50px);
        }
      }
    }
    ol {
      margin: 0;
      margin-top: 10px;
      // margin-left: 50px;
      padding-inline-start: 50px;
      @include mediaQuery('phone') {
        padding-inline-start: 20px;
      }
      li {
        margin: 0;
        margin-top: 10px;
      }
      &.round-bracket {
        li {
          list-style-type: none;
          counter-increment: cnt;
        }
        li::before {
          display: inline-block;
          width: 45px;
          margin-left: -50px;
          padding-right: 5px;
          text-align: right;
          content: "(" counter(cnt) ")";
          @include mediaQuery('phone') {
            // margin-left: -30px;
          }
        }
      }
    }
  }
}

</style>
