<template>
  <div class="horus-organization-billing-memo-edit-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>備考編集</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'備考'"
          :necessary="false"
          :validationState="'valid'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea v-model="memo" />
        </horus-attribute-in-form>
        <div class="controll">
          <button class="cancel-btn" @click="cancel">キャンセル</button>
          <button class="sumit-btn" @click="submit">適用</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusOrganizationBillingMemoEditModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    organizationBilling: Object,
    modalDisplay: Boolean,
  },

  emits: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      memo: '',
    }
  },

  watch: {
    modalDisplay: { handler: 'initData', immediate: true },
  },

  methods: {
    initData () {
      if (this.organizationBilling) {
        this.memo = this.organizationBilling.memo
      } else {
        this.memo = ''
      }
    },

    cancel () {
      this.$emit('cancelEvent')
    },

    submit () {
      let formData = new FormData()
      formData.append('id', this.organizationBilling.id)
      formData.append('memo', this.memo)
      this.$emit('submitEvent', formData)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-organizatoin-billing-memo-edit-modal {
  margin: 0;
}
</style>