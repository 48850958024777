<template>
  <div class="horus-drug-entry-display">
    <div class="title">
      <img src="/static/images/drug_green.png" />お薬情報
    </div>
    <div class="comment-display" v-show="drugEntry.comment && drugEntry.comment.length > 0">
      <div class="label">コメント</div>
      <div class="comment" v-html="drugEntryCommentHTML"></div>
    </div>
    <div class="stored-photos" v-show="drugEntry.storedPhotos.length > 0">
      <div class="label">投稿済み写真</div>
      <div class="stored-photo-thumbnails">
        <horus-inline-photo
          v-for="storedPhoto in drugEntry.storedPhotos"
          :key="'storedPhoto' + storedPhoto.id"
          :photoObject="storedPhoto"
          :photoMaxSize="100"
          :deleted="drugPhotoRemoveChecked(storedPhoto.id)"
          v-on:timeliePhotoClickEvent="showPhoto(storedPhoto)" />
      </div>
    </div>
    <div class="selected-photos" v-show="drugEntry.photos.length > 0">
      <div class="selected-photo-thumbnail-block" v-for="(selectedPhoto, index) in drugEntry.photos" :key="'selectdphoto' + index">
        <img class="selected-photo-thumbnail" :src="selectedPhoto.thumbnail" />
      </div>
    </div>
  </div>
</template>

<script>
import HorusInlinePhoto from '@/components/common/HorusInlinePhoto.vue'

export default {
  name: 'HorusDrugEntryDisplay',

  components: {
    HorusInlinePhoto
  },

  props: {
    drugEntry: Object
  },

  computed: {
    drugEntryCommentHTML () {
      if (this.drugEntry.comment) {
        return this.drugEntry.comment.replace(/\n/gi, '<br />')
      } else {
        return ''
      }
    },
  },

  methods: {
    drugPhotoRemoveChecked (photoID) {
      if (this.drugEntry.removePhotos.indexOf(photoID) < 0) {
        return false
      } else {
        return true
      }
    },

    showPhoto (storedPhoto) {
      this.$emit('timelinePhotoClickEvent', storedPhoto)
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-drug-entry-display {
  margin: 5px 0;
  padding: 5px;
  border: 1px solid #aaa;
  .title {
    font-weight: bold;
    color: #666;
    img {
      margin-right: 5px;
      margin-bottom: 3px;
      width: 15px;
      vertical-align: middle;
    }
  }
  .comment-display {
    display: flex;
    margin-top: 5px;
    .label {
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .stored-photos {
    margin-bottom: 10px;
    .label {
      margin-right: 10px;
      font-weight: bold;
    }
    .stored-photo-thumbnails {
      display: flex;
    }
  }
  .selected-photos {
    display: flex;
    .selected-photo-thumbnail-block {
      margin: 10px;
      padding: 0;
      width: 100px;
      height: 100px;
      border: 1px solid #aaa;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
