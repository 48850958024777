import axios from './AxiosInstance'

export default {
  fetchList: ({ targetState }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'stripe_notification/list',
        withCredentials: true,
        params: { state: targetState },
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetch: ({ targetID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'stripe_notification',
        withCredentials: true,
        params: { id: targetID },
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  update: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'stripe_notification',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

}