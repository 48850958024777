<template>
  <div class="horus-system-management-organization">
    <h1 class="centering-title">施設管理</h1>
    <div class="organization-list-column" v-if="!detailTargetOrganization">
      <h2>施設一覧</h2>
      <div class="head-controll">
        <div class="state-list">
          <button class="narrow-down-btn" :class="{disable: currentState === 'normal'}" @click="showNormal">施設一覧</button>
          <button class="narrow-down-btn" :class="{disable: currentState === 'revokeRequested'}" @click="showRevokeRequest">退会希望一覧</button>
          <button class="narrow-down-btn" :class="{disable: currentState === 'revoked'}" @click="showRevoked">退会済一覧</button>
        </div>
        <div class="data-controll">
          <button class="dependence-btn" v-if="systemManager" @click="editOrganizationDependence">所属管理団体一括設定</button>
          <button class="create-btn" @click="createOrganization">新規作成</button>
        </div>
      </div>
      <div class="narrow-down-tags">
        <div class="narrow-down-title">タグ絞り込み</div>
        <div class="narrow-down"
          v-for="tag in organizationTags"
          :key="'sysOrgListTag' + tag.id"
          @click="toggleNarrowDownTag(tag)"
          >
          <horus-custom-check-box
            :active="tagSelected(tag)"
            :enabled="true"
            :type="'color'"
            />
            {{ tag.name }}
        </div>
      </div><!-- narrow-down-tags -->
      <div class="display-area-blocks" v-if="systemManager">
        <div class="narrow-down-title">管理団体絞り込み</div>
        <div class="display-area-block" :class="{selected: !selectedAreaBlock}" @click="displayAreaBlock(null)">全管理団体</div>
        <div class="display-area-block" :class="{selected: selectedAreaBlock === 'none'}" @click="displayAreaBlock('none')">無所属</div>
        <div class="display-area-block"
          :class="{selected: selectedAreaBlock && selectedAreaBlock.id && areaBlock.id === selectedAreaBlock.id}"
          v-for="areaBlock in areaBlocks"
          :key="'sysOrgAB' + areaBlock.id"
          @click="displayAreaBlock(areaBlock)"
          >
          {{ areaBlock.name }}
        </div>
      </div>
      <div class="organization-count">
        {{ displayOrganizations.length }} / {{ organizations.length }}
        <button class="download-btn" @click="downloadDisplayOrgList" v-if="displayOrganizations.length > 0">表示リストをダウンロード</button>
      </div>
      <div v-if="organizationNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
      <div class="organization-list" v-if="!organizationNowLoading && !detailTargetOrganization">
        <div class="list-column"
          v-for="org in displayOrganizations"
          :key="'sysOrgList' + org.id"
          >
          <div class="list-line">
            <div class="name">{{ org.name }}[{{ org.organizationNumber }}](ID: {{ org.id }})</div>
          </div>
          <div class="list-line">
            <div class="title">{{ org.nameKana }}</div>
          </div>
          <div class="list-line">
            <div class="type">種別 : [{{ org.organization_type.name }}]</div>
            <div class="areablock">管理団体 : [{{ areaBlockName(org) }}]</div>
            <div class="duptize">設定代行 : {{ organizationDeputizable(org) }}</div>
            <div class="tag">タグ : {{ tagsOfOrganization(org) }}</div>
          </div>
          <div class="list-line">
            <div class="count">管理者数 : {{ org.manager_count }}</div>
            <div class="count">職員数 : {{ org.member_count }}</div>
            <div class="count">利用者数 : {{ org.related_user_count }}</div>
            <div class="controll"><button type="button" class="show-btn" @click="showDetail(org)">詳細</button></div>
          </div>
        </div>
      </div><!-- organization-list -->
    </div>
    <div class="organization-detail-column" v-if="detailTargetOrganization">
      <horus-system-management-organization-detail-column
        :systemManager="systemManager"
        :areaBlockOperator="areaBlockOperator"
        :sourceOrganization="detailTargetOrganization"
        :organizationTags="organizationTags"
        :areaBlocks="areaBlocks"
        v-on:closeDetailEvent="closeDetail"
        />
    </div>
    <horus-system-organization-edit-modal
      :showOrganizationEditModal="showOrganizationEditModal"
      :systemManager="systemManager"
      :areaBlockOperator="areaBlockOperator"
      :submitString="'新規作成'"
      v-on:submitCancelEvent="cencelCreateSubmit"
      v-on:submitEvent="submitCreate"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import {
  AreaBlock,
  Organization 
} from '@/models'

import {
  OrganizationTag
} from '@/api'

import HorusSystemManagementOrganizationDetailColumn from '@/components/manageSystem/organization/HorusSystemManagementOrganizationDetailColumn.vue'
import HorusSystemOrganizationEditModal from '@/components/manageSystem/organization/HorusSystemOrganizationEditModal.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusSystemManagementOrganizationView',

  components: {
    HorusSystemManagementOrganizationDetailColumn,
    HorusCustomCheckBox,
    HorusSystemOrganizationEditModal,
    HorusConfirmDialog
  },

  props: {
    systemManager: Object,
    areaBlockOperator: Object
  },

  data () {
    return {
      areaBlocks: [],
      organizationNowLoading: false,
      organizations: [],
      organizationTags: [],
      currentState: 'normal',
      selectedAreaBlock: null,
      selectedTags: [],
      displayOrganizations: [],
      showOrganizationEditModal: false,
      detailTargetOrganization: null
    }
  },

  mounted () {
    this.loadDatas()
  },

  methods: {
    async loadDatas () {
      await this.loadAreaBlocks()
      await this.loadOrganizations()
      await this.loadOrganozationTags()
    },

    async loadAreaBlocks () {
      AreaBlock.api().fetchList().then(aBlocks => {
        console.log('on component, ')
        console.log(aBlocks)
        this.areaBlocks = aBlocks
      })
    },

    async loadOrganizations () {
      this.organizationNowLoading = true
      Organization.api().listWithSummary().then(res => {
        // console.log(res.organizations)
        this.organizations = res.organizations
        this.updateDisplay()
        this.organizationNowLoading = false
      })
    },

    async loadOrganozationTags () {
      OrganizationTag.fetchList().then(res => {
        this.organizationTags = res.organization_tags
      })
    },

    updateDisplay () {
      // narrow down by type
      if (this.currentState === 'revokeRequested') {
        this.displayOrganizations = this.organizations.filter(org => org.ready_to_revoke && !org.deleted)
      } else if (this.currentState === 'revoked') {
        this.displayOrganizations = this.organizations.filter(org => org.deleted)
      } else {
        this.displayOrganizations = this.organizations.filter(org => !org.deleted)
      }
      this.displayOrganizations = this.displayOrganizations.filter(org => {
        if (this.selectedAreaBlock === null) {
          return true
        } else if (this.selectedAreaBlock === 'none') {
          if (org.area_block_id) {
            return false
          } else {
            return true
          }
        } else {
          if (org.area_block_id === this.selectedAreaBlock.id) {
            return true
          } else {
            return false
          }
        }
      })
      // narrow down by tags
      if (this.selectedTags.length > 0) {
        this.displayOrganizations = this.displayOrganizations.filter(org => {
          var exist = false
          org.tags.forEach(oTag => {
            if (this.selectedTags.some(sTag => sTag === oTag)) {
              exist = true
            }
          })
          return exist
        })
      }
    },

    displayAreaBlock (areaBlock) {
      this.selectedAreaBlock = areaBlock
      this.updateDisplay()
    },

    toggleNarrowDownTag (tag) {
      var tagIndex = this.selectedTags.indexOf(tag.id)
      if (tagIndex >= 0) {
        this.selectedTags.splice(tagIndex, 1)
      } else {
        this.selectedTags.push(tag.id)
      }
      this.updateDisplay()
    },

    tagSelected (tag) {
      if (this.selectedTags.some(sTag => sTag === tag.id)) {
        return true
      } else {
        return false
      }
    },

    showNormal () {
      this.currentState = 'normal'
      this.updateDisplay()
    },

    showRevokeRequest () {
      this.currentState = 'revokeRequested'
      this.updateDisplay()
    },

    showRevoked () {
      this.currentState = 'revoked'
      this.updateDisplay()
    },

    organizationDeputizable (org) {
      if (org.deputizable) {
        return 'あり'
      } else {
        return 'なし'
      }
    },

    areaBlockName (org) {
      if (org.area_block_id) {
        var target = this.areaBlocks.find(areablock => areablock.id === org.area_block_id)
        console.log(target)
        if (target) {
          return target.name
        }
      }
      return '所属なし'
    },

    tagsOfOrganization (org) {
      var tagnames = []
      this.organizationTags.forEach(tag => {
        if (org.tags.some(otag => otag === tag.id)) {
          tagnames.push(tag.name)
        }
      })
      return tagnames.join(', ')
    },

    showDetail (org) {
      this.detailTargetOrganization = org
    },

    closeDetail () {
      this.loadOrganizations()
      this.detailTargetOrganization = null
    },

    goHome () {
      this.$router.push({ path: '/system_management/home' })
    },

    createOrganization () {
      this.showOrganizationEditModal = true
    },

    cencelCreateSubmit (callback) {
      this.showOrganizationEditModal = false
      callback()
    },

    submitCreate (formData, callback) {
      Organization.api().create({ formData: formData }).then(() => {
        this.showOrganizationEditModal = false
        callback()
        this.loadOrganizations()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('新規作成失敗', '施設の新規作成に失敗しました。')
      })
    },

    editOrganizationDependence () {
      this.$router.push({ path: '/system_management/organization_area_block_relation' })
    },

    downloadDisplayOrgList () {
      const json = JSON.stringify(this.displayOrganizations)
      const blob = new Blob([json], { type: 'application/json' })
      const blobURL = window.URL.createObjectURL(blob)
      let dammyA = document.createElement('a')
      dammyA.href = blobURL
      dammyA.download = 'organization_list.json'
      document.body.appendChild(dammyA)
      dammyA.click()
      dammyA.parentNode.removeChild(dammyA)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-system-management-organization {
  padding: 10px 50px;
  height: calc(100% - 20px);
  overflow: auto;
  .head-controll {
    display: flex;
    justify-content: space-between;
    .state-list {
      display: flex;
      .narrow-down-btn {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #37a34a;
        border-radius: 3px;
        background-color: #37a34a;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        &.disable {
          background-color: #fff;
          color: #37a34a;
        }
      }
    }
    .data-controll {
      button {
        margin: 0 10px;
        padding: 3px 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .narrow-down-title {
    margin-right: 10px;
    font-weight: bold;
  }
  .display-area-blocks {
    display: flex;
    margin: 10px 0;
    .display-area-block {
      margin: 0 10px;
      padding: 3px 5px;
      background-color: #666;
      color: #fff;
      border-radius: 3px;
      border: 1px solid #666;
      font-weight: bold;
      &.selected {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .narrow-down-tags {
    display: flex;
    margin: 10px 0;
    .narrow-down {
      display: flex;
      margin-right: 20px;
    }
  }
  .now-loading {
    display: flex;
    justify-content: center;
    padding: 50px;
    border: 1px solid #aaa;
    background-color: #fff;
  }
  .organization-list {
    padding: 10px;
    border: 1px solid #aaa;
    background-color: #fff;
    .list-column {
      margin-bottom: 10px;
      border-bottom: 1px solid #aaa;
      .list-line {
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 5px;
          &.name {
            font-weight: bold;
          }
          &.title {
            width: auto;
          }
          &.type {
            width: 160px;
          }
          &.duptilze {
            width: 100px;
          }
          &.tag {
            width: 100px;
          }
          &.count {
            width: 100px;
          }
          &.controll {
            width: 50px;
            button {
              border: 1px solid #039;
              border-radius: 3px;
              background-color: #039;
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
