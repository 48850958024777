<template>
  <div class="horus-configure-staff-visibility-modal">
    <horus-modal-frame
      :showModalFlag="showModal">
      <div class="modal-header">
        <h1 v-if="user">{{ user.author.name }}さんの担当職員設定</h1>
      </div>
      <div class="modal-body" v-if="organization && user && visibilities">
        <div class="member-list" v-for="member in organization.members" :key="'staffvisiblemember' + member.id" @click="toggleVisible(member)">
          <horus-custom-check-box
            :active="staffVisible(member)"
            :enabled="true"
            :type="'color'"
            />
          <div class="member-info">
            <img :src="member.portrateSrc" class="member-portrate" />
            <div>{{ member.name }}</div>
          </div>
        </div>
        <div class="controll"><button class="close-modal-btn" @click="closeModal">閉じる</button></div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusConfigureStaffVisibilityModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox
  },

  props: {
    showModal: Boolean,
    user: Object,
    organization: Object,
    visibilities: Array
  },

  data () {
    return {
      visibilitySet: []
    }
  },

  watch: {
    user: { handler: 'initVisibilitySet', immediate: true }
  },

  // computed: {
  //   targetVisibilitySet () {
  //     var visibilitySet = this.organization.chargedMembers(this.user.user_id)
  //     console.log('targetVisibilitySet::' + visibilitySet)
  //     if (visibilitySet) {
  //       return visibilitySet
  //     } else {
  //       return []
  //     }
  //     // return this.organization.chargedMembers(this.user.user_id)
  //     // return this.organization.usersChargedMembers.find(userSet => userSet.userID === this.user.user_id).visibilities
  //   }
  // },

  methods: {
    initVisibilitySet () {
      if (this.user) {
        this.visibilitySet = this.organization.chargedMembers(this.user.user_id)
        if (!this.visibilitySet) {
          // init visibility
          this.$emit('initVisibilityForUserEvent', this.user)
        }
      }
    },

    staffVisible (member) {
      // console.log(this.targetVisibilitySet)
      // return this.visibilitySet.find(visibility => visibility.memberID === member.id)
      let visilbility = this.visibilitySet.find(visibility => visibility.memberID === member.id)
      if (visilbility && visilbility.visible) {
        return true
      }
      return false
    },

    toggleVisible (member) {
      var visibility = this.staffVisible(member)
      if (visibility.visible) {
        this.$emit('toInvisibleStaffEvent', member, this.user)
      } else {
        this.$emit('toVisibleStaffEvent', member, this.user)
      }
      visibility.visible = !visibility.visible
    },

    closeModal () {
      this.$emit('closeModalEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-configure-staff-visibility-modal {
  .modal-body {
    padding: 10px;
    .member-list {
      display: flex;
      align-items: center;
      margin: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      .member-info {
        display: flex;
        align-items: center;
        margin: 0 10px;
        font-weight: bold;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }
    .controll {
      text-align: right;
      .close-modal-btn {
        padding: 3px 10px;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
</style>
