<template>
  <div class="horus-system-management-signin-view container">
    <div class="navbar" :class="signinType">
      <div class="main-bar">
        <img src="/static/images/narrative_icon_w.png" class="navi-logo-icon" @click="backhome">
        <button class="signout-btn" @click="signout">サインアウト</button>
      </div>
    </div>
    <div class="content" :class="contentClass">
      <div class="main-menu">
        <horus-system-management-menu
          :systemManager="systemManager"
          :areaBlockOperator="areaBlockOperator"
          :menuOpen="menuOpen"
          v-on:toggleMenuEvent="toggleMenu"
          v-on:pageJumpEvent="pageJump"
        />
      </div>
      <div class="main-content">
        <router-view
          :systemManager="systemManager"
          :areaBlockOperator="areaBlockOperator"
          />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SystemManager,
  AreaBlockOperator
} from '@/models'

import HorusSystemManagementMenu from '@/components/manageSystem/HorusSystemManagementMenu.vue'

export default {
  name: 'HorusSystemManagementSignInView',

  components: {
    HorusSystemManagementMenu
  },

  data () {
    return {
      systemManager: null,
      areaBlockOperator: null,
      menuOpen: true
    }
  },

  created () {
    this.prepareSignin()
  },

  computed: {
    contentClass () {
      if (this.menuOpen) {
        return 'menu-open'
      } else {
        return 'menu-close'
      }
    },

    signinType () {
      if (this.systemManager) {
        return 'system-manager'
      } else if (this.areaBlockOperator) {
        return 'area-block-operator'
      } else {
        return ''
      }
    }
  },

  methods: {
    prepareSignin () {
      this.systemManager = SystemManager.query().first()
      this.areaBlockOperator = AreaBlockOperator.query().first()
    },

    backhome () {
      if (this.areaBlockOperator) {
        this.$router.push({ path: '/system_management/operator_home' })
      } else {
        this.$router.push({ path: '/system_management/home' })
      }
    },

    signout () {
      this.$router.push({ path: '/signout' })
    },

    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },

    pageJump (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style lang="scss" scoped>
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #999;
}

#app {
  height: 100%;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, “Droid Sans”, “游ゴシック”, YuGothic, “ヒラギノ角ゴ ProN W3”, “Hiragino Kaku Gothic ProN”, “メイリオ”, Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}

.container {
  height: 100%;
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
}

.horus-system-management-signin-view {
  .navbar {
    .main-bar {
      background-color: #039;
      background-image: url("/static/images/system_header.png");
      .signout-btn {
        border: 1px solid #bbc8e6;
        border-radius: 3px;
        background-color: #bbc8e6;
        color: #039;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &.area-block-operator {
      .main-bar {
        background-color: #066292;
        background-image: url("/static/images/operator_header.png");
        .signout-btn {
          border: 1px solid #cde9ef;
          background-color: #cde9ef;
          color: #066292;
        }
      }
    }
  }
  .content {
    display: flex;
    height: calc(100% - 50px);
    background-color: #f5f5f5;
    .main-menu {
      border-right: 1px solid #aaa;
      height: 100%;
      overflow: auto;
    }
    &.menu-open {
      .main-menu {
        width: 250px;
      }
      .main-content {
        width: calc(100% - 250px);
      }
    }
    &.menu-close {
      .main-menu {
        width: 50px;
      }
      .main-content {
        width: calc(100% - 50px);
      }
    }
    .main-content {
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
