import axios from './AxiosInstance'

// {
//   "system_version_name":"3.001",
//   "system_build_name":"20230415001",
//   "system_name":"Narrative Book System(staging AWS)",
//   "system_name_jp":"ナラティブブック(staging:AWS)",
//   "system_hostname":"sub2023.narrativebook.jp",
//   "vue_host_name":"https://sub2023.narrativebook.jp",
//   "system_env_name":"[AWS STAGING]"
// }
export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'version_info',
        withCredentials: true
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },
}
