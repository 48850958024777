import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  data () {
    return {
      confirmDialogVisible: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogType: DIALOG_TYPE.CONFIRM,
      confirmNegativeCallback: () => {},
      confirmPositiveCallback: () => {}
    }
  },

  methods: {
    showConfirmDialog (title, message, type, positiveCallback, negativeCallback = () => {}) {
      this.confirmDialogTitle = title
      this.confirmDialogMessage = message
      this.confirmDialogType = type
      this.confirmPositiveCallback = positiveCallback
      this.confirmNegativeCallback = negativeCallback
      this.confirmDialogVisible = true
    },

    confirmFinishNegative () {
      try {
        this.confirmNegativeCallback()
      } catch (err) {
        console.error(err)
      }
      this.resetConfirmDialog()
      this.confirmDialogVisible = false
    },

    confirmFinishPositive () {
      try {
        this.confirmPositiveCallback()
      } catch (err) {
        console.error(err)
      }
      this.resetConfirmDialog()
      this.confirmDialogVisible = false
    },

    resetConfirmDialog () {
      this.confirmDialogTitle = ''
      this.confirmDialogMessage = ''
      this.confirmDialogType = DIALOG_TYPE.CONFIRM
      this.confirmNegativeCallback = () => {}
      this.confirmPositiveCallback = () => {}
    },

    showNotifyDialog (title, message) {
      console.log('show notify dialog call, title: ' + title + ' message: ' + message)
      this.confirmDialogTitle = title
      this.confirmDialogMessage = message
      this.confirmDialogType = DIALOG_TYPE.NOTIFY
      this.confirmDialogVisible = true
    }
  }
}
