<template>
  <div class="horus-discount-unit-detail-view">
    <h1 class="centering-title">割引設定詳細</h1><button class="back-btn" @click="backToList">一覧に戻る</button>
    <div class="discount-unit" v-if="discountUnit">
      <horus-discount-unit-column
        :discountUnit="discountUnit"
        :editable="true"
        v-on:editEvent="editDiscountUnit"
        v-on:lockEvent="lockDiscountUnit"
        v-on:depuricateEvent="depuricateDiscountUnit"
        v-on:destroyEvent="destroyDiscountUnit"
        />
    </div>
    <div class="applicate-organizations" v-if="discountUnit && discountUnit.state === 1">
      <h2>割引対象施設</h2>
      <div class="controll">
        <button class="applicate-btn" @click="selectApplicateOrganizations">割当施設選択</button>
        <button class="end-applicate-btn" @click="selectEndApplicateOrganizations">割当解除選択</button>
      </div>
      <div class="loading" v-show="organizationLoading">loading....</div>
      <div class="organizations" v-show="!organizationLoading">
        <div class="organization-list"
          v-for="applicated in applicatedOrganizations"
          :key="'apporg' + applicated.id"
          >
          <div class="line-item">
            <div class="org-name">{{ applicated.organization.name }}</div>
            <div class="number"><span class="title">施設番号: </span>{{ organizationNumberForHuman(applicated.organization) }}</div>
          </div>
          <div class="line-item">
            <div class="term">{{ formatedDate(applicated.applicate_start) }} ~ {{ formatedDate(applicated.applicate_end) }}</div>
            <div class="controll"><button class='delete-btn' @click="endApplicate(applicated)">適用解除</button></div>
          </div>
        </div>
      </div>
    </div>
    <horus-discount-unit-form-modal
      :discountUnit="discountUnit"
      :modalDisplay="discountUnitFormDisplay"
      v-on:cancelEvent="closeDiscountForm"
      v-on:submitEvent="updateDiscountUnit"
      />
    <horus-select-applicate-organization-modal
      :title="'割引先割当選択'"
      :applicatedOrganizations="applicatedOrganizations"
      :modalDisplay="selectApplicateOrganizationDisplay"
      v-on:cancelEvent="closeSelectApplicateOrganizationModal"
      v-on:submitEvent="applicateOrganizations"
      />
    <horus-select-end-application-organization-modal
      :title="'割引先割当停止'"
      :applicatedOrganizations="applicatedOrganizations"
      :modal-display="selectEndApplicateOrganizationDisplay"
      v-on:cancelEvent="closeSelectEndApplicationOrganizationModal"
      v-on:submitEvent="endApplicateOrganizations"
      />
    <horus-loading-dialog
      :showDialog="loadingDialogVisible"
      :title="loadingDialogTitle"
      :message="loadingDialogMessage"
      :loadMessage="loadingDialogLoadingMessage"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import { BillingDiscount } from '@/api'

import HorusDiscountUnitColumn from '@/components/manageSystem/billingSetting/HorusDiscountUnitColumn.vue'
import HorusDiscountUnitFormModal from '@/components/manageSystem/billingSetting/HorusDiscountUnitFormModal.vue'
import HorusSelectApplicateOrganizationModal from '@/components/manageSystem/billingSetting/HorusSelectApplicateOrganizationModal.vue'
import HorusSelectEndApplicationOrganizationModal from '@/components/manageSystem/billingSetting/HorusSelectEndApplicationOrganizationModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusLoadingDialog from '@/components/common/HorusLoadingDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusLoadingDialogControll from '@/mixin/HorusLoadingDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusLoadingDialogControll],
  name: 'HorusDiscountUnitDetailView',

  components: {
    HorusDiscountUnitColumn,
    HorusDiscountUnitFormModal,
    HorusSelectApplicateOrganizationModal,
    HorusSelectEndApplicationOrganizationModal,
    HorusConfirmDialog,
    HorusLoadingDialog,
  },

  data () {
    return {
      discountUnit: null,
      organizationLoading: false,
      applicatedOrganizations: [],
      discountUnitFormDisplay: false,
      selectApplicateOrganizationDisplay: false,
      selectEndApplicateOrganizationDisplay: false,
    }
  },

  watch: {
    '$route': { handler: 'loadDiscountUnit', immediate: true },
  },

  methods: {
    formatedDate (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    organizationNumberForHuman (org) {
      return org.organizationNumber.substr(0, 4) + '-' + org.organizationNumber.substr(4, 4) + '-' + org.organizationNumber.substr(8, 4) + '-' + org.organizationNumber.substr(12, 4)
    },

    loadDiscountUnit () {
      BillingDiscount.fetch({ targetID: this.$route.params.id }).then(res => {
        console.log(res)
        this.discountUnit = res.organization_billing_discount_unit
        this.loadApplicatedOrganizations()
      })
    },

    loadApplicatedOrganizations () {
      if (this.discountUnit) {
        this.organizationLoading = true
        BillingDiscount.fetchApplicatedOrganizations({ targetID: this.discountUnit.id, condition: '' }).then(res => {
          console.log(res)
          this.applicatedOrganizations = res.applicated_organization_units
          this.organizationLoading = false
        }).catch(error => {
          console.error(error)
          this.organizationLoading = false
        })  
      }  
    },  

    backToList () {
      this.$router.push({ path: '/system_management/billing_setting' })
    },

    editDiscountUnit (discountUnit) {
      if (discountUnit.state === 0) {
        this.discountUnitFormDisplay = true
      } else {
        this.showNotifyDialog('割引設定の編集不可', '編集可能状態にない割引設定は編集できません。')
      }
    },

    closeDiscountForm () {
      this.discountUnitFormDisplay = false
      this.targetDiscountUnit = null
    },

    updateDiscountUnit (formData) {
      BillingDiscount.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadDiscountUnit()
        this.discountUnitFormDisplay = false
        this.showNotifyDialog('割引設定の更新', '割引設定を更新しました。')
      }).catch(error => {
        console.error(error)
        this.discountUnitFormDisplay = false
        this.showNotifyDialog('割引設定の更新失敗', '割引設定の更新に失敗しました。')
      })
    },

    lockDiscountUnit (discountUnit) {
      if (discountUnit.state !== 0) {
        this.showNotifyDialog('割引設定の確定不可', '編集可能以外の状態にある割引設定は確定できません。')
      } else {
        this.showConfirmDialog(
          '割引設定の確定',
          '割引設定の確定します。確定以後、割引設定を割り当てる施設を設定できるようになります。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeLockDiscountUnit(discountUnit) }
        )
      }
    },

    executeLockDiscountUnit (discountUnit) {
      BillingDiscount.lock({ targetID: discountUnit.id }).then(res => {
        console.log(res)
        this.loadDiscountUnit()
        this.showNotifyDialog('割引設定の確定', '割引設定を確定しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('割引設定の確定失敗', '割引設定の確定に失敗しました。')
      })
    },

    depuricateDiscountUnit (discountUnit) {
      if (discountUnit.state !== 1) {
        this.showNotifyDialog('割引設定の停止不可', 'ロック以外の状態にある割引設定は確定できません。')
      } else {
        this.showConfirmDialog(
          '割引設定の停止',
          '割引設定の停止します。停止以後、割引設定は適用されません。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeDepuricateDiscountUnit(discountUnit) }
        )
      }
    },

    executeDepuricateDiscountUnit (discountUnit) {
      BillingDiscount.depuricate({ targetID: discountUnit.id }).then(res => {
        console.log(res)
        this.loadDiscountUnit()
        this.showNotifyDialog('割引設定の停止', '割引設定を停止しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('割引設定の停止失敗', '割引設定の停止に失敗しました。')
      })
    },

    destroyDiscountUnit (discountUnit) {
      if (discountUnit.state !== 0) {
        this.showNotifyDialog('割引設定の削除不可', '編集可能以外の状態にある割引設定は削除できません。')
      } else {
        this.showConfirmDialog(
          '割引設定の削除',
          '割引設定の削除します。この操作は戻せません。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeDestroyDiscountUnit(discountUnit) }
        )
      }
    },

    executeDestroyDiscountUnit (discountUnit) {
      BillingDiscount.destroy({ targetID: discountUnit.id }).then(res => {
        console.log(res)
        this.showNotifyDialog('割引設定の削除', '割引設定を削除しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('割引設定の削除失敗', '割引設定の削除に失敗しました。')
      })
    },
    selectApplicateOrganizations () {
      if (this.discountUnit.state === 1) {
        this.selectApplicateOrganizationDisplay = true
      } else {
        this.showNotifyDialog('割引先割当不可', 'ロック状態にない割引設定は施設を割り当てできません。')
      }
    },

    closeSelectApplicateOrganizationModal () {
      this.selectApplicateOrganizationDisplay = false
    },

    applicateOrganizations (orgList) {
      let formData = new FormData()
      formData.append('id', this.discountUnit.id)
      for(const orgID of orgList) {
        formData.append('organizations[]', orgID)
      }
      this.showLoadingDialog('Stripeへ送信中', 'Stripeに施設と割引設定を送信しています。', '送信中...')
      BillingDiscount.applicateOrganizations({ formData: formData }).then(res => {
        console.log(res)
        this.selectApplicateOrganizationDisplay = false
        let message = '割引先施設を割り当てました。\n割当件数: '
        message += res.applicated_organizations.length
        message += '\n失敗件数: ' + res.failed_organizations.length
        message += '\n設定に失敗した施設\n'
        for (const org of res.failed_organizations) {
          message += org.name + '\n'
        }
        this.closeLoadingDialog()
        this.loadApplicatedOrganizations()
        this.showNotifyDialog(
          '割引先割当', 
          message
        )
      }).catch(error => {
        console.error(error)
        this.closeLoadingDialog()
        this.selectApplicateOrganizationDisplay = false
      })
    },

    selectEndApplicateOrganizations () {
      if (this.discountUnit.state === 1) {
        this.selectEndApplicateOrganizationDisplay = true
      } else {
        this.showNotifyDialog('割引先割当不可', 'ロック状態にない割b期設定は施設を割り当てできません。')
      }
    },

    closeSelectEndApplicationOrganizationModal () {
      this.selectEndApplicateOrganizationDisplay = false
    },

    endApplicateOrganizations (orgList) {
      let formData = new FormData()
      formData.append('id', this.discountUnit.id)
      for(const orgID of orgList) {
        formData.append('organizations[]', orgID)
      }
      this.showLoadingDialog('Stripeへ送信中', 'Stripeに施設と課金設定を送信しています。', '送信中...')
      BillingDiscount.endApplicateOrganizations({ formData: formData }).then(res => {
        console.log(res)
        this.selectEndApplicateOrganizationDisplay = false
        let message = '割引先施設を割り当てを停止しました。\n割当停止件数: '
        message += res.end_applicate_organizations.length
        message += '\n停止失敗件数: ' + res.failed_organizations.length
        message += '\n停止に失敗した施設\n'
        for (const org of res.failed_organizations) {
          console.log(org)
          message += org.name + '\n'
        }
        this.closeLoadingDialog()
        this.loadApplicatedOrganizations()
        this.showNotifyDialog(
          '割引先割当停止', 
          message
        )
      }).catch(error => {
        console.error(error)
        this.selectEndApplicateOrganizationDisplay = false
      })
    },

    endApplicate (applicatedOrganization) {
      if (this.discountUnit.state === 1) {
        let formData = new FormData()
        formData.append('id', this.discountUnit.id)
        formData.append('organizations[]', applicatedOrganization.organization.id)
        BillingDiscount.endApplicateOrganizations({ formData: formData }).then(res => {
          console.log(res)
          this.loadApplicatedOrganizations()
        })
      } else {
        this.showNotifyDialog('割引先割当不可', 'ロック状態にない割引設定は施設を割り当てできません。')
      }
    },

  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-discount-unit-detail-view {
  padding: 10px 20px;
  .back-btn {
    width: 100px;
    margin-left: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #666;
    color: #fff;
  }
  .discount-unit {
    margin: 10px 0;
  }
  .applicate-organizations {
    .controll {
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
      }
    }
    .organizations {
      .organization-list {
        border-bottom: 1px solid #ddd;
        padding: 5px 0;
        .line-item {
          display: flex;
          padding: 5px 0;
          div {
            margin-right: 10px;
            .title {
              font-weight: bold;
              font-size: 0.8rem;
            }
          }
          .org-name {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>