import { Model } from '@vuex-orm/core'
import axiosHelper from '@/api/AxiosInstance'
import moment from 'moment'
import TimelineOwner from './TimelineOwner'
import Organization from './Organization'
import Author from './Author'
import HashTag from './HashTag'
import UserTaskPhoto from './UserTaskPhoto'
import UserTaskFile from './UserTaskFile'
// import ZoomMeeting from './ZoomMeeting'

export default class UserTask extends Model {
  static entity = 'userTasks'

  static fields () {
    return {
      id: this.attr(null),
      reader_id: this.attr(null),
      user_id: this.attr(null),
      organization_id: this.attr(null),
      target_at: this.string(''),
      author_id: this.string(''),
      textContent: this.string(''),
      linkURL: this.string(''),
      recordable: this.boolean(false),
      meetingType: this.number(0),
      deleted: this.boolean(false),
      tags: this.attr(null),
      notify_targets: this.attr(null)
    }
  }

  get timelineOwner () {
    // TODO: load data when undefined
    if (this.user_id) {
      return TimelineOwner.find(this.user_id)
    } else {
      return null
    }
  }

  get timelineAuthor () {
    // TODO: load data when undefined
    if (this.user_id) {
      return Author.find('usr' + this.user_id)
    } else {
      return null
    }
  }

  get organization () {
    // TODO: load data when undefined
    if (this.organization_id) {
      return Organization.find(this.organization_id)
    } else {
      return null
    }
  }

  get author () {
    // TODO: load data when undefined
    return Author.find(this.author_id)
  }

  get targetAt () {
    if (!this.targetAt_) {
      this.targetAt_ = new Date(this.target_at)
    }
    return this.targetAt_
  }

  get targetAtStr () {
    return moment(this.targetAt).format('YYYY/MM/DD HH:mm')
  }

  get targetAtJp () {
    return moment(this.targetAt).format('YYYY年MM月DD日 HH:mm')
  }

  get hashTags () {
    return HashTag.findIn(this.tags)
  }

  get userTaskPhotos () {
    return UserTaskPhoto.query().where('user_task_id', this.id).get()
  }

  get userTaskFiles () {
    return UserTaskFile.query().where('user_task_id', this.id).get()
  }

  // get zoomMeeting () {
  //   return ZoomMeeting.query().where('user_task_id', this.id).first()
  // }

  get notifyTargets () {
    return Author.findIn(this.notify_targets)
  }

  static state () {
    return {
      fetchedAt: null
    }
  }

  static async updateData(data) {
    console.log(data)
    // cleanup old records
    for(var task of data.user_tasks) {
      var photos = await UserTaskPhoto.query().where('user_task_id', task.id).get()
      for(var photo of photos) {
        await photo.$delete()
      }
      var files = await UserTaskFile.query().where('user_task_id', task.id).get()
      for(var file of files) {
        await file.$delete()
      }
    }
    // save fetched records
    for(let dtask of data.deleted_user_tasks) {
      var dphotos = await UserTaskPhoto.query().where('user_task_id', dtask.id).get()
      for(var dphoto of dphotos) {
        await dphoto.$delete()
      }
      var dfiles = await UserTaskFile.query().where('user_task_id', dtask.id).get()
      for(var dfile of dfiles) {
        await dfile.$delete()
      }
      let storedDTask = UserTask.find(dtask.id)
      if (storedDTask) {
        await storedDTask.$delete()
      }
    }
    if (data.user_tasks.length) {
      let promises = [UserTask.insertOrUpdate({ data: data.user_tasks })]
      if (data.task_photos.length) {
        promises.push(UserTaskPhoto.insertOrUpdate({ data: data.task_photos }))
      }
      if (data.task_files.length) {
        promises.push(UserTaskFile.insertOrUpdate({ data: data.task_files }))
      }
      return Promise.all(promises).then(async ([taskEntities]) => {
        // TODO:load timelineOwner, author, organization
        if (taskEntities && taskEntities.userTasks) {
          var loadPromises = []
          var loadAuthorID = []
          var loadOrganizationID = []
          for(var task of taskEntities.userTasks) {
            if (task.user_id) {
              // to load timelineOwner and timelineAuthor
              var ownerAuthorID = 'usr' + task.user_id
              if (!Author.find(ownerAuthorID)) {
                if (loadAuthorID.indexOf(ownerAuthorID) < 0) loadAuthorID.push(ownerAuthorID)
              }
              if (!TimelineOwner.find(task.user_id)) {
                loadPromises.push(TimelineOwner.api().fetch({ timelineOwnerID: task.user_id }))
              }
            }
            if (task.organization_id) {
              if (!Organization.find(task.organization_id)) {
                if (loadOrganizationID.indexOf(task.organization_id) < 0) loadOrganizationID.push(task.organization_id)
              }
            }
            if (!task.author) {
              if(loadAuthorID.indexOf(task.author_id) < 0) loadAuthorID.push(task.author_id)
            }
          }
          if (loadAuthorID.length > 0) {
            loadPromises.push(Author.api().fetchByID({ idList: loadAuthorID }))
          }
          if (loadOrganizationID.length > 0) {
            loadPromises.push(Organization.api().fetchByID({ idList: loadOrganizationID }))
          }
          if (loadPromises.length > 0) {
            await Promise.all(loadPromises)
          }
          UserTask.commit((state) => {
            state.fetchedAt = new Date()
          })
          return taskEntities.userTasks
        }
      })
    } else {
      return []
    }
  }

  static apiConfig = {
    actions: {
      fetchList () {
        return this.request({
          method: 'get',
          url: 'user_task/list',
          withCredentials: true,
          save: false
        })
        .then(res => {
          return UserTask.updateData(res.response.data)
        })
        .catch(error => { throw error })
      },
    
      fetchUpdated ({ lastCheckAt }) {
        // console.log(lastCheckAt)
        if (!lastCheckAt) {
          lastCheckAt = new Date()
        }
        return this.request({
          method: 'get',
          url: 'user_task/updated_list',
          withCredentials: true,
          params: { last_check_at: lastCheckAt },
          save: false
        })
        .then(res => {
          return UserTask.updateData(res.response.data)
        })
        .catch(error => { throw error })
      },
    
      async create ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('POST')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'post',
            url: 'user_task',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async update ({ formData }) {
        var csrfHeader = await axiosHelper.csrfHeader('PUT')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'put',
            url: 'user_task',
            data: formData,
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      },
    
      async destroy ({ targetID }) {
        var csrfHeader = await axiosHelper.csrfHeader('DELETE')
        return this.request({
          ...csrfHeader,
          ...{
            method: 'delete',
            url: 'user_task',
            params: { user_task_id: targetID },
            save: false
          }
        })
        .then(res => {
          return res.response.data
        })
      }
    }
  }
}
