<template>
  <div class="horus-simulate-setting-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <div class="header-title-controll">
          <h1>請求シミュレーション</h1>
          <div class="close-btn">
            <img src="/static/images/x_icon.png" @click="closeModal" />
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="setting">
          <h2>条件設定</h2>
          <div class="simulate-settings">
            <div class="number-setting">
              <div class="condition">
                <label>職員数: </label><input type="number" v-model="memberCount"/>
              </div>
              <div class="condition">
                <label>かかりつけ利用者数: </label><input type="number" v-model="userCount"/>
              </div>
            </div>
            <div class="using-conditions">
              <div class="condition" 
                v-for="condition in applicableConditions"
                :key="'sim' + condition.id"
                @click="toggleApplicableCondition(condition)"
                >
                <horus-custom-check-box
                  :active="conditionSeleted(condition)"
                  :enabled="true"
                  :type="'color'"
                  />
                <span class="condition-name">{{ condition.name }}</span>
              </div>
              <div class="condition">
                <label>ナラティブフォン数: <input type="number" v-model="narrativePhoneCount"/></label>
              </div>
            </div>
          </div>
          <div class="controll">
            <button class="simulate-btn" @click="simulate">シミュレーション実行</button>
          </div>
        </div>
        <div class="simulation-result">
          <h2>シミュレーション結果</h2>
          <div class="simulate-conditions">
            {{ conditionText }}
          </div>
          <div class="result" v-html="resultHtml"></div>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { BillingSetting, BillingDiscount } from '@/api'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusSimulateSettingModal',

  components: {
    HorusModalFrame,
    HorusCustomCheckBox,
  },

  props: {
    modalDisplay: Boolean,
    calcurationSet: Object,
  },

  emits: [
    'closeEvent',
  ],

  data () {
    return {
      discountUnits: [],
      applicableConditions: [],
      narrativePhoneCount: 0,
      memberCount: 0,
      userCount: 0,
      selectedConditions: [],
      simulateResult: null,
    }
  },

  mounted() {
    this.loadDiscountUnits()
    BillingSetting.unitApplicableConditions().then(res => {
      console.log(res)
      this.applicableConditions = res.applicable_conditions
    })
  },

  watch: {
    modalDisplay: { handler: 'initSetting', immediate: true },
  },

  computed: {
    conditionText () {
      let text = ''
      text += '職員数: ' + this.memberCount
      text += ',  かかりつけ利用者数: ' + this.userCount + ', '
      for(let con of this.applicableConditions) {
        if (this.conditionSeleted(con)) {
          text += con.name + ', '
        }
      }
      text += 'ナラティブフォン利用数: ' + this.narrativePhoneCount
      return text
    },

    resultHtml () {
      let html = ''
      if (this.simulateResult) {
        html += '<div class="total-price">合計: ' + Math.floor(this.simulateResult.billing_price).toLocaleString() + '円</div>'
        html += '<table class="simulate-description">'
          html += '<tr><th>名目</th><th>数量</th><th>単価</th><th>計</th></tr>'
        for(let unit of this.simulateResult.organization_billing_units) {
          html += '<tr>'
          html += '<td>' + unit.organization_billing_calcuration_unit.name + '</td>'
          html += '<td class="amount">' + unit.amount + '</td>'
          html += '<td class="unit">' + Math.floor(unit.unit_price).toLocaleString() + '</td>'
          html += '<td class="price">' + Math.floor(unit.subtotal).toLocaleString() + '</td>'
          html += '</tr>'
        }
        if (this.simulateResult.organization_billing_discount_unit) {
          html += '<tr>'
          html += '<td>' + this.simulateResult.organization_billing_discount_unit.name + '</td>'
          html += '<td class="amount"></td>'
          html += '<td class="unit"></td>'
          html += '<td class="price">-' + Math.floor(this.simulateResult.discount_price).toLocaleString() + '</td>'
          html += '</tr>'
        }
        html += '<tr><td>小計</td><td class="amount"></td><td class="unit"></td><td class="price">' + Math.floor(this.simulateResult.total_price).toLocaleString() + '</td></tr>'
        html += '<tr><td>税</td><td class="amount"></td><td class="unit"></td><td class="price">' + Math.floor(this.simulateResult.tax_price).toLocaleString() + '</td></tr>'
        html += '<tr><td>合計</td><td class="amount"></td><td class="unit"></td><td class="price">' + Math.floor(this.simulateResult.billing_price).toLocaleString() + '</td></tr>'
        html += '</table><!-- description -->'
      }
      return html
    },
  },

  methods: {
    loadDiscountUnits () {
      BillingDiscount.fetchList().then(res => {
        console.log(res)
        this.discountUnits = res.organization_billing_discount_units
      })
    },

    initSetting () {
      this.memberCount = 0
      this.userCount = 0
      this.narrativePhoneCount = 0
      this.selectedConditions = []
    },

    toggleApplicableCondition (condition) {
      const condIndex = this.selectedConditions.indexOf(condition.id)
      if (condIndex < 0) {
        this.selectedConditions.push(condition.id)
      } else {
        this.selectedConditions.splice(condIndex, 1)
      }
    },

    conditionSeleted (condition) {
      return this.selectedConditions.some(con => con == condition.id)
    },

    simulate () {
      let params = { 
        id: this.calcurationSet.id,
        simulate_setting: {
          member_count: this.memberCount,
          user_count: this.userCount,
          organization_additional_functions: [],
        }
      }
      for(let condition of this.applicableConditions) {
        if (this.conditionSeleted(condition)) {
          if (condition.id === 110) {
            params.simulate_setting.initial = true
          } else if (condition.id === 115) {
            params.simulate_setting.deputizable = true
          } else if (condition.id === 120) {
            params.simulate_setting.narrative_phone_count = this.narrativePhoneCount
          } else if (condition.id === 130) {
            params.simulate_setting.narrative_reception = true
          } else {
            params.simulate_setting.organization_additional_functions.push(condition.id)
          }
        }
      }
      BillingSetting.simulate({ params: params }).then(res => {
        console.log(res)
        this.simulateResult = res.simulate_result
      })
    },

    closeModal () {
      this.$emit('closeEvent')
    },

  }
}
</script>

<style lang="scss">
// @import '@/assets/css/systemManagement.scss';

.horus-simulate-setting-modal {
  .modal-header {
    .header-title-controll {
      display: flex;
      h1 {
        width: 100%;
      }
      .close-btn {
        cursor: pointer;
      }
    }
  }
  .modal-body {
    padding: 10px 20px;
    .setting {
      .simulate-settings {
        .number-setting {
          display: flex;
          .condition {
            margin-right: 10px;
            label {
              font-size: 0.8rem;
              font-weight: bold;
            }
            input[type=number] {
              width: 50px;
            }
          }
        }
        .using-conditions {
          display: flex;
          flex-wrap: wrap;
          .condition { 
            display:flex;
            align-items: center;
            margin-right: 10px;
            padding: 5px 0;
            input[type=number] {
              width: 50px;
            }
          }
        }
      }
      .controll {
        padding: 10px;
        button {
          margin-left: 10px;
          border: 1px solid #999;
          border-radius: 3px;
          font-size: 14px;
          font-weight: bold;
          background-color: #666;
          color: #fff;
        }
      }
    }
    .simulation-result {
      .simulate-conditions {
        padding: 10px;
        border: 1px solid #ddd;
      }
      .result {
        .total-price {
          margin: 10px;
          padding: 10px;
          border-bottom: 1px solid #666;
          font-size: 1.1rem;
        }
        table.simulate-description {
          border: 1px solid #aaa;
          width: 100%;
          tr {
            th {
              padding: 3px;
              border-bottom: 1px solid #aaa;
            }
            td {
              border-bottom: 1px solid #ddd;
              padding: 3px;
              &.amount {
                text-align: right;
              }
              &.unit {
                text-align: right;
              }
              &.price {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
</style>