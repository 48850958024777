<template>
  <div class="horus-billing-setting-view">
    <h1 class="centering-title">施設請求根拠</h1>
    <h2>請求設定一覧<button class="create-btn" @click="newCalcurationSet">新規作成</button></h2>
    <div class="calcuration-set-list">
      <horus-calcuration-set-column
        v-for="calcurationSet in calcurationSets"
        :key="'calcset' + calcurationSet.id"
        :calcurationSet="calcurationSet"
        :jumpDetail="true"
        :editable="false"
        v-on:showEvent="displayCalcurationSetDetail"
        />
    </div><!-- calcuration-set-list -->
    <h2>割引設定一覧<button class="create-btn" @click="newDiscountUnit">新規作成</button></h2>
    <div class="discount-unit-list">
      <horus-discount-unit-column
        v-for="discountUnit in discountUnits"
        :key="'discunit' + discountUnit.id"
        :discountUnit="discountUnit"
        :editable="false"
        v-on:showEvent="displayDiscountUnitDetail"
        />
    </div>
    <horus-calcuration-set-form-modal
      :calcurationSet="null"
      :modalDisplay="calcurationSetFormDisplay"
      v-on:cancelEvent="closeCalcurationSetForm"
      v-on:submitEvent="createCalcurationSet"
      />
    <horus-discount-unit-form-modal
      :discountUnit="targetDiscountUnit"
      :modalDisplay="discountUnitFormDisplay"
      v-on:cancelEvent="closeDiscountForm"
      v-on:submitEvent="submitDiscountForm"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { BillingSetting, BillingDiscount } from '@/api'

import HorusCalcurationSetColumn from '@/components/manageSystem/billingSetting/HorusCalcurationSetColumn.vue'
import HorusDiscountUnitColumn from '@/components/manageSystem/billingSetting/HorusDiscountUnitColumn.vue'
import HorusCalcurationSetFormModal from '@/components/manageSystem/billingSetting/HorusCalcurationSetFormModal.vue'
import HorusDiscountUnitFormModal from '@/components/manageSystem/billingSetting/HorusDiscountUnitFormModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

// import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusBillingSettingView',

  components: {
    HorusCalcurationSetColumn,
    HorusDiscountUnitColumn,
    HorusCalcurationSetFormModal,
    HorusDiscountUnitFormModal,
    HorusConfirmDialog,
  },

  props: {
    systemManager: Object
  },

  data () {
    return {
      calcurationSets: [],
      targetCalcurationSet: null,
      setDetailDisplay: false,
      calcurationSetFormDisplay: false,
      discountUnits: [],
      targetDiscountUnit: null,
      discountDetailDisplay: false,
      discountUnitFormDisplay: false,
    }
  },
  
  mounted () {
    this.loadCalcurationSets()
    this.loadDiscountUnits()
  },

  methods: {
    loadCalcurationSets () {
      BillingSetting.fetchList().then(res => {
        console.log(res)
        this.calcurationSets = res.organization_billing_calcuration_sets
      }).catch(error => {
        console.error(error)
      })
    },

    loadDiscountUnits () {
      BillingDiscount.fetchList().then(res => {
        console.log(res)
        this.discountUnits = res.organization_billing_discount_units
      })
    },

    displayCalcurationSetDetail (calcurationSet) {
      this.$router.push({ path: 'calcuration_set_detail/' + calcurationSet.id })
    },

    newCalcurationSet () {
      this.targetCalcurationSet = null
      this.calcurationSetFormDisplay = true
    },

    closeCalcurationSetForm () {
      this.calcurationSetFormDisplay = false
      this.targetCalcurationSet = null
    },

    createCalcurationSet (formData) {
      BillingSetting.create({ formData: formData }).then(res => {
        console.log(res)
        this.loadCalcurationSets()
        this.calcurationSetFormDisplay = false
        this.targetCalcurationSet = null
        this.showNotifyDialog('請求設定の新規作成', '請求設定を新規作成しました。')
      }).catch(error => {
        console.error(error)
        this.calcurationSetFormDisplay = false
        this.targetCalcurationSet = null
        this.showNotifyDialog('請求設定の新規作成失敗', '請求設定の新規作成に失敗しました。')
      })
    },

    displayDiscountUnitDetail (discountUnit) {
      this.$router.push({ path: 'discount_unit_detail/' + discountUnit.id })
    },

    newDiscountUnit () {
      this.targetDiscountUnit = null
      this.discountUnitFormDisplay = true
    },

    closeDiscountForm () {
      this.discountUnitFormDisplay = false
      this.targetDiscountUnit = null
    },

    submitDiscountForm (formData) {
      if (this.targetDiscountUnit) {
        this.updateDiscountUnit(formData)
      } else {
        this.createDiscountUnit(formData)
      }
    },

    createDiscountUnit (formData) {
      BillingDiscount.create({ formData: formData }).then(res => {
        console.log(res)
        this.loadDiscountUnits()
        this.discountUnitFormDisplay = false
        this.targetDiscountUnit = null
        this.showNotifyDialog('割引設定の新規作成', '割引設定を新規作成しました。')
      }).catch(error => {
        console.error(error)
        this.discountUnitFormDisplay = false
        this.targetDiscountUnit = null
        this.showNotifyDialog('割引設定の新規作成失敗', '割引設定の新規作成に失敗しました。')
      })
    },
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-billing-setting-view {
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
}
</style>