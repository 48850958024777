<template>
  <div class="horus-manage-area-block-detail-column" v-if="areaBlock">
    <h2>{{ areaBlock.name }}<img src="/static/images/pencil_icon.png" @click="editAreaBlock" /></h2>
    <div class="list-data">
      <div class="list-data-line">
        <div class="labels"><label>施設名称</label></div>
        <div class="content">{{ areaBlock.name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>ヨミガナ</label></div>
        <div class="content">{{ areaBlock.name_kana }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>略称</label></div>
        <div class="content">{{ areaBlock.short_name }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>略称カナ</label></div>
        <div class="content">{{ areaBlock.short_name_kana }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>郵便番号</label></div>
        <div class="content">{{ areaBlock.zip_code }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>住所</label></div>
        <div class="content">{{ fullAddrss }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>電話番号</label></div>
        <div class="content">{{ areaBlock.tel }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>メールアドレス</label></div>
        <div class="content">{{ areaBlock.mail }}</div>
      </div>
      <div class="list-data-line">
        <div class="labels"><label>施設参加導入URL</label></div>
        <div class="content">{{ organiztionRequetURL }}</div>
      </div>
    </div><!-- list data -->
    <h2>オペレーター</h2>
    <div class="list-column"
      v-for="operator in areaBlockOperators"
      :key="'sysAreaBlockOperator' + operator.id"
      >
      <div class="list-line">
        <div class="name"><span class="label">名前</span><span clas="content">{{ operator.name }}</span></div>
        <div class="name"><span class="label">ニックネーム</span><span clas="content">{{ operator.nickname }}</span></div>
        <div class="mail"><span class="label">メール</span><span clas="content">{{ operator.mail }}</span></div>
        <div class="state">
          <span class="label">状態</span>
          <span clas="content"><span class="signin" v-if="areaBlockOperator && operator.id === areaBlockOperator.id">[あなた]</span></span>
        </div>
      </div>
      <div class="controll">
        <button class="edit-btn" @click="editOperator(operator)">編集</button>
        <button class="edit-btn" v-if="!operator.deleted && ((areaBlockOperator && operator.id !== areaBlockOperator.id) || !areaBlockOperator)" @click="freeze(operator)">凍結</button>
        <button class="edit-btn" v-if="operator.deleted && ((areaBlockOperator && operator.id !== areaBlockOperator.id) || !areaBlockOperator)" @click="defrost(operator)">凍結解除</button>
        <button class="edit-btn" @click="editMail(operator)">メールアドレス変更</button>
        <button class="edit-btn" v-if="areaBlockOperator && operator.id === areaBlockOperator.id" @click="editPassword">パスワード変更</button>
      </div>
    </div>
    <h2>オペレーター案内&nbsp;<button type="button" class="new-btn" @click="createInvitation">新規作成</button></h2>
    <table class="invitation-table">
      <tr><th>名前</th><th>メールアドレス</th><th>期限</th><th>状態</th><th>&nbsp;</th></tr>
      <tr
        v-for="invitation in areaBlockOperatorInvitations"
        :key="'sysAreaBlockInv' + invitation.id"
        >
        <td>{{ invitation.name }}</td>
        <td>{{ invitation.mail }}</td>
        <td>{{ limitAtStr(invitation) }}</td>
        <td>{{ invitation.state }}</td>
        <td>
          <img src="/static/images/send_mail_icon.png" @click="sendMailInvitation(invitation)" v-if="!invitation.used" />
          <img src="/static/images/bucket_icon.png" @click="removeInvitation(invitation)" />
        </td>
      </tr>
    </table>
    <h2>
      利用規約
      &nbsp;<button class="new-tou-btn" @click="newTermOfUse">新規作成</button>
    </h2>
    <table class="term-of-use-table">
      <tr><th>タイトル</th><th>適用日</th><th>状態</th><th>&nbsp;</th></tr>
      <tr
        v-for="termOfUse in areaBlockTermOfUses"
        :key="'sysAreaTOU' + termOfUse.id"
        >
        <td>{{ termOfUse.title }}</td>
        <td>{{ termOfUseOpenAtStr(termOfUse) }}</td>
        <td>{{ termOfUseState(termOfUse) }}</td>
        <td>
          <button class="tou-display-btn" @click="showTermOfUseDetail(termOfUse)">詳細</button>
          <button class="tou-edit-btn" @click="editTermOfUse(termOfUse)">編集</button>
          <button class="tou-destroy-btn" @click="deleteTermOfUse(termOfUse)" v-if="!termOfUse.deleted">停止</button>
          <button class="tou-unlock-btn" @click="unlockTermOfUse(termOfUse)" v-if="termOfUse.deleted">復帰</button>
        </td>
      </tr>
    </table>
    <horus-manage-area-block-form-modal
      :sourceAreaBlock="areaBlock"
      :showModal="areaBlockFormModalDisplay"
      v-on:submitCancelEvent="modalSubmitCancel"
      v-on:submitEvent="modalSubmit"
      />
    <horus-area-block-operator-edit-modal
      :areaBlock="areaBlock"
      :sourceOperator="editTargetOperator"
      :showModal="operatorEditModalDisplay"
      v-on:cancelSubmitEvent="cancelEditOperator"
      v-on:submitEvent="submitUpdateOperator"
      />
    <horus-area-block-operator-edit-mail-modal
      :areaBlock="areaBlock"
      :areaBlockOperator="mailEditTargetOperator"
      :showModal="operatorMailEditModaldisplay"
      v-on:cancelSubmitEvent="cencelEditMail"
      v-on:submitEvent="submitChangeMail"
      />
    <horus-area-block-operator-edit-password-modal
      :showModal="operatorPasswordEditModalDisplay"
      v-on:cancelSubmitEvent="cancelEditPassword"
      v-on:submitEvent="submitUpdatePassword"
      />
    <horus-manage-area-block-operator-invitation-modal
      :areaBlock="areaBlock"
      :showModal="invitationCreateModalDisplay"
      v-on:cancelSubmitEvent="createInvitationCancel"
      v-on:submitEvent="createInvitationSubmit"
      />
    <horus-area-block-term-of-use-form-modal
      :areaBlock="areaBlock"
      :modalDisplay="termOfUseFormModalDisplay"
      :targetTermOfUse="targetTermOfUse"
      v-on:cancelEvent="closeTermOfUseForm"
      v-on:submitEvent="submitTermOfUse"
      />
    <horus-area-block-term-of-use-display-modal
      :termOfUse="targetTermOfUse"
      :modalDisplay="termOfUseDetailModalDisplay"
      v-on:closeEvent="closeTermOfUseDetail"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  AreaBlock,
  AreaBlockOperator,
} from '@/models'

import {
  AreaBlockOperatorInvitation,
  AreaBlockTermOfUse,
} from '@/api'

import HorusManageAreaBlockFormModal from '@/components/manageSystem/areaBlock/HorusManageAreaBlockFormModal.vue'
import HorusManageAreaBlockOperatorInvitationModal from '@/components/manageSystem/areaBlock/HorusManageAreaBlockOperatorInvitationModal.vue'
import HorusAreaBlockOperatorEditModal from '@/components/manageSystem/areaBlock/HorusAreaBlockOperatorEditModal.vue'
import HorusAreaBlockOperatorEditMailModal from '@/components/manageSystem/areaBlock/HorusAreaBlockOperatorEditMailModal.vue'
import HorusAreaBlockOperatorEditPasswordModal from '@/components/manageSystem/areaBlock/HorusAreaBlockOperatorEditPasswordModal.vue'
import HorusAreaBlockTermOfUseFormModal from '@/components/manageSystem/areaBlock/HorusAreaBlockTermOfUseFormModal.vue'
import HorusAreaBlockTermOfUseDisplayModal from '@/components/manageSystem/areaBlock/HorusAreaBlockTermOfUseDisplayModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as ADDRESS_DATA from '@/assets/constants/addressData.js'
import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusManageAreaBlockDetailColumn',

  components: {
    HorusManageAreaBlockFormModal,
    HorusAreaBlockOperatorEditModal,
    HorusAreaBlockOperatorEditMailModal,
    HorusAreaBlockOperatorEditPasswordModal,
    HorusManageAreaBlockOperatorInvitationModal,
    HorusAreaBlockTermOfUseFormModal,
    HorusAreaBlockTermOfUseDisplayModal,
    HorusConfirmDialog,
  },

  props: {
    systemManager: Object,
    areaBlockOperator: Object,
    areaBlockID: Number
  },

  data () {
    return {
      areaBlock: null,
      areaBlockOperators: [],
      areaBlockOperatorInvitations: [],
      areaBlockTermOfUses: [],
      areaBlockFormModalDisplay: false,
      editTargetOperator: null,
      operatorEditModalDisplay: false,
      mailEditTargetOperator: null,
      operatorMailEditModaldisplay: false,
      operatorPasswordEditModalDisplay: false,
      invitationCreateModalDisplay: false,
      termOfUseFormModalDisplay: false,
      termOfUseDetailModalDisplay: false,
      targetTermOfUse: null,
    }
  },

  watch: {
    '$route': { handler: 'initDatas', immediate: true }
  },

  computed: {
    fullAddrss () {
      var pref = ADDRESS_DATA.PREFECTURE_CODE_JP.find(code => code[0] === this.areaBlock.prefecture)
      var addressStr = ''
      if (pref) {
        addressStr += pref[1]
      }
      addressStr += this.areaBlock.city + this.areaBlock.address
      return addressStr
    },

    organiztionRequetURL () {
      return window.location.origin + '/prepare_create_organization_request?area_block_id=' + this.areaBlock.id
    },

  },

  methods: {
    async initDatas () {
      if (this.$route.query.area_block) {
        console.log(this.$route.query.area_block)
        let res = await AreaBlock.api().fetch({ targetID: this.$route.query.area_block })
        console.log(res)
        this.areaBlock = res
      } else if (this.areaBlockID) {
        let resp = await AreaBlock.api().fetch({ targetID: this.areaBlockID })
        console.log(resp)
        this.areaBlock = resp
      }
      this.loadOperators()
      this.loadOperatorInvitations()
      this.loadTermOfUses()
    },

    async reloadAreaBlock () {
      let res = await AreaBlock.api().fetch({ targetID: this.areaBlock.id })
      console.log(res)
      this.areaBlock = res
      this.loadOperators()
      this.loadOperatorInvitations()
    },

    loadOperators () {
      AreaBlockOperator.api().fetchList({ areaBlockID: this.areaBlock.id }).then(res => {
        this.areaBlockOperators = res.area_block_operators
      }).catch(err => {
        console.error(err)
      })
    },

    loadOperatorInvitations () {
      AreaBlockOperatorInvitation.fetchList({ areaBlockID: this.areaBlock.id }).then(res => {
        this.areaBlockOperatorInvitations = res.area_block_operator_invitations
      }).catch(err => {
        console.log(err)
      })
    },

    loadTermOfUses () {
      AreaBlockTermOfUse.fetchList({ areaBlockID: this.areaBlock.id }).then(res => {
        this.areaBlockTermOfUses = res.area_block_term_of_uses
      }).catch(err => {
        console.log(err)
      })
    },

    limitAtStr (invitation) {
      return moment(invitation.limit_at).format('YYYY/MM/DD HH:mm')
    },
    termOfUseOpenAtStr (termOfUse) {
      return moment(termOfUse.open_at).format('YYYY/MM/DD HH:mm')
    },

    termOfUseState (termOfUse) {
      if (termOfUse.deleted) {
        return '非公開'
      } else {
        return '公開'
      }
    },

    editAreaBlock () {
      this.areaBlockFormModalDisplay = true
    },

    modalSubmitCancel (callback) {
      this.areaBlockFormModalDisplay = false
      callback()
    },

    modalSubmit (formData, callback) {
      AreaBlock.api().update({ formData: formData }).then(() => {
        this.areaBlockFormModalDisplay = false
        callback()
        this.showNotifyDialog('更新完了', '管理団体の情報を更新しました。')
        this.reloadAreaBlock()
      }).catch(err => {
        console.error(err)
        this.areaBlockFormModalDisplay = false
        callback()
        this.showNotifyDialog('更新失敗', '管理団体の情報更新に失敗しました。')
      })
    },

    editOperator (operator) {
      this.editTargetOperator = operator
      this.operatorEditModalDisplay = true
    },

    cancelEditOperator (callback) {
      this.operatorEditModalDisplay = false
      this.editTargetOperator = null
      callback()
    },

    submitUpdateOperator (formData, callback) {
      AreaBlockOperator.api().update({ formData: formData }).then(() => {
        this.loadOperators()
        this.showNotifyDialog('オペレーター更新完了', '管理団体オペレーターの情報を更新しました。')
        this.editTargetOperator = null
        this.operatorEditModalDisplay = false
        callback()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('オペレーター更新失敗', '管理団体オペレーターの情報更新に失敗しました。')
      })
    },

    freeze (operator) {
      AreaBlockOperator.api().delete({ areaBlockID: this.areaBlock.id, targetID: operator.id }).then(() => {
        this.loadOperators()
        this.showNotifyDialog('オペレーター凍結', '管理団体オペレーター[' + operator.name + ']を凍結しました。')
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('オペレーター凍結失敗', '管理団体オペレーターの凍結に失敗しました。')
      })
    },

    defrost (operator) {
      AreaBlockOperator.api().unlock({ areaBlockID: this.areaBlock.id, targetID: operator.id }).then(() => {
        this.loadOperators()
        this.showNotifyDialog('オペレーター凍結解除', '管理団体オペレーター[' + operator.name + ']の凍結を解除しました。')
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('オペレーター凍結解除失敗', '管理団体オペレーターの凍結解除に失敗しました。')
      })
    },

    editMail (operator) {
      this.mailEditTargetOperator = operator
      this.operatorMailEditModaldisplay = true
    },

    cencelEditMail (callback) {
      this.operatorMailEditModaldisplay = false
      this.mailEditTargetOperator = null
      callback()
    },

    submitChangeMail (formData, callback) {
      AreaBlockOperator.api().changeMail({ formData: formData }).then(() => {
        this.showNotifyDialog('メールアドレス変更受付', 'メールアドレス変更の受付を行いました。\n確認作業を行うまではメールアドレスは変更されません。\n新しいメールアドレスに疎通確認メールが送信されています。メール本文のリンクから確認作業を行ってください。')
        this.operatorMailEditModaldisplay = false
        this.mailEditTargetOperator = null
        callback()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('メールアドレス変更受付失敗', 'メールアドレス変更の受付に失敗しました。')
      })
    },

    editPassword () {
      this.operatorPasswordEditModalDisplay = true
    },

    cancelEditPassword (callback) {
      this.operatorPasswordEditModalDisplay = false
      callback()
    },

    submitUpdatePassword (formData, callback) {
      AreaBlockOperator.api().updatePassword({ formData: formData }).then(() => {
        this.showNotifyDialog('パスワード変更完了', 'パスワードの変更を完了しました。')
        this.operatorPasswordEditModalDisplay = false
        callback()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('パスワード変更失敗', 'パスワードの変更に失敗しました。')
      })
    },

    createInvitation () {
      this.invitationCreateModalDisplay = true
    },

    createInvitationCancel (callback) {
      this.invitationCreateModalDisplay = false
      callback()
    },

    createInvitationSubmit (formData, callback) {
      AreaBlockOperatorInvitation.create({ formData: formData }).then(() => {
        this.invitationCreateModalDisplay = false
        callback()
        this.showNotifyDialog('新規オペレーター案内作成', '管理団体オペレーター案内を新規作成し、メールを送信しました。')
        this.loadOperatorInvitations()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('新規オペレーター案内作成失敗', '管理団体オペレーター案内の新規作成に失敗しました。')
      })
    },

    sendMailInvitation (invitation) {
      AreaBlockOperatorInvitation.sendMail({ areaBlockID: this.areaBlock.id, targetID: invitation.id }).then(() => {
        this.showNotifyDialog('オペレーター案内の再送信', '管理団体オペレーター案内の利用期限を延長し、メールを送信しました。')
        this.loadOperatorInvitations()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('オペレーター案内の再送信失敗', '管理団体オペレーター案内の再送信に失敗しました。')
      })
    },

    removeInvitation (invitation) {
      AreaBlockOperatorInvitation.destroy({ areaBlockID: this.areaBlock.id, targetID: invitation.id }).then(() => {
        this.showNotifyDialog('オペレーター案内の削除', '管理団体オペレーター案内を削除しました。送信済みメールのリンクからは登録できなくなりました。')
        this.loadOperatorInvitations()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('オペレーター案内の削除失敗', '管理団体オペレーター案内の削除に失敗しました。')
      })
    },

    showTermOfUseDetail (termOfUse) {
      this.targetTermOfUse = termOfUse
      this.termOfUseDetailModalDisplay = true
    },

    closeTermOfUseDetail () {
      this.termOfUseDetailModalDisplay = false
      this.targetTermOfUse = null
    },

    closeTermOfUseForm () {
      console.log('close tou')
      this.termOfUseFormModalDisplay = false
      this.targetTermOfUse = null
    },

    newTermOfUse () {
      this.targetTermOfUse = null
      this.termOfUseFormModalDisplay = true
    },

    submitTermOfUse (formData) {
      if (this.targetTermOfUse) {
        this.updateTermOfUse(formData)
      } else {
        this.createTermOfUse(formData)
      }
    },

    createTermOfUse (formData) {
      AreaBlockTermOfUse.create({ formData: formData }).then(res => {
        console.log(res)
        this.loadTermOfUses()
        this.termOfUseFormModalDisplay = false
        this.targetTermOfUse = null
      }).catch(err => {
        console.error(err)
        this.termOfUseFormModalDisplay = false
        this.targetTermOfUse = null
        this.showNotifyDialog('管理団体利用規約の作成失敗', '管理団体利用規約の作成に失敗しました。')
      })
    },

    editTermOfUse (termOfUse) {
      this.targetTermOfUse = termOfUse
      this.termOfUseFormModalDisplay = true
    },

    updateTermOfUse (formData) {
      AreaBlockTermOfUse.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadTermOfUses()
        this.termOfUseFormModalDisplay = false
        this.targetTermOfUse = null
      }).catch(err => {
        console.error(err)
        this.termOfUseFormModalDisplay = false
        this.targetTermOfUse = null
        this.showNotifyDialog('管理団体利用規約の更新失敗', '管理団体利用規約の更新に失敗しました。')
      })
    },

    deleteTermOfUse (termOfUse) {
      this.showConfirmDialog(
        '管理団体利用規約の停止',
        '管理団体利用規約を停止します。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDestroyTermOfUse(termOfUse) }
      )
    },

    executeDestroyTermOfUse (termOfUse) {
      AreaBlockTermOfUse.destroy({ areaBlockID: this.areaBlock.id, targetID: termOfUse.id }).then(res => {
        console.log(res)
        this.loadTermOfUses()
        this.showNotifyDialog('管理団体利用規約の削除', '管理団体利用規約を削除しました。')
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('管理団体利用規約の削除失敗', '管理団体利用規約の削除に失敗しました。')
      })
    },

    unlockTermOfUse (termOfUse) {
      this.showConfirmDialog(
        '管理団体利用規約の復帰',
        '管理団体利用規約を復帰します。\nよろしいですか?',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeUnlockTermOfUse(termOfUse) }
      )
    },

    executeUnlockTermOfUse (termOfUse) {
      AreaBlockTermOfUse.unlock({ areaBlockID: this.areaBlock.id, targetID: termOfUse.id }).then(res => {
        console.log(res)
        this.loadTermOfUses()
        this.showNotifyDialog('管理団体利用規約の復帰', '管理団体利用規約を復帰しました。')
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('管理団体利用規約の復帰失敗', '管理団体利用規約の復帰に失敗しました。')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-manage-area-block-detail-column {
  .list-data {
    background-color: #fff;
    .list-data-line {
      display: flex;
      padding: 3px 0;
      border-bottom: 1px solid #aaa;
      .labels {
        margin-right: 10px;
        width: 150px;
        text-align: right;
      }
      .content {
        padding: 0;
        background-color: inherit;
      }
      img {
        margin-left: 20px;
        height: 20px;
      }
    }
  }
  .list-column {
    margin-bottom: 10px;
    border-bottom: 1px solid #aaa;
    background-color: #fff;
    .list-line {
      display: flex;
      justify-content: space-between;
      .label {
        margin-right: 10px;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .controll {
      display: flex;
      justify-content: flex-end;
      margin: 5px 0;
      button {
        margin-left: 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
    th {
      text-align: left;
      border-bottom: 1px solid #aaa;
    }
    td {
      border-bottom: 1px solid #aaa;
      button {
        margin-left: 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    &.operator-table td .signin {
      font-size: 0.8em;
      color: #6c272d;
    }
  }
}
</style>
