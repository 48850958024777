import axios from './AxiosInstance'

export default {
  fetchList: ({ params }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing/list',
        withCredentials: true,
        params: params,
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetch: ({ targetID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing',
        withCredentials: true,
        params: { id: targetID }
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  fetchSummary: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization_billing/summary',
        withCredentials: true,
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  finalize: () => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing/finalize',
            withCredentials: true,
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  suspend: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing/suspend',
            withCredentials: true,
            params: { id: targetID },
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  resume: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing/resume',
            withCredentials: true,
            params: { id: targetID },
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  adjust: ({ formData }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing/adjust',
            withCredentials: true,
            data: formData,
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  finish: ({ targetID }) => {
    return axios.csrfHeader('PUT').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'put',
            url: 'organization_billing/finish',
            withCredentials: true,
            params: { id: targetID },
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

}