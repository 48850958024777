<template>
  <div class="horus-right-column-org-list">
    <h3><img src="/static/images/org_member.png" />organizations</h3>
    <div v-if="organizationsNowLoading" class="now-loading"><img class="progress" src="/static/images/progress_s.gif" />読み込み中...</div>
    <div v-else class="list-wrapper">
      <div class="org-block" v-for="organization in organizations" :key="organization.id">
        <div class="org-name" @click.stop="showOrgModal(organization)">{{ organization.shortName }}</div>
        <horus-organization-modal
          :organization="organization"
          :key="organization.id"
          :currentOpenOrg="currentOpenOrg"
          v-on:closeOrganizationModalEvent="closeOrgModal" />
        <div class="organization-members-block">
          <div class="horus-org-member-block"
            v-for="member in organizationVisibleMembers(organization)"
            :key="'addressbook' + member.id">
            <div class="member-display-wrapper" @click.stop="showOrgMemberModal(member)">
              <img v-bind:src="member.portrateSrc" class="org-member-portrate" />
              <span class="org-member-name">{{ member.name }}</span>
              <img src="/static/images/video_meeting_icon.png" class="start-video-meeting" @click.stop="createMeeting(member)" v-if="meetingAvailable" />
            </div>
            <horus-author-modal
              :author="member"
              :key="member.id"
              :currentOpenAuthor="currentOpenMember"
              v-on:closeAuthorModalEvent="closeOrgMemberModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

import HorusOrganizationModal from '@/components/common/HorusOrganizationModal.vue'
import HorusAuthorModal from '@/components/common/HorusAuthorModal.vue'

import HorusOrganizationMemberVisibility from '@/mixin/HorusOrganizationMemberVisibility.js'

export default {
  mixins: [HorusOrganizationMemberVisibility],

  name: 'HorusRightColumnOrgList',

  components: {
    HorusAuthorModal,
    HorusOrganizationModal
  },

  props: {
    organizations: Array,
    organizationsNowLoading: Boolean,
    organizationStaffVisibilities: Array,
    meetingAvailable: Boolean
  },

  data () {
    return {
      currentOpenOrg: -1,
      currentOpenMember: ''
    }
  },

  methods: {
    showOrgModal (organization) {
      // console.log('address show org..' + organization.id)
      this.currentOpenOrg = organization.id
    },

    closeOrgModal () {
      // console.log('clese org modal call,,,')
      this.currentOpenOrg = -1
    },

    showOrgMemberModal (member) {
      // console.log('address show member: ' + member.id)
      this.currentOpenMember = member.id
    },

    closeOrgMemberModal () {
      // console.log('clese org member call,,,')
      this.currentOpenMember = ''
    },

    createMeeting (memberAuthor) {
      // console.log('createmetting. ' + memberAuthor)
      this.$emit('createMeetingEvent', memberAuthor)
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
.horus-right-column-org-list {
  width: 100%;
  margin: 0;
  h3 {
    margin: 10px 0 5px 0;
    color: #666;
    font-size: 1em;
    line-height: 1.1;
    font-weight: 500;
    img {
      margin: 0;
      margin-right: 5px;
      width: 10px;
    }
  }
  .list-wrapper {
    margin: 10px 10px 20px 0;
  }
}

.org-block {
  position: relative;
}

.org-name {
  margin: 10px;
  margin-right: 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #aaa;
  color: #666;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.1;
}

.horus-org-member-block {
  margin: 8px 0;
  padding: 0 15px;
  font-size: 1.2em;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.member-display-wrapper {
  display: flex;
  align-items: center;
}

.org-member-portrate {
  width: 40px;
  margin-left: 5px;
}

.org-member-name {
  margin-left: 20px;
}
.start-video-meeting {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
</style>
