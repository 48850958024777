<template>
  <div class="horus-calcuration-set-detail-view">
    <h1 class="centering-title">請求設定詳細</h1><button class="back-btn" @click="backToList">一覧に戻る</button>
    <div class="calcuration-set" v-if="calcurationSet">
      <horus-calcuration-set-column
        :calcurationSet="calcurationSet"
        :jumpDetail="false"
        :editable="true"
        :listOpen="true"
        v-on:simulateEvent="simulate"
        v-on:editEvent="editCalcurationSet"
        v-on:lockEvent="lockCalcurationSet"
        v-on:depuricateEvent="depuricateCalcurationSet"
        v-on:destroyEvent="destroyCalcurationSet"
        v-on:addUnitEvent="addCalcurationUnit"
        v-on:editUnitEvent="editCalcurationUnit"
        v-on:destroyUnitEvent="destroyCalcurationUnit"
        />
    </div>
    <div class="applicate-organizations" v-if="calcurationSet && calcurationSet.state === 1">
      <h2>請求対象施設</h2>
      <div class="controll">
        <button class="applicate-btn" @click="selectApplicateOrganizations">割当施設選択</button>
        <button class="end-applicate-btn" @click="selectEndApplicateOrganizations">割当解除選択</button>
      </div>
      <div class="loading" v-show="organizationLoading">loading....</div>
      <div class="organizations" v-show="!organizationLoading">
        <div class="organization-list"
          v-for="applicated in applicatedOrganizations"
          :key="'apporg' + applicated.id"
          >
          <div class="line-item">
            <div class="org-name">{{ applicated.organization.name }}</div>
            <div class="number"><span class="title">施設番号: </span>{{ organizationNumberForHuman(applicated.organization) }}</div>
            <div class="stripe"><span class="title">Stripe顧客ID: </span>{{ applicated.organization.stripe_customer_id }}</div>
          </div>
          <div class="line-item">
            <div class="term">{{ formatedDate(applicated.applicate_start) }} ~ {{ formatedDate(applicated.applicate_end) }}</div>
            <div class="controll"><button class='delete-btn' @click="endApplicate(applicated)">適用解除</button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="test-billings" v-if="appBuildEnv !== 'production'">
      <div class="test-create-controll">
        <div class="notify">テスト専用の請求書作成</div>
        <input type="number" v-model="testCalcYear">年
        <input type="number" v-model="testCalcMonth">月
        <input type="number" v-model="testCalcDay">日
        <button class="test-clear-btn" @click="clearTestCalcValue">フォームのクリア</button>
        <button class="test-calc-btn" @click="testCreateBilling">テスト請求書作成</button>
      </div>
      <div class="test-created-list">
        <div class="organization-billing"
            v-for="orgBilling in testCreatedBillings"
            :key="'orgBill' + orgBilling.id">
            <div class="line-item">
              <span class="title">施設名</span>{{ orgBilling.organization.name }}
            </div>
            <div class="line-item">
              <span class="title">締め日</span>{{ orgBilling.period_end }}
            </div>
            <div class="line-item">
              <span class="title">状態</span>{{ orgBilling.billing_state_jp }}
            </div>
            <div class="controll">
              <button class="show-detail-btn" @click="showTestBillingDetail(orgBilling)">詳細表示</button>
            </div>
          </div>
      </div>
    </div>
    <horus-simulate-setting-modal
      :calcurationSet="calcurationSet"
      :modalDisplay="simulateSettingDisplay"
      v-on:closeEvent="closeSimulation"
      />
    <horus-calcuration-set-form-modal
      :calcurationSet="calcurationSet"
      :modalDisplay="calcurationSetFormDisplay"
      v-on:cancelEvent="closeCalcurationSetForm"
      v-on:submitEvent="updateCalcurationSet"
      />
    <horus-calcuration-unit-form-modal
      :calcurationSet="calcurationSet"
      :calcurationUnit="targetCalcurationUnit"
      :modalDisplay="calcurationUnitFormDisplay"
      v-on:cancelEvent="closeCalcurationUnitForm"
      v-on:submitEvent="submitCalcurationUnitForm"
      />
    <horus-select-applicate-organization-modal
      :title="'請求先割当選択'"
      :applicatedOrganizations="applicatedOrganizations"
      :modalDisplay="selectApplicateOrganizationDisplay"
      v-on:cancelEvent="closeSelectApplicateOrganizationModal"
      v-on:submitEvent="applicateOrganizations"
      />
    <horus-select-end-application-organization-modal
      :title="'請求先割当停止'"
      :applicatedOrganizations="applicatedOrganizations"
      :modal-display="selectEndApplicateOrganizationDisplay"
      v-on:cancelEvent="closeSelectEndApplicationOrganizationModal"
      v-on:submitEvent="endApplicateOrganizations"
      />
    <horus-organization-billing-detail-modal
      :organizationBilling="targetBilling"
      :modalDisplay="billingDetailDisplay"
      v-on:closeEvent="closeTestBillingDetail"
      />
    <horus-loading-dialog
      :showDialog="loadingDialogVisible"
      :title="loadingDialogTitle"
      :message="loadingDialogMessage"
      :loadMessage="loadingDialogLoadingMessage"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'
import { BillingSetting } from '@/api'

import HorusCalcurationSetColumn from '@/components/manageSystem/billingSetting/HorusCalcurationSetColumn.vue'
import HorusCalcurationSetFormModal from '@/components/manageSystem/billingSetting/HorusCalcurationSetFormModal.vue'
import HorusCalcurationUnitFormModal from '@/components/manageSystem/billingSetting/HorusCalcurationUnitFormModal.vue'
import HorusSelectApplicateOrganizationModal from '@/components/manageSystem/billingSetting/HorusSelectApplicateOrganizationModal.vue'
import HorusSelectEndApplicationOrganizationModal from '@/components/manageSystem/billingSetting/HorusSelectEndApplicationOrganizationModal.vue'
import HorusSimulateSettingModal from '@/components/manageSystem/billingSetting/HorusSimulateSettingModal.vue'

import HorusOrganizationBillingDetailModal from '@/components/manageSystem/organizationBilling/HorusOrganizationBillingDetailModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusLoadingDialog from '@/components/common/HorusLoadingDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'
import HorusLoadingDialogControll from '@/mixin/HorusLoadingDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll, HorusLoadingDialogControll],
  name: 'HorusCalcurationSetDetailView',

  components: {
    HorusConfirmDialog,
    HorusLoadingDialog,
    HorusCalcurationSetColumn,
    HorusCalcurationSetFormModal,
    HorusCalcurationUnitFormModal,
    HorusSelectApplicateOrganizationModal,
    HorusSelectEndApplicationOrganizationModal,
    HorusSimulateSettingModal,
    HorusOrganizationBillingDetailModal,
  },

  data () {
    return {
      calcurationSet: null,
      organizationLoading: false,
      applicatedOrganizations: [],
      calcurationSetFormDisplay: false,
      targetCalcurationUnit: null,
      calcurationUnitFormDisplay: false,
      selectApplicateOrganizationDisplay: false,
      selectEndApplicateOrganizationDisplay: false,
      simulateSettingDisplay: false,
      testCalcYear: 2024,
      testCalcMonth: 1,
      testCalcDay: 1,
      testCreatedBillings: [],
      targetBilling: null,
      billingDetailDisplay: false,
    }
  },

  watch: {
    '$route': { handler: 'loadCalcurationSet', immediate: true },
  },

  computed: {
    appBuildEnv () {
      return process.env.VUE_APP_BUILD_ENV
    },
  },

  methods: {
    formatedDate (date) {
      if (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    organizationNumberForHuman (org) {
      return org.organizationNumber.substr(0, 4) + '-' + org.organizationNumber.substr(4, 4) + '-' + org.organizationNumber.substr(8, 4) + '-' + org.organizationNumber.substr(12, 4)
    },

    loadCalcurationSet () {
      BillingSetting.fetch({ targetID: this.$route.params.id }).then(res => {
        console.log(res)
        this.calcurationSet = res.organization_billing_calcuration_set
        this.loadApplicatedOrganizations()
      })
    },

    loadApplicatedOrganizations () {
      if (this.calcurationSet) {
        this.organizationLoading = true
        BillingSetting.fetchApplicatedOrganizations({ targetID: this.calcurationSet.id, condition: '' }).then(res => {
          console.log(res)
          this.applicatedOrganizations = res.applicated_organization_sets
          this.organizationLoading = false
        }).catch(error => {
          console.error(error)
          this.organizationLoading = false
        })  
      }  
    },  

    backToList () {
      this.$router.push({ path: '/system_management/billing_setting' })
    },

    simulate () {
      this.simulateSettingDisplay = true
    },

    closeSimulation () {
      this.simulateSettingDisplay = false
    },

    editCalcurationSet () {
      if (this.calcurationSet.state === 0) {
        this.calcurationSetFormDisplay = true
      } else {
        this.showNotifyDialog('請求設定の編集不可', '編集可能以外の状態にある請求設定は編集できません。')
      }
    },

    closeCalcurationSetForm () {
      this.calcurationSetFormDisplay = false
    },

    updateCalcurationSet (formData) {
      BillingSetting.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.calcurationSetFormDisplay = false
        this.showNotifyDialog('請求設定の更新完了', '請求設定を更新しました。')
      }).catch(error => {
        console.error(error)
        this.calcurationSetFormDisplay = false
        this.showNotifyDialog('請求設定の更新失敗', '請求設定の更新に失敗しました。')
      })
    },

    lockCalcurationSet (calcurationSet) {
      this.showConfirmDialog(
        '請求設定の確定',
        '請求設定を確定します。確定すると、Stripeに設定を送信して請求先を追加できるようになります。\n確定後の編集、削除は行えません。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeLockCalcurationSet(calcurationSet) }
      )
    },
    
    executeLockCalcurationSet (calcurationSet) {
      this.showLoadingDialog('請求設定の確定中', 'Stripeに設定を送信し、データを更新しています。', '送信中...')
      BillingSetting.lock({ targetID: calcurationSet.id }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.closeLoadingDialog()
        this.showNotifyDialog('請求設定の確定完了', '請求設定を確定しました。')
      }).catch(error => {
        console.error(error)
        this.closeLoadingDialog()
        this.showNotifyDialog('請求設定の確定失敗', '請求設定の確定に失敗しました。')
      })
    },

    depuricateCalcurationSet (calcurationSet) {
      this.showConfirmDialog(
        '請求設定の停止',
        '請求設定を停止します。停止すると、すべての請求先施設が停止状態となり、この設定を利用した請求がなされなくなります。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeDepuricateCalcurationSet(calcurationSet) }
      )
    },

    executeDepuricateCalcurationSet (calcurationSet) {
      BillingSetting.depuricate({ targetID: calcurationSet.id }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.showNotifyDialog('請求設定の停止', '請求設定を停止しました。')
      }).catch(error => {
        console.log(error)
        this.showNotifyDialog('請求設定の停止失敗', '請求設定の停止に失敗しました。')
      })
    },

    destroyCalcurationSet (calcurationSet) {
      if (calcurationSet.state !== 0) {
        this.showNotifyDialog('請求設定の削除不可', '編集可能以外の状態にある請求設定は削除できません。')
      } else {
        this.showConfirmDialog(
          '請求設定の削除',
          '請求設定を削除します。この操作は元にもどす事ができません。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeDestroyCalcurationSet(calcurationSet) }
        )
      }
    },

    executeDestroyCalcurationSet (calcurationSet) {
      BillingSetting.destroy({ targetID: calcurationSet.id }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.showNotifyDialog('請求設定の削除', '請求設定を削除しました。')
      }).catch(error => {
        console.log(error)
        this.showNotifyDialog('請求設定の削除失敗', '請求設定の削除に失敗しました。')
      })
    },

    addCalcurationUnit () {
      this.targetCalcurationUnit = null
      this.calcurationUnitFormDisplay = true
    },

    closeCalcurationUnitForm () {
      this.calcurationUnitFormDisplay = false
      this.targetCalcurationUnit = null
    },

    submitCalcurationUnitForm (formData) {
      if (this.targetCalcurationUnit) {
        this.updateCalcurationUnit(formData)
      } else {
        this.createCalcurationUnit(formData)
      }
    },

    createCalcurationUnit (formData) {
      BillingSetting.addUnit({ formData: formData }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.targetCalcurationUnit = null
        this.calcurationUnitFormDisplay = false
        this.showNotifyDialog('明細の追加', '明細を追加しました。')
      }).catch(error => {
        console.error(error)
        this.targetCalcurationUnit = null
        this.calcurationUnitFormDisplay = false
        this.showNotifyDialog('明細の追加失敗', '明細の追加に失敗しました。')
      })
    },

    editCalcurationUnit (calcurationUnit) {
      this.targetCalcurationUnit = calcurationUnit
      this.calcurationUnitFormDisplay = true
    },

    updateCalcurationUnit (formData) {
      BillingSetting.updateUnit({ formData: formData }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.targetCalcurationUnit = null
        this.calcurationUnitFormDisplay = false
        this.showNotifyDialog('明細の更新', '明細を更新しました。')
      }).catch(error => {
        console.error(error)
        this.targetCalcurationUnit = null
        this.calcurationUnitFormDisplay = false
        this.showNotifyDialog('明細の更新失敗', '明細の更新に失敗しました。')
      })
    },

    destroyCalcurationUnit (calcurationUnit) {
      if (this.calcurationSet.state !== 0) {
        this.showNotifyDialog('請求設定の削除不可', '編集可能以外の状態にある請求設定は削除できません。')
      } else {
        this.showConfirmDialog(
          '請求明細の削除',
          '請求明細を削除します。この操作は元にもどす事ができません。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeDestroyCalcurationUnit(calcurationUnit) }
        )
      }
    },

    executeDestroyCalcurationUnit (calcurationUnit) {
      BillingSetting.destroyUnit({ setID: this.calcurationSet.id, unitID: calcurationUnit.id }).then(res => {
        console.log(res)
        this.loadCalcurationSet()
        this.showNotifyDialog('明細の削除', '明細を削除しました。')
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog('明細の削除失敗', '明細の削除に失敗しました。')
      })
    },

    selectApplicateOrganizations () {
      if (this.calcurationSet.state === 1) {
        this.selectApplicateOrganizationDisplay = true
      } else {
        this.showNotifyDialog('請求先割当不可', 'ロック状態にない請求設定は施設を割り当てできません。')
      }
    },

    closeSelectApplicateOrganizationModal () {
      this.selectApplicateOrganizationDisplay = false
    },

    applicateOrganizations (orgList) {
      let formData = new FormData()
      formData.append('id', this.calcurationSet.id)
      for(const orgID of orgList) {
        formData.append('organizations[]', orgID)
      }
      this.showLoadingDialog('Stripeへ送信中', 'Stripeに施設と課金設定を送信しています。', '送信中...')
      BillingSetting.applicateOrganizations({ formData: formData }).then(res => {
        console.log(res)
        this.selectApplicateOrganizationDisplay = false
        let message = '請求先施設を割り当てました。\n割当件数: '
        message += res.applicated_organizations.length
        message += '\n失敗件数: ' + res.failed_organizations.length
        message += '\n設定に失敗した施設\n'
        for (const org of res.failed_organizations) {
          message += org.name + '\n'
        }
        this.closeLoadingDialog()
        this.loadApplicatedOrganizations()
        this.showNotifyDialog(
          '請求先割当', 
          message
        )
      }).catch(error => {
        console.error(error)
        this.closeLoadingDialog()
        this.selectApplicateOrganizationDisplay = false
      })
    },

    selectEndApplicateOrganizations () {
      if (this.calcurationSet.state === 1) {
        this.selectEndApplicateOrganizationDisplay = true
      } else {
        this.showNotifyDialog('請求先割当不可', 'ロック状態にない請求設定は施設を割り当てできません。')
      }
    },

    closeSelectEndApplicationOrganizationModal () {
      this.selectEndApplicateOrganizationDisplay = false
    },

    endApplicateOrganizations (orgList) {
      let formData = new FormData()
      formData.append('id', this.calcurationSet.id)
      for(const orgID of orgList) {
        formData.append('organizations[]', orgID)
      }
      this.showLoadingDialog('Stripeへ送信中', 'Stripeに施設と課金設定を送信しています。', '送信中...')
      BillingSetting.endApplicateOrganizations({ formData: formData }).then(res => {
        console.log(res)
        this.selectEndApplicateOrganizationDisplay = false
        let message = '請求先施設を割り当てを停止しました。\n割当停止件数: '
        message += res.end_plicate_organizations.length
        message += '\n停止失敗件数: ' + res.failed_organizations.length
        message += '\n停止に失敗した施設\n'
        for (const org of res.failed_organizations) {
          console.log(org)
          message += org.name + '\n'
        }
        this.closeLoadingDialog()
        this.loadApplicatedOrganizations()
        this.showNotifyDialog(
          '請求先割当停止', 
          message
        )
      }).catch(error => {
        console.error(error)
        this.selectEndApplicateOrganizationDisplay = false
      })
    },

    endApplicate (applicatedOrganization) {
      if (this.calcurationSet.state === 1) {
        let formData = new FormData()
        formData.append('id', this.calcurationSet.id)
        formData.append('organizations[]', applicatedOrganization.organization.id)
        BillingSetting.endApplicateOrganizations({ formData: formData }).then(res => {
          console.log(res)
          this.loadApplicatedOrganizations()
        })
      } else {
        this.showNotifyDialog('請求先割当不可', 'ロック状態にない請求設定は施設を割り当てできません。')
      }
    },

    clearTestCalcValue () {
      this.testCalcYear = 2024
      this.testCalcMonth = 1
      this.testCalcDay = 1
    },

    testCreateBilling () {
      this.showConfirmDialog(
          'テスト請求の作成',
          'テスト請求を作成します。',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeTestCreateBilling() }
        )
    },

    executeTestCreateBilling () {
      let formData = new FormData()
      formData.append('id', this.calcurationSet.id)
      formData.append('year', this.testCalcYear)
      formData.append('month', this.testCalcMonth)
      formData.append('day', this.testCalcDay)
      BillingSetting.testBillingCreate({ formData }).then(res => {
        console.log(res)
        this.testCreatedBillings = res.organization_billings
      }).catch(error => {
        console.error(error)
      })
    },

    showTestBillingDetail (organizationBilling) {
      this.targetBilling = organizationBilling
      this.billingDetailDisplay = true
    },

    closeTestBillingDetail () {
      this.billingDetailDisplay = false
      this.targetBilling = null
    },
    
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-calcuration-set-detail-view {
  padding: 10px 20px;
  .back-btn {
    width: 100px;
    margin-left: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    background-color: #666;
    color: #fff;
  }
  .calcuration-set {
    margin: 10px 0;
  }
  .calcuration-unit-controll {
    text-align: right;
    .add-unit-btn {
      width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      background-color: #666;
      color: #fff;
    }
  }
  .applicate-organizations {
    .controll {
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
      }
    }
    .organizations {
      .organization-list {
        border-bottom: 1px solid #ddd;
        padding: 5px 0;
        .line-item {
          display: flex;
          padding: 5px 0;
          div {
            margin-right: 10px;
            .title {
              font-weight: bold;
              font-size: 0.8rem;
            }
          }
          .org-name {
            font-weight: bold;
          }
        }
      }
    }
  }
  .test-billings {
    .test-create-controll {
      .notify {
        margin: 5px;
        padding: 5px;
        border: 1px solid #f00;
      }
      input[type=number] {
        width: 4rem;
      }
      button {
        margin-left: 10px;
        border: 1px solid #666;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        background-color: #666;
        color: #fff;
        &.test-clear-btn {
          background-color: #fff;
          color: #666;
        }
      }
    }
    .test-created-list {

    }
  }
}
</style>