<template>
  <div class="horus-area-block-term-of-use-form-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1><img src="/static/images/pencil_icon.png" class="title-icon" />{{ titleText }}</h1>
      </div>
      <div class="modal-body">
        <horus-attribute-in-form
          :labelText="'タイトル'"
          :necessary="true"
          :validationState="titleValidation.state"
          :validationMessage="titleValidation.message"
          :attributeNotify="''">
          <input type="text" v-model="title" />
        </horus-attribute-in-form>
        <div class="date-inputs">
          <div class="label">公開日時</div>
          <div class="content">{{ openAtStr }}</div>
          <img src="/static/images/calender_icon.png" @click="pickOpenAt" />
          <button class="reset-btn" @click="resetOpenAt">公開日時を設定しない</button>
        </div>
        <div class="content-column">
          <div class="content-input">
            <textarea v-model="content"></textarea>
          </div>
          <div class="content-display narrativebook_rule" v-html="parsedContent"></div>
        </div>
        <div class="submit-controlls">
          <button type="button" class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button type="button" class="submit-btn" :class="{disable: !allValid}" @click="submit">{{ submitText }}</button>
        </div>
      </div>
    </horus-modal-frame>
    <horus-date-picker
      :showDatePicker="showDatePicker"
      :defaultDate="pickTargetDate"
      :timeSelection="false"
      v-on:closeEvent="pickDateCancel"
      v-on:datePickupEvent="datePicked"
      />
  </div>
</template>

<script>
import moment from 'moment'
import { Marked } from 'marked'

import HorusDatePicker from '@/components/common/HorusDatePicker.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'

export default {
  name: 'HorusAreaBlockTermOfUseFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
    HorusDatePicker,
  },

  props: {
    areaBlock: Object,
    targetTermOfUse: Object,
    modalDisplay: Boolean,
  },

  emits: [
    'submitEvent',
    'cancelEvent',
  ],

  data() {
    return {
      title: '',
      content: '',
      openAt: null,
      showDatePicker: false,
      pickTargetDate: new Date(),
    }
  },

  computed: {
    titleText () {
      if (this.targetTermOfUse) {
        return '管理団体利用規約編集'
      } else {
        return '管理団体利用規約作成'
      }
    },

    submitText () {
      if (this.targetTermOfUse) {
        return '更新'
      } else {
        return '作成'
      }
    },

    titleValidation () {
      if (this.title.trim().length > 0) {
        return { valid: true, state: 'valid', message: '' }
      } else {
        return { valid: false, state: 'invalid', message: '空白を許しません。'}
      }
    },

    openAtStr () {
      if (this.openAt) {
        return moment(this.openAt).format('YYYY/MM/DD HH:mm')
      } else {
        return '-----'
      }
    },

    parsedContent () {
      const marked = new Marked()
      return marked.parse(this.content)
    },

    allValid () {
      if (this.titleValidation.valid) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    modalDisplay: { handler: 'initData', immediate: true }
  },

  methods: {
    initData () {
      if (this.modalDisplay) {
        if (this.targetTermOfUse) {
          this.title = this.targetTermOfUse.title
          this.content = this.targetTermOfUse.content
          this.openAt = this.targetTermOfUse.open_at
        } else {
          this.title = ''
          this.content = ''
          this.openAt = null
        }
      }
    },

    pickOpenAt () {
      if (this.openAt) {
        this.pickTargetDate = this.openAt
      } else {
        this.pickTargetDate = new Date()
      }
      this.showDatePicker = true
    },

    resetOpenAt () {
      this.openAt = null
    },

    pickDateCancel () {
      this.pickTargetDate.date = new Date()
      this.showDatePicker = false
    },

    datePicked (selectedDate, callback) {
      console.log(selectedDate)
      console.log(callback)
      this.openAt = new Date(selectedDate.getTime())
      this.pickTargetDate = new Date()
      this.showDatePicker = false
      callback()
    },

    cancelSubmit () {
      console.log('cancel tou form')
      this.$emit('cancelEvent')
    },

    submit () {
      let formData = new FormData()
      formData.append('area_block_id', this.areaBlock.id)
      if (this.targetTermOfUse) {
        formData.append('id', this.targetTermOfUse.id)
      }
      formData.append('area_block_term_of_use[title]', this.title)
      formData.append('area_block_term_of_use[content]', this.content)
      if (this.openAt) {
        formData.append('area_block_term_of_use[open_at]', this.openAt)
      }
      this.$emit('submitEvent', formData)
    },

  },
}
</script>

<style lang="scss" scoped>
.horus-area-block-term-of-use-form-modal {
  .modal-body {
    padding: 10px;
    .date-inputs {
      display: flex;
      align-items: center;
      .content {
        margin: 0 10px;
      }
      img {
        margin: 0 10px;
      }
      button {
        margin: 0 10px;
        border: 1px solid #666;
        border-radius: 3px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
      }
    }
    .submit-controlls {
      margin: 10px 0;
    }
    .content-column {
      height: calc(100vh - 300px);
      display: flex;
      justify-content: space-between;
      .content-input {
        height: 100%;
        width: calc(50% - 10px);
        textarea {
          height: 100%;
          width: 100%;
        }
      }
      .content-display {
        height: calc(100% - 20px);
        width: calc(50% - 30px);
        overflow: auto;
        border: 1px solid #aaa;
      }
    }
  }
}
</style>