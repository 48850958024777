<template>
  <div class="horus-member-profile-configure-view">
    <div class="profile-header">
      <router-link to="/member_profile_personal" active-class="active">
        <button class="to-personal-btn">個人設定</button>
      </router-link>
      <button class="to-member-btn disable">職員用設定</button>
    </div>
    <div class="member-profile">
      <h1 class="centering-title">職員用設定</h1>
      <div class="red_titled_column">
        <h4>所属施設設定</h4>
        <div class="list-data">
          下記に登録した情報は施設をかかりつけ設定された利用者へ公開されます。<br />
          設定されていない場合は、名前と写真は基本設定で登録されている情報が表示されます。
        </div>
      </div>
      <div class="member-list" v-for="suom in members" :key="'memberList' + suom.id">
        <div class="red_titled_column">
          <h4>
            {{ suom.organization.name }}
            <span v-if="suom.member_manager || suom.relation_manager || suom.deputize_manager">(管理者)</span>
            <button type="button" class="first-signin-btn" v-if="suom.default_signin">最初のログイン先</button>
            <button type="button" class="to-first-signin-btn" v-if="!suom.default_signin" @click="toFirstSignin(suom)">最初のログイン先にする</button>
          </h4>
          <div class="list-data">
            <div class="list-data-line">
              <div class="content">〒{{ suom.organization.zipCode }}&nbsp;{{ suom.organization.address }}<br/>TEL:{{ suom.organization.tel }}</div>
            </div>
          </div>
          <h4>
            <span>施設利用者へ表示するプロフィール</span>
            <img src="/static/images/pencil_icon.png" class="edit-user-basic-btn" @click="editMemberProfile(suom)" />
          </h4>
          <div class="list-data">
            <div class="list-data-line">
              <div class="labels"><label>名前</label></div>
              <div class="content">{{ suom.name }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>メール</label></div>
              <div class="content">{{ suom.mail }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>電話</label></div>
              <div class="content">{{ suom.tel }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>職種</label></div>
              <div class="content">{{ suom.additional_comment }}</div>
            </div>
            <div class="list-data-line">
              <div class="labels"><label>ポートレート</label></div>
              <div class="content"><img :src="suom.author.portrateSrc" /></div>
            </div>
          </div>
          <h4>アプリ専用ログイン情報</h4>
          <div class="list-data">
            <div class="list-data-line"
              v-for="app_token in suom.app_signin_tokens"
              :key="'appsignintoken' + app_token.id">
              <div class="labels">{{ app_token.app_type }}</div>
              <div class="content">
                <span class="app-signin-create-at" v-html="timeStrToStr(app_token.created_at)"></span>
                <button class="remove-app-signin-btn" @click="removeAppSigninToken(suom, app_token)">利用停止</button>
              </div>
            </div>
            <div v-if="suom.app_signin_tokens.length <= 0">現在利用中のアプリ専用ログイン情報はありません</div>
          </div>
        </div><!-- red titled column end -->
      </div><!-- member list end -->
      <horus-footer />
    </div><!-- member profile end -->
    <horus-member-profile-third-party-linkage-modal
      :linkage="targetThirdPartyLinkage"
      :showThirdPartyLinkageModal="showThirdPartyLinkageModal"
      v-on:closeEvent="closeThirdPartyLinkageModal"
      />
    <horus-member-profile-edit-modal
      :suom="targetSuom"
      :showMemberProfileEditModal="showMemberProfileEditModal"
      v-on:cancelSubmitEvent="cancelMemberProfileSubmit"
      v-on:executeSubmitEvent="executeMemberProfileSubmit"
      />
    <!-- confirm dialog -->
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import moment from 'moment'

import {
  Author,
  Organization
} from '@/models'
import {
  AppSigninToken,
  ServiceUsingOrganizationMember,
  ThirdPartySigninToken
} from '@/api'

import HorusMemberProfileEditModal from '@/components/memberProfile/HorusMemberProfileEditModal.vue'
import HorusMemberProfileThirdPartyLinkageModal from '@/components/memberProfile/HorusMemberProfileThirdPartyLinkageModal.vue'
// import HorusMemberProfileClientCertificationCreateModal from '@/components/memberProfile/HorusMemberProfileClientCertificationCreateModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'
import HorusFooter from '@/components/common/HorusFooter.vue'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusMemberProfileConfigureView',

  components: {
    HorusFooter,
    HorusMemberProfileEditModal,
    HorusMemberProfileThirdPartyLinkageModal,
    // HorusMemberProfileClientCertificationCreateModal,
    HorusConfirmDialog
  },

  props: {
    signinMember: Object
  },

  data () {
    return {
      members: [],
      showMemberProfileEditModal: false,
      targetSuom: {
        id: null,
        name: '',
        mail: '',
        tel: '',
        additional_comment: '',
        organization_id: null,
        author_id: null,
        member_manager: false,
        relation_manager: false,
        deputize_manager: false,
        default_signin: false,
        organization: null,
        author: null
      },
      showThirdPartyLinkageModal: false,
      targetThirdPartyLinkage: null,
      showCreateCertificateModal: false,
      certificationTargetMember: null
    }
  },

  watch: {
    signinMember: { handler: 'initObjects', immediate: true }
  },

  methods: {
    async initObjects () {
      if (this.signinMember) {
        await ServiceUsingOrganizationMember.fetch().then(async res => {
        // await this.$store.dispatch('fetchServiceUsingOrganizationMembers').then(async res => {
          var members = res.service_using_organization_members
          var authorIDList = []
          var orgIDList = []
          for(var mem of members) {
            if (!Organization.find(mem.organization_id)) {
              if (orgIDList.indexOf(mem.organization_id) < 0) orgIDList.push(mem.organization_id)
            }
            if (!Author.find('mem' + mem.id)) {
              if (authorIDList.indexOf('mem' + mem.id) < 0) authorIDList.push('mem' + mem.id)
            }
          }
          if (orgIDList.length > 0) {
            await Organization.api().fetchByID({ idList: orgIDList })
          }
          if (authorIDList.length > 0) {
            await Author.api().fetchByID({ idList: authorIDList })
          }
          for(var suom of members) {
            suom.organization = Organization.find(suom.organization_id)
            suom.author = Author.find('mem' + suom.id)
          }
          this.members = members
        })
      }
    },

    showThirdPartySigninToken (tpst) {
      this.targetThirdPartyLinkage = tpst
      this.showThirdPartyLinkageModal = true
    },

    closeThirdPartyLinkageModal () {
      this.showThirdPartyLinkageModal = false
      this.targetThirdPartyLinkage = null
    },

    toFirstSignin (suom) {
      ServiceUsingOrganizationMember.updateDefaultSignin({ targetID: suom.id }).then(() => {
        this.members.forEach(mem => {
          if (mem.id === suom.id) {
            mem.default_signin = true
          } else {
            mem.default_signin = false
          }
        })
      }).carch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '最初のログイン先更新失敗',
          '最初にログインする職員の設定に失敗しました。\n最初のログイン先は変更されていません。'
        )
      })
    },

    resetTargetSuom () {
      this.targetSuom = {
        id: null,
        name: '',
        mail: '',
        tel: '',
        additional_comment: '',
        organization_id: null,
        author_id: null,
        member_manager: false,
        relation_manager: false,
        deputize_manager: false,
        default_signin: false,
        organization: null,
        author: null
      }
    },

    editMemberProfile (suom) {
      this.targetSuom = Object.assign(this.targetSuom, suom)
      this.showMemberProfileEditModal = true
    },

    cancelMemberProfileSubmit () {
      this.showConfirmDialog(
        '基本情報の編集キャンセル',
        '基本情報の編集をキャンセルします。\n編集した内容は全て破棄されます。よろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.resetTargetSuom()
          this.showMemberProfileEditModal = false
        }
      )
    },

    executeMemberProfileSubmit (formData) {
      ServiceUsingOrganizationMember.update({ formData: formData }).then(() => {
        // TODO: fire app event.
        console.log('in views/HorusMemberProfileConfigureView.vue, executeMemberProfileSubmit succss. check native.status. : native.status: ' + this.$native )
        if (this.$native) {
          console.log('in views/HorusMemberProfileConfigureView.vue, executeMemberProfileSubmit succss. gonna fire update event.')
          this.$native.event('userProfileUpdated', { updatedAt: new Date(), targetAuthorID: this.signinMember.author_id })
          console.log('in views/HorusMemberProfileConfigureView.vue, executeMemberProfileSubmit succss. gonna fired update event.')
        }
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '職員情報の更新失敗',
          '職情報の更新に失敗しました。'
        )
      })
    },

    timeStrToStr (timeStr) {
      var targetTime = new Date(timeStr)
      if (targetTime) {
        return moment(targetTime).format('YYYY/MM/DD HH:mm:ss')
      }
      return ''
    },

    removeAppSigninToken (suom, token) {
      this.showConfirmDialog(
        'アプリ専用ログイン設定の停止',
        'アプリ専用ログイン設定[' + token.app_type + ']の利用を停止します。利用停止すると、設定されているアプリからの自動ログインが行えなくなります。\n停止してよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRemoveAppSigninToken(suom, token)
        }
      )
    },

    executeRemoveAppSigninToken (suom, token) {
      AppSigninToken.destroy({ targetUserID: this.signinMember.user_id, targetID: token.id }).then(() => {
        this.initObjects()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog('アプリ専用ログイン設定の削除失敗', 'アプリ専用ログイン設定の削除に失敗しました')
      })
    },

    removeThirdPartySigninToken (suom, thirdPartySignin) {
      this.showConfirmDialog(
        '第三者連携サービス利用ログインの停止',
        '第三者連携サービス利用ログイン[' + thirdPartySignin.name + ']の停止します。\n停止した場合、この第三者連携サービスを利用したログインは出来なくなります。\nよろしいですか?',
        DIALOG_TYPE.YES_NO,
        () => {
          this.executeRemoveThirdPartySigninToken(suom, thirdPartySignin)
        }
      )
    },

    executeRemoveThirdPartySigninToken (suom, thirdPartySignin) {
      ThirdPartySigninToken.destroy({ serviceUserID: suom.id, targetID: thirdPartySignin.third_party_linkage_id }).then(() => {
        this.initObjects()
      }).catch(error => {
        console.error(error.response)
        this.showNotifyDialog(
          '第三者連携サービスの停止失敗',
          '第三者連携サービスの停止に失敗しました。'
        )
      })
    },

    provideCertificate (suom) {
      this.certificationTargetMember = suom
      this.showCreateCertificateModal = true
    },

    cancelCerfiticateRequest () {
      this.certificationTargetMember = null
      this.showCreateCertificateModal = false
    },
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-member-profile-configure-view {
  height: calc(100% - 51px);
  .profile-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    height: 30px;
    background-color: #ddd;
    border-bottom: 1px solid #aaa;
    button {
      border: 1px solid #666;
      border-radius: 3px;
      background-color: #666;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      &.disable {
        background-color: #fff;
        color: #666;
      }
    }
  }
  .centering-title {
    margin: 0 0 10px;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
  .member-profile {
    height: calc(100% - 60px);
    overflow: scroll;
    padding: 10px 50px;
    @include mediaQuery('phone') {
      padding: 10px;
    }
    .member-list {
      margin-bottom: 10px;
      padding: 0 10px;
      border: 1px solid #aaa;
    }
    .red_titled_column {
      margin-bottom: 20px;
      h4 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 10px 0;
        padding-bottom: 5px;
        color: #6c272d;
        border-bottom: 1px solid #aaa;
        font-size: 18px;
        font-weight: normal;
        @include mediaQuery('phone') {
          font-size: 16px;
        }
        img {
          margin-left: 20px;
          height: 20px;
        }
        button {
          margin-left: 20px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
          &.first-signin-btn {
            border: 1px solid #5cb85c;
            background-color: #5cb85c;
          }
        }
      }
      .list-data {
        .list-data-line {
          margin-bottom: 10px;
          .labels {
            font-size: 0.9em;
            font-weight: bold;
          }
          .content {
            margin-left: 10px;
            border-bottom: 1px solid #ddd;
            img {
              width: 100px;
            }
            .certification-notice {
              margin-right: 10px;
              color: #f00;
              font-weight: bold;
            }
            .third-party-signin-create-at {
              margin: 0 10px;
              font-size: 12px;
            }
            .remove-third-party-signin-btn {
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
            .remove-app-signin-btn {
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
            .download-certificate-btn {
              padding: 2px 4px;
              margin-right: 10px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
              text-decoration: none;
            }
            .show-certificate-password-btn {
              padding: 2px 4px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
            .restore-certificate-btn {
              padding: 2px 4px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
            .recreate-certificate-btn {
              padding: 2px 4px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
            .destroy-certificate-btn {
              padding: 2px 4px;
              border: 1px solid #666;
              border-radius: 3px;
              background-color: #666;
              color: #fff;
              font-size: 13px;
              font-weight: bold;
            }
          }
        }
        .provide-certificate {
          padding: 2px 4px;
          border: 1px solid #666;
          border-radius: 3px;
          background-color: #666;
          color: #fff;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
