<template>
  <div class="author-modal" v-show="currentOpenAuthor === author.id" v-click-outside="closeSelf">
    <div class="author-modal-head">
      <img :src="author.portrateSrc" class="author-modal-portrate">
      <br>
      {{ author.name }}<br>
      <span class="author-name-additional" v-if="author.nameAdditional.length > 0">{{ author.nameAdditional }}</span>
    </div>
    <div class="author-modal-body">
      <div class="author-address" v-if="author.address">{{ author.address }}</div>
      <div class="author-tel" v-if="author.tel">TEL: <a :href="'tel:' + phoneNumber" @click="telephoneLinkClicked">{{ author.tel }}</a></div>
      <div class="author-mail" v-if="author.mail">Mail: {{ author.mail }}</div>
      <div class="author-status" v-if="author.relationship < 10">{{ familyStatusText(author) }}</div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'HorusAuthorModal',

  props: {
    author: Object,
    relationship: Number,
    currentOpenAuthor: String
  },

  computed: {
    telephoneLink () {
      if (this.$native.status) {
        return '#'
      } else {
        return 'tel:' + this.author.tel
      }
    },

    phoneNumber () {
      var regResult = /(0\d{1,4}-\d{2,4}-\d{4})/g.exec(this.author.tel)
      if (regResult) {
        return regResult[0]
      } else {
        return null
      }
    }
  },

  methods: {
    familyStatusText (author) {
      switch (author.relationship) {
        case 1:
          return '(お互いにタイムラインを見ることが出来ます)'
        case 2:
          return '(この家族はあなたのタイムラインを見ることが出来ます)'
        case 3:
          return '(あなたはこの家族のタイムラインを見ることが出来ます)'
        default:
          return ''
      }
    },

    closeSelf () {
      // console.log('close self......')
      if (this.currentOpenAuthor === this.author.id) {
        this.$emit('closeAuthorModalEvent')
      }
    },

    telephoneLinkClicked () {
      if (this.$native.status) {
        this.$native.event('callTelephoneEvent', this.phoneNumber)
      } else {
        console.log('telephone link clicked...' + this.author.tel)
      }
    }
  },

  // vue-click-outside使用の際の必須項目
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang='scss' scoped>
.author-modal {
  position: absolute;
  top: 30px;
  left: 10px;
  width: 280px;
  background-color: #fff;
  border: 1px solid #aaa;
  z-index: 1000;
}
.author-modal-head {
  padding: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  color: #666;
  img {
    width: 100px;
  }
  .author-name-additional {
    font-size: 12px;
    font-weight: normal;
  }
}
.author-modal-body {
  padding: 10px;
  border-top: 1px solid #ddd;
  .author-status {
    font-size: 0.7em;
  }
}
</style>
