import axios from './AxiosInstance'

export default {
  customerPortalURL: ({ targetID }) => {
    let formData = new FormData()
    formData.append('id', targetID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization/stripe_customer_portal',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetch: ({ organizationID }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: 'organization/stripe_customer',
        withCredentials: true,
        params: { id: organizationID },
      }).then(res => {
        resolve(res.data)
      }).catch(error => { reject(error) })
    })
  },

  create: ({ organizationID }) => {
    let formData = new FormData()
    formData.append('id', organizationID)
    return axios.csrfHeader('POST').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'post',
            url: 'organization/create_stripe_customer',
            withCredentials: true,
            data: formData
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },
  
  revoke: ({ organizationID }) => {
    return axios.csrfHeader('DELETE').then(header => {
      return new Promise((resolve, reject) => {
        axios.httpClient({
          ...header,
          ...{
            method: 'delete',
            url: 'organization/revoke_stripe_customer',
            withCredentials: true,
            params: { id: organizationID }
          }
        }).then(res => {
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

}