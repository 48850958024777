<template>
  <div class="horus-applicated-organization-column">
    <div class="selection">
      <horus-custom-check-box
        :active="selected"
        :enabled="true"
        :type="'color'"
        />
    </div>
    <div class="info">
      <div class="line-item">
        <div class="name">{{ applicatedOrganization.organization.name }}({{ applicatedOrganization.organization.nameKana }})</div>
      </div>
      <div class="line-item">
        <div class="applicate-at"><span class="title">適用日: </span>{{ formatedApplicateStart }}</div>
      </div>
      <div class="line-item">
        <div class="number"><span class="title">施設番号: </span>{{ organizationNumberForHuman }}</div>
        <div class="stripe"><span class="title">Stripe顧客ID: </span>{{ applicatedOrganization.organization.stripe_customer_id }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

export default {
  name: 'HorusApplicatedOrganizationColumn',

  components: {
    HorusCustomCheckBox,
  },

  props: {
    applicatedOrganization: Object,
    selected: Boolean,
  },

  computed: {
    formatedApplicateStart () {
      if (this.applicatedOrganization.applicate_start) {
        return moment(this.applicatedOrganization.applicate_start).format('YYYY/MM/DD HH:mm')
      }
      return ''
    },

    organizationNumberForHuman () {
      return this.applicatedOrganization.organization.organizationNumber.substr(0, 4) 
        + '-' + this.applicatedOrganization.organization.organizationNumber.substr(4, 4) 
        + '-' + this.applicatedOrganization.organization.organizationNumber.substr(8, 4) 
        + '-' + this.applicatedOrganization.organization.organizationNumber.substr(12, 4)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-applicated-organization-column {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 10px 0;
  .selection {
    margin-right: 10px;
  }
  .info {
    .line-item {
      display: flex;
      padding: 3px 0;
      div {
        margin-right: 10px;
        .title {
          font-weight: bold;
          font-size: 0.8rem;
        }
      }
      .name {
        font-weight: bold;
      }
    }
  }
}
</style>