<template>
  <div class="horus-organization-billing-view">
    <h1 class="centering-title">施設請求</h1>
    <h2>絞り込み条件</h2>
    <div class="display-conditions">
      <div class="text-conditions">
        <div class="state-condition">
          <label>状態</label>
          <select v-model="billingState">
            <option
              v-for="state in BILLING_STATES"
              :key="state.value"
              :value="state.value"
              >{{ state.name }}</option>
          </select>
        </div>
        <div class="month-condition">
          <input type="number" size="2" v-model="year" />年
          <input type="number" size="2" v-model="month" />月
        </div>
        <div class="caluratoin-set-condition">
          <select v-model="targetCalcurationSet">
            <option
              v-for="calcSet in calcurationSets"
              :key="calcSet.id"
              :value="calcSet.id">
              {{ calcSet.name }}
            </option>
          </select>
        </div>
        <div class="condition-controll">
          <button class="update-btn" @click="loadOrganizationBillings">更新</button>
        </div>
      </div>
      <div class="controll">
        <button class="finalize-btn" @click="finalize">すべてのStripe確認済みの請求を実行する</button>
      </div>
      <div class="organization-billings">
        <div class="summary">
          <div ><span class="title">DRAFT: </span>{{ draftCount }}</div>
          <div ><span class="title">SENT: </span>{{ sentCount }}</div>
          <div ><span class="title">STRIPE_CREATED: </span>{{ stripeCreatedCount }}</div>
          <div ><span class="title">STRIPE_OPENED: </span>{{ stripeOpenedCount }}</div>
          <div ><span class="title">STRIPE_DELETED: </span>{{ stripeDeletedCount }}</div>
          <div ><span class="title">SUSPEND: </span>{{ suspendCount }}</div>
          <div ><span class="title">FAILED: </span>{{ failedCount }}</div>
        </div>
        <div class="results">
          <div class="search-state" v-if="resultState">
            <div class="title">検索条件</div>
            <div class="states">{{ resultStateName }} : {{ resultYearMonth }}</div>
          </div>
          <div class="organization-billing"
            v-for="orgBilling in organizationBillings"
            :key="'orgBill' + orgBilling.id">
            <div class="lines">
              <div class="line-item">
                <span class="title">施設名</span>{{ orgBilling.organization.name }}
              </div>
            </div>
            <div class="lines">
              <div class="line-item">
                <span class="title">状態</span>{{ orgBilling.billing_state_jp }}
              </div>
              <div class="line-item">
                <span class="title">締め日</span>{{ orgBilling.period_end }}
              </div>
            </div>
            <div class="controll">
              <button class="show-detail-btn" @click="showDetail(orgBilling)">詳細表示</button>
            </div>
          </div>
        </div>
      </div><!-- organization-billings -->
    </div>
    <horus-organization-billing-detail-modal
      :organizationBilling="targetBilling"
      :modalDisplay="billingDetailDisplay"
      v-on:closeEvent="closeDetail"
      />
    <horus-confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
      />
  </div>
</template>

<script>
import { BillingSetting, OrganizationBilling } from '@/api'

import HorusOrganizationBillingDetailModal from '@/components/manageSystem/organizationBilling/HorusOrganizationBillingDetailModal.vue'

import HorusConfirmDialog from '@/components/common/HorusConfirmDialog.vue'

import HorusConfirmDialogControll from '@/mixin/HorusConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [HorusConfirmDialogControll],

  name: 'HorusOrganizationBillingView',

  components: {
    HorusOrganizationBillingDetailModal,
    HorusConfirmDialog,
  },

  data () {
    return {
      // nowLoading: false,
      organizationBillings: [],
      calcurationSets: [],
      billingState: 100,
      year: null,
      month: null,
      resultState: null,
      resultYear: null,
      resultMonth: null,
      targetCalcurationSet: null,
      draftCount: 0,
      sentCount: 0,
      stripeCreatedCount: 0,
      stripeOpenedCount: 0,
      stripeDeletedCount: 0,
      suspendCount: 0,
      failedCount: 0,
      targetBilling: null,
      billingDetailDisplay: false,
      BILLING_STATES: [
        { value: 100, name: 'すべて' },
        { value: 0, name: 'ドラフト' },
        { value: 10, name: 'Stripe確認済み' },
        { value: 11, name: 'Stripe請求進行中' },
        { value: 12, name: 'Stripe請求削除済' },
        { value: 1, name: '送信済み' },
        { value: 2, name: '支払完了' },
        { value: 3, name: '支払い失敗' },
        { value: 4, name: '処理完了' },
        { value: 5, name: '処理保留中' },
      ],
    }
  },

  mounted() {
    this.loadCalcurationSets()
    this.loadOrganizationBillings()
    this.loadOrganizationBillingSummary()
  },

  computed: {
    resultStateName () {
      if (this.resultState) {
        return this.BILLING_STATES.find((state) => state.value === this.billingState ).name
      } else {
        return null
      }
    },

    resultYearMonth () {
      if (this.resultYear && this.resultMonth) {
        return this.year + '年' + this.month + '月分'
      } else {
        return '期間設定なし'
      }
    }
  },

  methods: {
    loadOrganizationBillingSummary () {
      OrganizationBilling.fetchSummary().then(res => {
        console.log(res)
        this.draftCount = res.draft_count
        this.sentCount = res.sent_count
        this.stripeCreatedCount = res.stripe_created_count
        this.stripeOpenedCount = res.stripe_opened_count
        this.stripeDeletedCount = res.stripe_deleted_count
        this.suspendCount = res.suspend_count
        this.failedCount = res.failed_count
      })
    },

    loadOrganizationBillings () {
      let params = { state: this.billingState }
      if (this.year && this.month) {
        params["year"] = this.year
        params["month"] = this.month
      }
      if (this.targetCalcurationSet) {
        params["organization_billing_calcuration_set_id"] = this.targetCalcurationSet.id
      }
      OrganizationBilling.fetchList({ params: params }).then(res => {
        console.log(res)
        this.organizationBillings = res.organization_billings
        this.resultState = this.billingState
        this.resultYear = res.target_year
        this.resultMonth = res.target_month
      }).catch(error => {
        console.error(error)
      })
    },

    loadCalcurationSets () {
      BillingSetting.fetchList().then(res => {
        console.log(res)
        this.calcurationSets = [{ id: -1, name: 'この条件を使用しない' }]
        for(let calcSet of res.organization_billing_calcuration_sets) {
          if (calcSet.state === 1) {
            this.calcurationSets.push(calcSet)
          }
        }
      }).catch(error => {
        console.error(error)
      })
    },

    showDetail (organizationBilling) {
      this.targetBilling = organizationBilling
      this.billingDetailDisplay = true
    },

    closeDetail () {
      this.billingDetailDisplay = false
      this.targetBilling = null
    },

    finalize () {
      this.showConfirmDialog(
        '請求実施',
        'Stripe確認済み状態のすべての請求を実施します。\n実施後、Stripeのルーチンに沿って自動的に請求と支払いが行われます。\nこの操作は戻せません。',
        DIALOG_TYPE.EXECUTE,
        () => { this.executeFinalize() }
      )
    },

    executeFinalize () {
      OrganizationBilling.finalize().then(res => {
        console.log(res)
        this.showNotifyDialog(
          '請求実施完了',
          '請求の実施完了\n成功: ' + res.success_billings.length + '件 失敗: ' + res.failed_billings.length + '件'
        )
      }).catch(error => {
        console.error(error)
        this.showNotifyDialog(
          '請求実施失敗',
          '請求の実施に失敗しました。'
        )
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-organization-billing-view {
  margin: 0;
  padding: 10px 50px 0 50px;
  overflow: auto;
  background-color: #f5f5f5;
  .display-conditions {
    .text-conditions {
      display: flex;
      flex-wrap: wrap;
      div {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      .state-condition {
        label {
          margin-right: 5px;

        }
      }
      .month-condition {
        input {
          width: 50px;
        }
      }
      .condition-controll {
        button {
          // width: 100px;
          margin-left: 10px;
          border: 1px solid #999;
          border-radius: 3px;
          font-size: 14px;
          font-weight: bold;
          background-color: #666;
          color: #fff;
        }
      }
    }
  }
  .controll {
    padding: 10px 0;
    button {
      // width: 100px;
      margin-left: 10px;
      border: 1px solid #999;
      border-radius: 3px;
      font-size: 14px;
      font-weight: bold;
      background-color: #666;
      color: #fff;
    }
  }
  .organization-billings {
    .summary {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #aaa;
      padding: 10px;
      div {
        margin-right: 10px;
      }
    }
    .results {
      background-color: #fff;
      .search-state {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ddd;
      }
      .organization-billing {
        border-bottom: 1px solid #aaa;
        .lines {
          display: flex;
          flex-wrap: wrap;
          .line-item {
            padding: 3px 10px;
            .title {
              margin-right: 10px;
              font-size: 0.8rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>