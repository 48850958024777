export default {
  data () {
    return {
      loadingDialogVisible: false,
      loadingDialogTitle: '',
      loadingDialogMessage: '',
      loadingDialogLoadingMessage: '',
    }
  },

  methods: {
    showLoadingDialog (title, message, loadingMessage) {
      this.loadingDialogTitle = title
      this.loadingDialogMessage = message
      this.loadingDialogLoadingMessage = loadingMessage
      this.loadingDialogVisible = true
    },

    closeLoadingDialog () {
      this.loadingDialogVisible = false
      this.loadingDialogTitle = ''
      this.loadingDialogMessage = ''
      this.loadingDialogLoadingMessage = 'loadingMessage'
    },
  }
}