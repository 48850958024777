<template>
  <div class="horus-create-user-input-profile">
    <h2>利用者情報の入力</h2>
    <h4 class="new_account_heading">利用者情報</h4>
    <div class="form-list">
      <horus-attribute-in-form
        :labelText="'NBID'"
        :necessary="true"
        :validationState="validateNBID"
        :validationMessage="validateNBIDMessage"
        :attributeNotify="'６文字以上 半角英小文字、半角数字、一部の記号@._/-+() （使用できる記号は、@._/-+()の８種類のみです。）'">
        <input type="text" v-model="nbid" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'パスワード'"
        :necessary="true"
        :validationState="validatePassword"
        :validationMessage="validatePasswordMessage"
        :attributeNotify="'8文字以上'">
        <input :type="passwordInputType" v-model="password" />
      </horus-attribute-in-form>
      <horus-attribute-in-form
        :labelText="'パスワード(確認)'"
        :necessary="true"
        :validationState="validatePasswordConf"
        :validationMessage="validatePasswordConfMessage"
        :attributeNotify="'上と同じパスワードを入力'">
        <input :type="passwordInputType" v-model="passwordConfirmation" />
      </horus-attribute-in-form>
      <div class="toggle-password-visible" @click="togglePasswordVisible">
        <horus-custom-check-box
          :active="passwordVisible"
          :enabled="true"
          :type="'color'"
          />パスワードを表示
      </div>
    </div>
    <h4 class="new_account_heading">基本情報</h4>
    <div class="form-list">
      <horus-user-basic-form-inputs ref="userBasicForm" />
    </div>
    <h4 class="new_account_heading">連絡先等</h4>
    <div class="form-list">
      <horus-user-address-form-inputs ref="userAddressForm" />
    </div>
    <h4 class="new_account_heading">規約の説明</h4>
    <h4 class="rule-title">ナラティブブックシステム利用規約</h4>
    <div class="narrativebook_rule small_box">
      <horus-rules />
    </div>
    <h4 class="rule-title">利用者の個人情報の取扱について</h4>
    <div class="narrativebook_rule small_box">
      <horus-personal-information />
    </div>
    <div class="confirm-rules">
      <div class="admit-column" @click="toggleAdmit">
        <horus-custom-check-box
          :active="admitAgreement"
          :enabled="true"
          :type="'color'"
          />
        <span>ナラティブブックシステム利用規約および利用者情報の取扱についてに同意</span>
      </div>
    </div>
    <div class="new_user_submit">
      <div class="new_user_submit_notify" v-show="!allValidated">
        全ての項目を正しく入力し、「ナラティブブックシステム利用規約および利用者情報の取扱についてに同意」にチェックしてください
      </div>
      <button type="button" class="new-user-submit-btn" :class="{disable: !allValidated}" @click="submitCreate">新規ユーザー登録</button>
    </div>
  </div>
</template>

<script>
import { User } from '@/api'

import HorusUserBasicFormInputs from '@/components/profile/HorusUserBasicFormInputs.vue'
import HorusUserAddressFormInputs from '@/components/profile/HorusUserAddressFormInputs.vue'
import HorusRules from '@/components/common/HorusRules.vue'
import HorusPersonalInformation from '@/components/common/HorusPersonalInformation.vue'
import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusCustomCheckBox from '@/components/common/HorusCustomCheckBox.vue'

import AuthenticateInfo from '@/validateModel/AuthenticateInfo.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  name: 'HorusCreateUserInputProfile',

  components: {
    HorusUserBasicFormInputs,
    HorusUserAddressFormInputs,
    HorusRules,
    HorusPersonalInformation,
    HorusAttributeInForm,
    HorusCustomCheckBox
  },

  data () {
    return {
      admitAgreement: false,
      nbid: '',
      nbidState: '',
      password: '',
      passwordConfirmation: '',
      passwordVisible: false
    }
  },

  watch: {
    nbid: { handler: 'updateNBIDState' }
  },

  computed: {
    validateNBID () {
      if (this.nbidState === 'available') {
        return 'valid'
      } else if (this.nbidState === 'alreadyuse' || this.nbidState === 'invalid') {
        return 'invalid'
      } else {
        return 'invalid'
      }
    },

    validateNBIDMessage () {
      if (this.nbidState === 'alreadyuse') {
        return '入力されたNBIDは、すでに別のユーザーが利用しています。別のNBIDを設定してください。'
      } else if (this.nbidState === 'invalid') {
        return '入力されたNBIDは、形式が正しくありません。６文字以上で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePassword () {
      if (this.password.length <= 0) {
        return 'invalid'
      } else if (AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordMessage () {
      if (this.password.length > 0 && !AuthenticateInfo.validatePasswordFormat(this.password)) {
        return 'パスワードは、8文字以上120文字以内で、半角英小文字、半角数字、一部の記号@._/-+()の文字のみを利用して入力ください。'
      } else {
        return ''
      }
    },

    validatePasswordConf () {
      if (this.passwordConfirmation.length <= 0) {
        return 'invalid'
      } else if (this.password.trim() === this.passwordConfirmation) {
        return 'valid'
      } else {
        return 'invalid'
      }
    },

    validatePasswordConfMessage () {
      if (this.password.length > 0 && (this.password.trim() !== this.passwordConfirmation)) {
        return '上で入力したパスワードと同じ値を入れてください。'
      } else {
        return ''
      }
    },

    passwordInputType () {
      if (this.passwordVisible) {
        return 'text'
      } else {
        return 'password'
      }
    },

    allValidated () {
      if (this.nbidState === 'available' &&
        this.validatePassword === 'valid' &&
        this.validatePasswordConf === 'valid' &&
        this.$refs.userBasicForm.userBasic.isValid() &&
        this.$refs.userAddressForm.userAddress.isValid() &&
        this.admitAgreement
      ) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    updateNBIDState () {
      console.log('updateNBIDState')
      if (this.nbid.length <= 0) {
        this.nbidState = 'none'
      } else if (this.nbid.trim().match(/^([a-z0-9]|@|\.|_|\/|-|\+|\(|\)){6,120}$/g)) {
        // get valid
        User.nbidValidation({ nbid: this.nbid }).then(res => {
          this.nbidState = res.result
        })
      } else {
        this.nbidState = 'invalid'
      }
    },

    togglePasswordVisible () {
      this.passwordVisible = !this.passwordVisible
    },

    toggleAdmit () {
      this.admitAgreement = !this.admitAgreement
    },

    submitCreate () {
      if (this.allValidated) {
        this.$emit('showConfirmDialogEvent',
          'ナラティブブック利用者登録',
          '入力された情報で、ナラティブブックの利用者登録を実行します。\nよろしいですか?',
          DIALOG_TYPE.EXECUTE,
          () => { this.executeSubmit() })
      }
    },

    executeSubmit () {
      if (this.allValidated) {
        var userBasic = this.$refs.userBasicForm.userBasic
        var userAddress = this.$refs.userAddressForm.userAddress
        var portrate = this.$refs.userBasicForm.portrateObj
        var formData = new FormData()
        formData.append('user[name]', this.nbid)
        formData.append('authenticate_info[password]', this.password)
        formData.append('authenticate_info[password_confirmation]', this.passwordConfirmation)
        formData.append('user_basic[family_name]', userBasic.familyName)
        formData.append('user_basic[given_name]', userBasic.givenName)
        formData.append('user_basic[family_name_kana]]', userBasic.familyNameKana)
        formData.append('user_basic[given_name_kana]', userBasic.givenNameKana)
        formData.append('user_basic[sex_type]', userBasic.sexType)
        formData.append('user_basic[birth_date]', userBasic.birthDate)
        formData.append('user_basic[blood_type_abo]', userBasic.bloodTypeABO)
        formData.append('user_basic[blood_type_rh]', userBasic.bloodTypeRH)
        if (portrate) {
          formData.append('user_basic[portrate]', portrate.uploadFile, portrate.name)
        }
        formData.append('user_address[zip_code]', userAddress.zipCode)
        formData.append('user_address[prefecture]', userAddress.prefecture)
        formData.append('user_address[city]', userAddress.city)
        formData.append('user_address[address]', userAddress.address)
        formData.append('user_address[tel]', userAddress.tel)
        formData.append('user_address[cellphone]', userAddress.cellphone)
        formData.append('user_address[fax]', userAddress.fax)
        formData.append('user_address[mail]', userAddress.mail)
        formData.append('nb_rule_accept', this.admitAgreement)
        User.create({ formData: formData }).then(res => {
          console.log(res)
          this.$emit('userCreationFinishEvent', res)
        }).catch(error => {
          console.log(error.response)
          if (error.response.status === 400) {
            this.$emit('showNotifyDialgEvent', 'ナラティブブック利用者登録の失敗', 'ナラティブブック利用者登録に失敗しました。\n内容を再度確認してください。')
          } else {
            this.$emit('showNotifyDialgEvent', 'ナラティブブック利用者登録の失敗', 'ナラティブブック利用者登録に失敗しました。\nシステム側に問題がある可能性があります。\nしばらく断ってからもう一度お試しください。\n改善しない場合は、システム管理者に連絡をお願いいたします。')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-create-user-input-profile {
  margin: 0;
  padding: 0 10px;
  h2 {
    font-size: 1.1em;
    font-weight: normal;
  }
  .new_account_heading {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding-bottom: 5px;
    color: #6c272d;
    border-bottom: 1px solid #aaa;
    font-size: 18px;
    font-weight: normal;
  }
  .form-list {
    margin: 10px 0;
    padding: 10px 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    input {
      font-size: 16px;
      border: 1px solid #aaa;
    }
  }
  .rule-title {
    margin: 5px 0;
    text-align: center;
  }
  .small_box {
    margin-bottom: 10px;
    border: 1px solid #aaa;
    height: 300px;
    overflow: scroll;
  }
  .confirm-rules {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
    .admit-column {
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .new_user_submit {
    text-align: center;
    .new_user_submit_notify {
      padding: 10px;
      border: 1px solid #6c272d;
      background-color: #fff;
      color: #6c272d;
      text-align: center;
    }
    button {
      margin-top: 20px;
      padding: 3px 10px;
      border: 1px solid #4cae4c;
      border-radius: 5px;
      background-color: #4cae4c;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      &.disable {
        border-color: #aaa;
        background-color: #aaa;
        color: #ddd;
      }
    }
  }
}
</style>
