<template>
  <div class="horus-loading-dialog-wrapper" v-show="showDialog">
    <div class="dialog-backdrop" @click="backdropClicked"></div>
    <div class="dialg">
      <div class="dialog-header">
        <span class="title">{{ title }}</span>
      </div>
      <div class="dialog-body" v-html="messageHtml"></div>
      <div class="dialog-footer">
        <img class="progress" src="/static/images/progress_s.gif" />
        <span class="loading-message">{{ loadMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HorusLoadingDialog',

  props: {
    showDialog: Boolean,
    title: String,
    message: String,
    loadMessage: String,
  },

  computed: {
    messageHtml () {
      if (this.message) {
        return this.message.replace(/\n/g, '<br />')
      }
      return ''
    },
  },
  
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';

.horus-loading-dialog-wrapper {
  .dialog-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #000;
    opacity: 0.2;
  }
  .dialg {
    position: absolute;
    width: 600px;
    max-height: calc(100vh - 250px);
    margin: 0;
    padding: 0;
    top: 80px;
    left: calc((100vw - 600px) / 2);
    overflow: auto;
    background-color: #fff;
    border: 1px solid #aaa;
    @include mediaQuery('phone') {
      width: calc(100vw - 10px);
      max-height: calc(100vh - 100px);
      left: 5px;
    }
    .dialog-header {
      padding: 10px 10px;
      text-align: center;
      img {
        height: 18px;
        margin-left: 10px;
        vertical-align: middle;
        opacity: 0.8;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #666;
      }
    }
    .dialog-body {
      margin: 0 auto;
      padding: 10px 30px;
      max-width: 100%;
      font-size: 16px;
    }
    .dialog-footer {
      margin: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .loading-message {
        padding-left: 20px;
      }
    }
  }
}
</style>