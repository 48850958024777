<template>
  <div class="horus-calcuration-set-form-modal">
    <horus-modal-frame
      :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>{{ titleText }}</h1>
      </div>
      <div class="modal-body">
        <div class="calcuration-set-form">
          <horus-attribute-in-form
            :labelText="'名称'"
            :necessary="false"
            :validationState="validateName.state"
            :validationMessage="validateName.message"
            :attributeNotify="''">
            <input type="text" size="40" v-model="name" />
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'内税/外税'"
            :necessary="false"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="''">
            <input type="radio" id="tax-included" value="true" v-model="taxIncluded" />
            <label for="tax-included">内税</label>
            <input type="radio" id="tax-not-included" value="false" v-model="taxIncluded" />
            <label for="tax-not-included">外税</label>
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'税率'"
            :necessary="false"
            :validationState="validateTaxRatio.state"
            :validationMessage="validateTaxRatio.message"
            :attributeNotify="''">
            <input class="number-type-input" type="number" size="2" v-model="taxRatio" />%
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'開始日'"
            :necessary="false"
            :validationState="validatePeriodStart.state"
            :validationMessage="validatePeriodStart.message"
            :attributeNotify="''">
            <input class="number-type-input" type="number" size="2" v-model="periodStart" />日
          </horus-attribute-in-form>
          <horus-attribute-in-form
            :labelText="'備考'"
            :necessary="false"
            :validationState="'valid'"
            :validationMessage="''"
            :attributeNotify="''">
            <textarea class="memo-input" v-model="memo" />
          </horus-attribute-in-form>
        </div>
        <div class="controll-column">
          <button class="cancel-btn" @click="cancelSubmit">キャンセル</button>
          <button class="submit-btn" :class="{ enable: allValid }" @click="submitForm">{{ actionText }}</button>
        </div>
      </div>
    </horus-modal-frame>
  </div>
</template>

<script>
import { BillingSetting } from '@/api'

import HorusAttributeInForm from '@/components/common/HorusAttributeInForm.vue'
import HorusModalFrame from '@/components/common/HorusModalFrame.vue'

export default {
  name: 'HorusCalcurationSetFormModal',

  components: {
    HorusModalFrame,
    HorusAttributeInForm,
  },

  props: {
    modalDisplay: Boolean,
    calcurationSet: Object,
  },

  emits: [
    'cancelEvent',
    'submitEvent',
  ],

  data () {
    return {
      name: '',
      taxIncluded: 'false',
      taxRatio: 10,
      periodStart: 1,
      memo: '',
      nameState: '',
    }
  },

  watch: {
    modalDisplay: { handler: 'initData', immediate: true },
    name: { handler: 'updateNameState' }
  },

  computed: {
    titleText () {
      if (this.calcurationSet) {
        return '請求設定編集'
      }
      return '新規請求設定'
    },

    actionText () {
      if (this.calcurationSet) {
        return '更新'
      }
      return '新規作成'
    },

    validateName () {
      if (this.name.trim().length <= 0 || this.name.trim().length > 128) {
        return { valid: false, state: 'invalid', message: '名前は入力必須で文字数は128文字以下です' }
      } else if (this.nameState === 'exist') {
        return { valid: false, state: 'invalid', message: '同一の名前が使用されています' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validateTaxRatio () {
      if (this.taxRatio < 0 || this.taxRatio > 100) {
        return { valid: false, state: 'invalid', message: '税率は0〜100の整数で入力してください' }
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    validatePeriodStart () {
      if (this.periodStart < 0 || this.periodStart >= 29) {
        return { valid: false, state: 'invalid', message: '0〜28の整数を入力してください。'}
      } else {
        return { valid: true, state: 'valid', message: '' }
      }
    },

    allValid () {
      if (this.validateName.valid && this.validateTaxRatio.valid && this.validatePeriodStart.valid) {
        return true
      } else {
        return false
      }
    }

  },

  methods: {
    initData () {
      if (this.calcurationSet) {
        this.name = this.calcurationSet.name
        this.taxIncluded = this.calcurationSet.tax_included ? 'true' : 'false'
        this.taxRatio = this.calcurationSet.tax_ratio * 100
        this.periodStart = this.calcurationSet.period_start_date
        this.memo = this.calcurationSet.memo
      } else {
        this.name = ''
        this.taxIncluded = 'false'
        this.taxRatio = 10
        this.periodStart = 1
        this.memo = ''
      }
    },

    updateNameState () {
      let params = { name: this.name }
      if (this.calcurationSet) {
        params = { name: this.name, id: this.calcurationSet.id }
      }
      BillingSetting.nameAvailable({ params: params }).then(res => {
        console.log(res)
        if (res.exist) {
          this.nameState = 'exist'
        } else {
          this.nameState = 'available'
        }
      })
    },

    cancelSubmit () {
      this.$emit('cancelEvent')
    },

    submitForm () {
      let formData = new FormData()
      if (this.calcurationSet) {
        formData.append('id', this.calcurationSet.id)
      }
      formData.append('organization_billing_calcuration_set[name]', this.name)
      formData.append('organization_billing_calcuration_set[tax_included]', (this.taxIncluded === 'true'))
      formData.append('organization_billing_calcuration_set[tax_ratio]', (this.taxRatio / 100))
      formData.append('organization_billing_calcuration_set[period_start_date]', this.periodStart)
      formData.append('organization_billing_calcuration_set[memo]', this.memo)
      this.$emit('submitEvent', formData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/systemManagement.scss';

.horus-calcuration-set-form-modal {
  margin: 0;
  .modal-body {
    padding: 10px 20px;
    .number-type-input {
      margin-right: 10px;
      width: 4rem;
    }
    .memo-input {
      width: 80%;
    }
    .controll-column {
      text-align: right;
      button {
        width: 100px;
        margin-left: 10px;
        border: 1px solid #999;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;
        &.cancel-btn {
          background-color: #fff;
          color: #666;
        }
        &.submit-btn {
          background-color: #aaa;
          color: #fff;
          &.enable {
            background-color: #666;
          }
        }
      }
    }
  }
}
</style>