<template>
 <div class="horus-footer">
    <p>
      <a href="/term_of_use" target="_blank">ナラティブブック利用規約</a>
      <a href="/static/pages/personal_information.html" target="_blank">利用者の個人情報の取扱について</a>
      <a href="/personal_information_protection_policy" target="_blank">プライバシーポリシー</a>
      <a href="/contact" target="_blank">お問い合せ</a>
    </p>
    <p>Copyright © 2019 NarrativeBook All Rights Reserved.</p>
    <div class="version-info">
      <span class="frontend-version">F/E: {{ frontEndVersionInfo }}</span>
      <span class="api-version" v-if="apiVersion">
        API: {{ apiVersion.system_version_name }}/{{ apiVersion.system_build_name }}
      </span>
    </div>
  </div>
</template>

<script>
import { Version } from '@/api'

export default {
  name: 'HorusFooter',

  data () {
    return {
      apiVersion: null,
    }
  },

  mounted () {
    this.fetchAPIVersion()
  },

  computed: {
    frontEndVersionInfo () {
      return process.env.VUE_APP_VERSION_INFO
    }
  },

  methods: {
    fetchAPIVersion () {
      Version.fetch().then(res => {
        console.log(res)
        this.apiVersion = res
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.horus-footer {
  padding: 20px;
  border-top: 1px solid #aaa;
  height: 91px;
  p {
    text-align: center;
    font-size: 0.8em;
    color: #666;
    a {
      margin: 0 30px;
      color: #666;
      white-space: nowrap;
      text-decoration: none;
    }
  }
  .version-info {
    text-align: right;
    font-size: 10px;
    color: #999;
  }
}
</style>
